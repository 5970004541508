import { useTrackerByUid, useTrackers } from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import {
  OnChangeValue,
  OptionProps,
  Select,
  SelectInstance,
  SelectOption,
  StylesConfig,
  components,
} from '@capturi/ui-select'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  useToken,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, plural, select, t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { usePrevious } from 'react-use'

import { FormField } from './types'

type TrackerOptionType = SelectOption & {
  speaker?: Speaker | null
  isValidOption?: boolean
}

function useSpeaker(speaker: Speaker | null | undefined): string {
  const { organizationType } = useOrganization()
  switch (speaker) {
    case 1:
      return t`Employee`
    case 0:
      return select(organizationType, {
        public: 'Citizen',
        other: 'Customer',
      })
    default:
      return t`All`
  }
}

const Option: React.ComponentType<OptionProps<TrackerOptionType, boolean>> = (
  props,
) => {
  const { label, speaker, isValidOption } = props.data as TrackerOptionType
  const localSpeaker = useSpeaker(speaker)
  if (isValidOption === false) return null
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {label}
      </Box>
      <Text fontSize="xs" color="textMuted" noOfLines={1} wordBreak="break-all">
        <Trans>Speaker</Trans>: {localSpeaker}
      </Text>
    </components.Option>
  )
}

function useCustomStyles(
  isInvalid?: boolean,
): StylesConfig<SelectOption, boolean> {
  const red500 = useToken('colors', 'red.500') as string
  return {
    control: (base) => ({
      ...base,
      ...(isInvalid
        ? { borderColor: red500, boxShadow: `0 0 0 1px ${red500}` }
        : {}),
    }),
    multiValue: (base, { data }) => {
      return {
        ...base,
        ...((data as TrackerOptionType).isValidOption === false
          ? {
              backgroundColor: `${red500}90`,
            }
          : {}),
      }
    },
  }
}

type TrackerSelectProps = {
  id?: string
  isDisabled?: boolean
  isInvalid?: boolean
  isMulti?: boolean
  onChange: (value: string[]) => void
  options?: TrackerOptionType[]
  value?: string | string[]
}

export const TrackerSelect = React.forwardRef<
  SelectInstance<TrackerOptionType, boolean>,
  TrackerSelectProps
>(function TrackerSelect(
  {
    id,
    isDisabled,
    isInvalid,
    isMulti,
    onChange,
    options = [],
    value: valueProp,
  },
  ref,
) {
  const customStyles = useCustomStyles(isInvalid)

  const value = useMemo(() => {
    const valueArray = Array.isArray(valueProp)
      ? valueProp
      : valueProp != null
        ? [valueProp]
        : []
    return valueArray.map(
      (val) =>
        options.find((x) => x.value === val) ?? {
          label: val,
          value: val,
          isValidOption: false,
        },
    )
  }, [options, valueProp])

  const handleChange = (
    option: OnChangeValue<TrackerOptionType, boolean>,
  ): void => {
    if (option == null) {
      onChange([])
    } else if (Array.isArray(option)) {
      const value = (option as TrackerOptionType[]).map((o) => o.value)
      onChange(value)
    } else {
      const value = (option as TrackerOptionType).value
      onChange([value])
    }
  }

  return (
    <Select
      mRef={ref}
      id={id}
      options={options}
      value={value}
      onChange={handleChange}
      isMulti={isMulti}
      isDisabled={isDisabled}
      components={{
        Option,
      }}
      placeholder={
        isMulti
          ? t`Please select one or more trackers`
          : t`Please select a tracker`
      }
      hideSelectedOptions={false}
      styles={customStyles}
    />
  )
})

function getInvalidTrackerValues(
  values?: string | string[],
  validValues?: Set<string>,
): string[] {
  if (values === undefined || validValues === undefined) return []
  return (typeof values === 'string' ? [values] : values).filter(
    (val) => !validValues.has(val),
  )
}

export const TrackerField: FormField = ({
  name = 'trackerUid',
  isRequired,
  isDisabled,
}) => {
  const { formState, watch, trigger } = useFormContext()

  const currentValue = watch(name) as string | undefined
  const { data: trackers } = useTrackers()
  const trackersByUid = useTrackerByUid(trackers)

  const { trackerOptions, validTrackerUids } = useMemo(() => {
    const trackersList = trackers ?? []
    return trackersList?.reduce<{
      trackerOptions: TrackerOptionType[]
      validTrackerUids: Set<string>
    }>(
      (memo, x) => {
        memo.trackerOptions?.push({
          label: x.name,
          value: x.uid,
          speaker: x.speech?.speakerId,
        })
        memo.validTrackerUids?.add(x.uid)
        return memo
      },
      {
        trackerOptions: [],
        validTrackerUids: new Set(),
      },
    )
  }, [trackers])

  const isInvalidTrackerValue =
    getInvalidTrackerValues(currentValue, validTrackerUids).length > 0

  const fieldError = formState.errors[name]
  const isInvalid = fieldError != null || isInvalidTrackerValue
  const isTouched = !!formState.touchedFields[name]

  useEffect(() => {
    // trigger validation
    if (isTouched || isInvalidTrackerValue) {
      trigger(name)
    }
  }, [trigger, name, isInvalidTrackerValue, isTouched])

  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor="widget-tracker">
        <Trans>Tracker</Trans>
      </FormLabel>
      <Controller
        name={name}
        rules={{
          required: isRequired ? t`Please select a tracker` : false,
          validate: {
            trackerMustExist: (value) => {
              if (isEmpty(value) || isEmpty(trackers)) return true
              return (
                trackersByUid?.[value] != null ||
                t`The selected value is not a valid tracker`
              )
            },
          },
        }}
        render={({ field }) => (
          <TrackerSelect
            {...field}
            options={trackerOptions}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            onChange={(val) => field.onChange(val[0])}
            id="widget-tracker"
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}

export const MultiTrackerField: FormField<
  string[],
  { speakerFilter?: Speaker }
> = ({ name = 'trackerUids', isRequired, isDisabled, speakerFilter }) => {
  const { formState, watch, trigger } = useFormContext()

  const currentValues = watch(name) as string[] | undefined
  const { data: trackers } = useTrackers()
  const trackersByUid = useTrackerByUid(trackers)
  const isAllSelected = speakerFilter === Speaker.All
  const { trackerOptions, validTrackerUids } = useMemo(() => {
    const trackersList = trackers ?? []
    return trackersList?.reduce<{
      trackerOptions: TrackerOptionType[]
      validTrackerUids: Set<string>
    }>(
      (memo, x) => {
        const isValidOption =
          (x.speech &&
            (speakerFilter === x.speech.speakerId || isAllSelected)) ??
          false
        memo.trackerOptions.push({
          label: x.name,
          value: x.uid,
          speaker: x.speech?.speakerId,
          isValidOption,
        })
        if (isValidOption) {
          memo.validTrackerUids.add(x.uid)
        }
        return memo
      },
      {
        trackerOptions: [],
        validTrackerUids: new Set(),
      },
    )
  }, [trackers, speakerFilter, isAllSelected])

  const hasInvalidTrackerValues =
    getInvalidTrackerValues(currentValues, validTrackerUids).length > 0
  const didHaveInvalidTrackerValues = usePrevious(hasInvalidTrackerValues)

  const fieldError = formState.errors[name]
  const isInvalid = fieldError != null || hasInvalidTrackerValues
  const isTouched = !!formState.touchedFields[name]

  useEffect(() => {
    // trigger validation
    if (
      isTouched ||
      (didHaveInvalidTrackerValues !== undefined &&
        hasInvalidTrackerValues !== didHaveInvalidTrackerValues)
    ) {
      trigger(name)
    }
  }, [
    trigger,
    name,
    hasInvalidTrackerValues,
    didHaveInvalidTrackerValues,
    isTouched,
  ])

  const requiredErrorMessage = t`Please select one or more trackers`
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor="widget-trackers">
        <Trans>Trackers</Trans>
      </FormLabel>
      <Controller
        name={name}
        rules={{
          required: isRequired ? requiredErrorMessage : false,
          validate: {
            valuesLength: (values: string[]) => {
              return (values && values.length > 0) || requiredErrorMessage
            },
            trackersMustExist: (values) => {
              const invalidTrackerUids = getInvalidTrackerValues(
                values,
                validTrackerUids,
              )
              if (invalidTrackerUids.length === 0) return true

              const formattedTrackerValues = invalidTrackerUids
                .map((uid) => {
                  // Resolve names or invalid tracker uids. Deleted trackers will show an uid.
                  const value = trackersByUid?.[uid]?.name ?? uid
                  return `"${value}"`
                })
                .join(', ')

              return plural(invalidTrackerUids.length, {
                one: `${formattedTrackerValues} is not a valid tracker`,
                other: `${formattedTrackerValues} are not a valid trackers`,
              })
            },
          },
        }}
        render={({ field }) => (
          <TrackerSelect
            {...field}
            options={trackerOptions}
            isMulti
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            id="widget-trackers"
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
