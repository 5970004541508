import { t } from '@lingui/macro'
import React from 'react'

import { DashboardCanvas } from '../components/DashboardCanvas'
import ReadOnlyWidget from '../components/widgets/ReadOnlyWidget'
import { DashboardContext } from '../contexts/DashboardContext'
import useLayouts from '../hooks/useLayout'
import { Dashboard } from '../types'
import { isDashboardUpgraded } from '../utils/constants'

type Props = {
  dashboard: Dashboard
}

const DashboardView: React.FC<Props> = ({ dashboard }) => {
  const layouts = useLayouts(dashboard.widgets, true)
  return (
    <DashboardContext.Provider value={dashboard.uid}>
      <DashboardCanvas
        columns={dashboard.columns}
        rows={dashboard.rows}
        layout={layouts}
        dashboardUpgraded={isDashboardUpgraded(dashboard)}
        canEditDashboard={false}
        placeholder={t`Dashboard is empty`}
      >
        {dashboard.widgets.map((w) => (
          <ReadOnlyWidget key={w.uid} widget={w} />
        ))}
      </DashboardCanvas>
    </DashboardContext.Provider>
  )
}

export default DashboardView
