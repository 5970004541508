import { PreviewAccessRequestModel } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import {
  CreateDashboardRequestModel,
  UpdateDashboardRequestModel,
} from '../types'
import { baseQuery, controller } from './utils'

export default {
  create: (model: CreateDashboardRequestModel): RequestOptions => ({
    url: `${controller}`,
    query: baseQuery,
    method: 'post',
    json: model,
  }),
  clone: (
    uid: string,
    title: string,
    description?: string,
  ): RequestOptions => ({
    url: `${controller}/${uid}/duplicate`,
    query: baseQuery,
    method: 'post',
    json: {
      title,
      description,
    },
  }),
  delete: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
    method: 'delete',
  }),
  get: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
  }),
  getByAccessKey: (accessKey: string): RequestOptions => ({
    url: `public/${controller}?key=${accessKey}`,
    query: baseQuery,
  }),
  getAll: (): RequestOptions => ({
    url: `${controller}`,
    query: baseQuery,
  }),
  update: (
    uid: string,
    model: UpdateDashboardRequestModel,
  ): RequestOptions => ({
    url: `${controller}/${uid}`,
    query: baseQuery,
    method: 'patch',
    json: model,
  }),
  addAccessKey: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}/accessKey`,
    query: baseQuery,
    method: 'post',
    json: {},
  }),
  deleteAccessKey: (uid: string): RequestOptions => ({
    url: `${controller}/${uid}/accessKey`,
    query: baseQuery,
    method: 'delete',
  }),
  getPreviewAccess: (model: PreviewAccessRequestModel): RequestOptions => ({
    url: `${controller}/preview-access`,
    query: baseQuery,
    method: 'post',
    json: model,
  }),
}
