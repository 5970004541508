import { Container } from '../components/Widget'
import { WidgetComponent } from '../registry'
import MarkdownRenderer from './MarkdownRenderer'
import { HeaderWidgetModel } from './types'

const HeaderWidget: WidgetComponent<HeaderWidgetModel> = (widget) => {
  const { title, description, horizontalAlignment, verticalAlignment } = widget
  return (
    <Container>
      <MarkdownRenderer
        horizontalAlignment={horizontalAlignment}
        verticalAlignment={verticalAlignment}
        markdown={description}
        title={title}
      />
    </Container>
  )
}

export default HeaderWidget
