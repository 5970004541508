import { DateBucket, LineDescriptor } from '@capturi/charts'

const timeSeriesChartData = [
  {
    date: new Date('2022-08-15T22:00:00.000Z'),
    conversations: 10,
    averageScore: 1,
    dateTime: new Date('2022-08-15T22:00:00.000Z'),
    users: [
      {
        averageScore: 2,
      },
      {
        averageScore: 4,
      },
      {
        averageScore: 3,
      },
    ],
    teams: [
      {
        averageScore: 0.5,
      },
      {
        averageScore: 0,
      },
    ],
  },
  {
    date: new Date('2022-08-18T22:00:00.000Z'),
    conversations: 10,
    averageScore: 2,
    dateTime: new Date('2022-08-18T22:00:00.000Z'),
    users: [
      {
        averageScore: 0,
      },
      {
        averageScore: 6,
      },
      {
        averageScore: 2,
      },
    ],
    teams: [
      {
        averageScore: 2,
      },
      {
        averageScore: 3,
      },
    ],
  },
  {
    date: new Date('2022-08-18T23:00:00.000Z'),
    conversations: 10,
    averageScore: 5,
    dateTime: new Date('2022-08-18T23:00:00.000Z'),
    users: [
      {
        averageScore: 3,
      },
      {
        averageScore: 4,
      },
      {
        averageScore: 5,
      },
    ],
    teams: [
      {
        averageScore: 1,
      },
      {
        averageScore: 2,
      },
    ],
  },
  {
    date: new Date('2022-08-19T00:00:00.000Z'),
    conversations: 20,
    averageScore: 10,
    dateTime: new Date('2022-08-19T00:00:00.000Z'),
    users: [
      {
        averageScore: 0,
      },
      {
        averageScore: 6,
      },
      {
        averageScore: 2,
      },
    ],
    teams: [
      {
        averageScore: 2,
      },
      {
        averageScore: 3.5,
      },
    ],
  },
  {
    date: new Date('2022-08-19T01:00:00.000Z'),
    conversations: 20,
    averageScore: 20,
    dateTime: new Date('2022-08-19T01:00:00.000Z'),
    users: [
      {
        averageScore: 3,
      },
      {
        averageScore: 4,
      },
      {
        averageScore: 3,
      },
    ],
    teams: [
      {
        averageScore: 0.5,
      },
      {
        averageScore: 1,
      },
    ],
  },
]

const lines = {
  primary: [
    {
      id: 'primary',
      color: 'segments.primary',
      getValue: (d) => d.averageScore,
    },
  ] as LineDescriptor[],
  teams: [
    {
      id: 'team-1',
      color: 'segments.primary',
      getValue: (d) => d.teams?.[0]?.averageScore,
    },
    {
      id: 'team-2',
      color: 'segments.secondary',
      getValue: (d) => d.teams?.[1]?.averageScore,
    },
  ] as LineDescriptor[],
  users: [
    {
      id: 'user-1',
      color: 'segments.primary',
      getValue: (d) => d.users?.[0]?.averageScore,
    },
    {
      id: 'user-2',
      color: 'segments.secondary',
      getValue: (d) => d.users?.[1]?.averageScore,
    },
    {
      id: 'user-3',
      color: 'segments.tertiary',
      getValue: (d) => d.users?.[2]?.averageScore,
    },
  ] as LineDescriptor[],
  tracker: [
    {
      id: 'tracker-1',
      color: 'segments.primary',
      getValue: (d) => d.averageScore,
    },
    {
      id: 'tracker-2',
      color: 'segments.secondary',
      getValue: (d) => d.users?.[1]?.averageScore,
    },
    {
      id: 'tracker-3',
      color: 'segments.tertiary',
      getValue: (d) => d.teams?.[1]?.averageScore,
    },
  ] as LineDescriptor[],
}

const lineData = [
  {
    date: new Date('2022-06-15T22:00:00.000Z'),
    value: 80,
    percent: 0.8,
  },
  {
    date: new Date('2022-07-16T23:00:00.000Z'),
    value: 90,
    percent: 0.9,
  },
  {
    date: new Date('2022-08-17T24:00:00.000Z'),
    value: 80,
    percent: 0.8,
  },
  {
    date: new Date('2022-09-18T22:00:00.000Z'),
    value: 90,
    percent: 0.9,
  },
  {
    date: new Date('2022-10-11T18:00:00.000Z'),
    value: 80,
    percent: 0.8,
  },
  {
    date: new Date('2022-11-12T12:00:00.000Z'),
    value: 90,
    percent: 0.9,
  },
  {
    date: new Date('2022-12-13T10:00:00.000Z'),
    value: 80,
    percent: 0.8,
  },
] as DateBucket[]

export { lineData, lines, timeSeriesChartData }
