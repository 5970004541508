import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseSuspenseQueryResult,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'

import {
  CreateDashboardRequestModel,
  DashboardListItem,
  DashboardsListResponseModel,
} from '../types'

const url = 'dashboards?api-version=3.3'
export const cacheKey = ['dashboards']

export const useCreateDashboard = (): UseMutationResult<
  DashboardListItem,
  ResponseError,
  CreateDashboardRequestModel
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (model: CreateDashboardRequestModel) =>
      request.post<DashboardListItem>(url, { json: model }),
    onSuccess: (newDashboard) => {
      queryClient.setQueryData<DashboardsListResponseModel>(
        cacheKey,
        (oldData) => {
          if (!oldData) {
            return { dashboards: [newDashboard] }
          }
          return { dashboards: [newDashboard, ...oldData.dashboards] }
        },
      )
    },
  })
}

export const useDashboards = (): UseSuspenseQueryResult<
  DashboardListItem[],
  ResponseError
> =>
  useSuspenseQuery({
    queryKey: cacheKey,
    queryFn: () => request.get<DashboardsListResponseModel>(url),
    select: (data) => data.dashboards,
  })
