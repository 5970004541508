import { HStack, Radio, RadioGroup, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { ConversationDurationWidgetModel } from '../../ConversationsDuration/types'

type Props = {
  role: ConversationDurationWidgetModel['visual']
  onChange: (visual: 'UserList' | 'TeamList') => void
}

enum VisualType {
  Employees = 'UserList',
  Teams = 'TeamList',
}

export const RoleField: React.FC<Props> = ({ role, onChange }) => {
  return (
    <>
      <Text>
        Choose whether the list should be divided into employees or teams.
      </Text>
      <RadioGroup
        id="tracker_message-filter"
        value={role}
        onChange={(v: VisualType) => {
          onChange(v)
        }}
      >
        <HStack>
          <Radio value={VisualType.Employees}>
            <Trans>Employees</Trans>
          </Radio>
          <Radio value={VisualType.Teams}>
            <Trans>Teams</Trans>
          </Radio>
        </HStack>
      </RadioGroup>
    </>
  )
}
