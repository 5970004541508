import { Trans, t } from '@lingui/macro'

import { TimeResolution } from '../../../types'
import { RadioField } from './RadioField'
import { FormField } from './types'

export const ResolutionField: FormField<TimeResolution> = ({
  name = 'resolution',
  defaultValue = 'Auto',
  isRequired,
}) => {
  const options: {
    value: TimeResolution
    label: string
  }[] = [
    {
      value: 'Auto',
      label: t`Auto`,
    },
    {
      value: 'Hours',
      label: t`Hours`,
    },
    {
      value: 'Days',
      label: t`Days`,
    },
    {
      value: 'Weeks',
      label: t`Weeks`,
    },
    {
      value: 'Months',
      label: t`Months`,
    },
  ]

  return (
    <RadioField
      name={name}
      defaultValue={defaultValue}
      label={<Trans>Resolution</Trans>}
      description={
        <Trans>
          Choose whether the graph should show the hit rate per hour, day, week
          or month
        </Trans>
      }
      isRequired={isRequired}
      options={options}
      orientation="horizontal"
    />
  )
}
