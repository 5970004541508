import { Center } from '@chakra-ui/react'
import React from 'react'
import useWidgetData from '../../../hooks/useWidgetData'
import {
  Container,
  Content,
  Description,
  Footer,
  Goal,
  SubText,
  Title,
} from '../../components/Widget'
import SingleValue from '../../components/visuals/SingleValueDisplay'
import {
  ConversationDurationData,
  ConversationDurationWidgetModel,
} from '../types'

import { formatSeconds } from '@capturi/ui-components'
import { FormatHits } from '../../utils/format'

export const ConversationDurationSingleValue: React.FC<{
  widget: ConversationDurationWidgetModel
}> = ({ widget }) => {
  const { title, description, goal } = widget

  const { data: widgetData } = useWidgetData<ConversationDurationData>(widget)

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Content>
        <SingleValue.ValueContainer
          value={widgetData?.durationAverage}
          goal={goal}
          formatValue={formatSeconds}
        >
          <Center flexDirection="column">
            <SingleValue.ValueDisplay />
          </Center>
        </SingleValue.ValueContainer>
      </Content>
      <Footer>
        <Goal
          {...goal}
          value={widgetData?.durationAverage}
          formatValue={formatSeconds}
        />
        <SubText>
          <FormatHits total={widgetData?.conversations} />
        </SubText>
      </Footer>
    </Container>
  )
}
