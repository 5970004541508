import React from 'react'

const InsetShadowFilter: React.FC<{ id?: string }> = ({
  id = 'inset-shadow',
}) => {
  return (
    <filter id={id}>
      {/**
       * 1st inner shadow (dark)
       */}
      {/* Shadow offset */}
      <feOffset dx="6" dy="0" />
      {/* Shadow blur */}
      <feGaussianBlur stdDeviation="8" result="offset-blur" />
      {/* Invert drop shadow to make an inset shadow */}
      <feComposite
        operator="out"
        in="SourceGraphic"
        in2="offset-blur"
        result="inverse"
      />
      {/* Cut color inside shadow */}
      <feFlood floodColor="black" floodOpacity="0.25" result="color" />
      <feComposite operator="in" in="color" in2="inverse" result="shadow" />
      {/* Placing shadow over element */}
      <feComposite
        operator="over"
        in="shadow"
        in2="SourceGraphic"
        result="effect1_innerShadow"
      />
      {/**
       * 2nd inner shadow (light)
       */}
      <feOffset dx="-6" dy="0" />
      {/* Shadow blur */}
      <feGaussianBlur stdDeviation="6" result="offset-blur" />
      {/* Invert drop shadow to make an inset shadow */}
      <feComposite
        operator="out"
        in="SourceGraphic"
        in2="offset-blur"
        result="inverse"
      />
      {/* Cut color inside shadow */}
      <feFlood floodColor="white" floodOpacity="0.1" result="color" />
      <feComposite operator="in" in="color" in2="inverse" result="shadow" />
      {/* Placing shadow over element */}
      <feComposite operator="over" in="shadow" in2="effect1_innerShadow" />
    </filter>
  )
}

export default InsetShadowFilter
