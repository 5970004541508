import { TrendDisplay } from './TrendDisplay'
import { ValueContainer } from './ValueContainer'
import { ValueDisplay } from './ValueDisplay'
import { ValueDisplayContext } from './ValueDisplayContext'
import { ValueWithUnit } from './ValueWithUnit'

const SingleValue = {
  ValueContainer,
  ValueDisplay,
  ValueDisplayContext,
  ValueWithUnit,
  TrendDisplay,
}

export default SingleValue
