import { useScore } from '@capturi/api-scoring'
import { Box, Collapse } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import merge from 'lodash/merge'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import ConfigurationPreviewWidget from '../components/ConfigurationPreviewWidget'
import {
  ConfiguratorColumn,
  ConfiguratorLayout,
  DescriptionField,
  MinimumConversationsField,
  ResolutionField,
  ScoreField,
  SegmentationField,
  TitleField,
  TypeField,
  VisualField,
  VisualRadioField,
  WidgetPreviewColumn,
} from '../configurator'
import { BenchmarkField } from '../configurator/fields/BenchmarkField'
import { FormModel } from '../configurator/formUtils'
import { WidgetConfigurator } from '../registry'
import { ScoreWidgetModel } from './types'

type ScoreFormModel = FormModel<ScoreWidgetModel>

const defaultFormModel: Partial<ScoreFormModel> = {
  type: 'insights_score',
  visual: 'Value',
  resolution: 'Auto',
  minimumConversationsForListView: null,
  showPreviousPeriodTrendIndicator: true,
}

const ScoreConfigurator: WidgetConfigurator<ScoreWidgetModel> = ({
  formModel,
  onSubmit,
  children,
}) => {
  const formMethods = useForm<ScoreFormModel>({
    defaultValues: merge({}, defaultFormModel, formModel),
  })
  const { watch, handleSubmit, formState, setValue } = formMethods
  const { touchedFields } = formState

  const visualType = watch('visual') ?? ''
  const isListType = /List$/.test(visualType)
  const isGraphType = /^Graph/.test(visualType)

  const title = watch('title')
  const description = watch('description')
  const previousPeriodTrendInterval = watch('previousPeriodTrendInterval')
  const scoreMaxPossibleScore = watch('scoreMaxPossibleScore')

  const scoreUid = watch('scoreUid')
  const initialTitleValue = formModel?.title
  const isTitleTouched = !!touchedFields.title

  const { data: score } = useScore(scoreUid)

  React.useEffect(() => {
    if (score) {
      if (!(initialTitleValue || isTitleTouched)) {
        /**
         * Auto-set the title field based on the selected `score` if the field
         * is untouched.
         */
        setValue('title', t`Score: ${score.name}`, { shouldTouch: false })
      }
    }
  }, [score, setValue, initialTitleValue, isTitleTouched])

  const widgetPreviewData = {
    title,
    description,
    visual: visualType,
    showTrendIndicator: !(previousPeriodTrendInterval === 'None'),
    benchmark: previousPeriodTrendInterval,
    scoreMaxPossibleScore,
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfiguratorLayout>
          <ConfiguratorColumn>
            <TypeField />
            <TitleField />
            <DescriptionField />
            <ScoreField isRequired />
          </ConfiguratorColumn>
          <ConfiguratorColumn>
            <SegmentationField
              title={
                <span>
                  <Trans>Select conversations</Trans>
                </span>
              }
              description={
                <Trans>
                  Select the conversations within the data basis (set up in the
                  score configuration) that you want to show data for. If
                  nothing is selected, the data from all conversations within
                  the data basis will be shown.
                </Trans>
              }
            />
            <VisualField<ScoreWidgetModel['visual']>
              options={[
                'Value',
                'Gauge',
                { Line: ['GraphTotal', 'GraphPerTeam', 'GraphPerUser'] },
                { List: ['UserList', 'TeamList'] },
              ]}
            />
            <Collapse in={isGraphType}>
              <VisualRadioField
                id="visual-graph-type"
                label={t`Division`}
                description={t`Choose what the line chart should display.`}
                options={[
                  {
                    value: 'GraphTotal',
                    label: t`Average (total)`,
                  },
                  {
                    value: 'GraphPerTeam',
                    label: t`Average per team`,
                  },
                  {
                    value: 'GraphPerUser',
                    label: t`Average per user`,
                  },
                ]}
                orientation="vertical"
              />
              <Box mt={4}>
                <ResolutionField isRequired />
              </Box>
            </Collapse>
            <Collapse in={isListType}>
              <Box>
                <VisualRadioField
                  id="visual-list-type"
                  label={t`Division`}
                  description={t`Choose whether the list should be divided into employees or teams.`}
                  options={[
                    { value: 'UserList', label: t`Employees` },
                    { value: 'TeamList', label: t`Teams` },
                  ]}
                  orientation="vertical"
                />
              </Box>
              <Box mt={4}>
                <MinimumConversationsField />
              </Box>
            </Collapse>
            {visualType === 'Value' ||
              (visualType === 'Gauge' && <BenchmarkField />)}
          </ConfiguratorColumn>
          <WidgetPreviewColumn>
            <ConfigurationPreviewWidget previewWidget={widgetPreviewData} />
          </WidgetPreviewColumn>
        </ConfiguratorLayout>
        {children}
      </form>
    </FormProvider>
  )
}

export default ScoreConfigurator
