import { DurationInput } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import merge from 'lodash/merge'
import { FormProvider, useForm } from 'react-hook-form'

import ConfigurationPreviewWidget from '../components/ConfigurationPreviewWidget'
import {
  ConfiguratorColumn,
  ConfiguratorLayout,
  DescriptionField,
  GoalField,
  MinimumConversationsField,
  ResolutionField,
  RoleField,
  SegmentationField,
  TitleField,
  TypeField,
  VisualField,
  WidgetPreviewColumn,
  YAxisRangeField,
} from '../configurator'
import { FormModel } from '../configurator/formUtils'
import { WidgetConfigurator } from '../registry'
import { ConversationDurationWidgetModel } from './types'

type ConversationDurationFormModel = FormModel<
  Partial<ConversationDurationWidgetModel>
>

const ConversationDurationConfigurator: WidgetConfigurator<
  ConversationDurationWidgetModel
> = ({ formModel, onSubmit, children }) => {
  const defaultFormModel: Partial<ConversationDurationFormModel> = {
    type: 'insights_summaries-activity_duration',
    visual: 'Value',
    title: t`Duration (avg)`,
    goal: {
      min: null,
      max: null,
    },
    yAxisRange: {
      min: null,
      max: null,
    },
    resolution: 'Auto',
  }
  const formMethods = useForm<ConversationDurationFormModel>({
    defaultValues: merge({}, defaultFormModel, formModel),
  })
  const visualType = formMethods.watch(
    'visual',
  ) as ConversationDurationWidgetModel['visual']
  const yAxisRange = formMethods.watch('yAxisRange') ?? null
  const resolution = formMethods.watch('resolution') ?? null
  const goal = formMethods.watch('goal') ?? null
  const title = formMethods.watch('title')
  const description = formMethods.watch('description')
  const type = formMethods.getValues('type')

  const widgetPreviewData = {
    title,
    description,
    goal,
    resolution,
    yAxisRange,
    visual: visualType,
    type,
  }

  const isTeamListOrUserList =
    widgetPreviewData.visual === 'UserList' ||
    widgetPreviewData.visual === 'TeamList'

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ConfiguratorLayout>
          <ConfiguratorColumn>
            <TypeField />
            <TitleField />
            <DescriptionField />
            <SegmentationField />
          </ConfiguratorColumn>
          <ConfiguratorColumn>
            <VisualField<ConversationDurationWidgetModel['visual']>
              options={[
                'Value',
                { Line: 'Graph' },
                { List: ['UserList', 'TeamList'] },
              ]}
            />
            {isTeamListOrUserList && (
              <>
                <RoleField
                  role={visualType}
                  onChange={(role) => formMethods.setValue('visual', role)}
                />
                <MinimumConversationsField />
              </>
            )}
            {widgetPreviewData.visual === 'Graph' && (
              <>
                <ResolutionField />
                <YAxisRangeField
                  numberInput={<DurationInput size="sm" />}
                  unit={t`mm:ss`}
                />
              </>
            )}

            {!isTeamListOrUserList && (
              <GoalField
                numberInput={<DurationInput size="sm" />}
                unit={t`mm:ss`}
              />
            )}
          </ConfiguratorColumn>

          <WidgetPreviewColumn>
            <ConfigurationPreviewWidget previewWidget={widgetPreviewData} />
          </WidgetPreviewColumn>
        </ConfiguratorLayout>
        {children}
      </form>
    </FormProvider>
  )
}

export default ConversationDurationConfigurator
