import { Box, Center, Heading, useMergeRefs } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import orderBy from 'lodash/orderBy'
import React, { useMemo, useRef } from 'react'
import {
  Layout,
  Layouts,
  Responsive as ResponsiveGridLayout,
} from 'react-grid-layout'
import { useMeasure, useWindowSize } from 'react-use'

import ReadOnlyWidget from '../components/widgets/ReadOnlyWidget'
import { DashboardContext } from '../contexts/DashboardContext'
import { Dashboard } from '../types'
import findBestPosition from '../utils/findBestPosition'
import Registry from '../widgets/registry'

const breakpoints = { lg: 1280, md: 992, sm: 768, xs: 480, xxs: 0 }
const cols = { lg: 12, md: 10, sm: 8, xs: 6, xxs: 4 }
const rowHeight = 60
const bottomPadding = 8

type Props = {
  dashboard: Dashboard
}

const DashboardResponsiveView: React.FC<Props> = ({ dashboard }) => {
  const [useMeasureRef, { width }] = useMeasure<HTMLDivElement>()
  const containerRef = useRef<HTMLDivElement>(null)
  const mergedRef = useMergeRefs(useMeasureRef, containerRef)

  const containerTop = containerRef.current?.getBoundingClientRect()?.top ?? 0
  const { height: windowHeight } = useWindowSize()
  const cappedContainerHeight = windowHeight - containerTop - bottomPadding

  const widgets = dashboard.widgets
  const layouts = useMemo(() => {
    const orderedWidgets = orderBy(widgets, ['position.y', 'position.x'])
    // Generate a layout for each breakpoint
    return Object.entries(cols).reduce<Layouts>((memo, [breakpoint, cols]) => {
      memo[breakpoint] = orderedWidgets.reduce<Layout[]>((layouts, widget) => {
        const definition = Registry.get(widget.type)
        const { size } = widget
        // Constrain widget width to grid columns. 100% width in 'xxs' breakpoint
        const w =
          breakpoint === 'xxs'
            ? cols
            : Math.min(size?.width ?? definition.minWidth, cols)
        const h = size?.height ?? definition.minHeight

        // Find best position on grid. Grid may expand vertically.
        const position = findBestPosition(
          cols,
          Number.MAX_SAFE_INTEGER,
          w,
          h,
          layouts.map((item) => ({
            x: item.x,
            y: item.y,
            width: item.w,
            height: item.h,
          })),
        )

        if (position) {
          layouts.push({
            i: widget.uid,
            x: position.x,
            y: position.y,
            w,
            h,
            static: true,
          })
        }
        return layouts
      }, [])
      return memo
    }, {})
  }, [widgets])

  return (
    <DashboardContext.Provider value={dashboard.uid}>
      <Box
        ref={mergedRef}
        position="relative"
        overflow="auto"
        minH={cappedContainerHeight}
        w="100%"
        className="dashboard-container"
      >
        {widgets.length === 0 && (
          <Center h="100%" fontSize="2rem" fontWeight="bold" color="gray.300">
            <Heading fontSize="4xl" color="gray.400" textAlign="center">
              {t`Dashboard is empty`}
            </Heading>
          </Center>
        )}
        <ResponsiveGridLayout
          className="layout"
          layouts={layouts}
          // This turns off compaction so items can be placed everywhere.
          compactType={null}
          // This turns off rearrangement so items will not be pushed arround.
          preventCollision
          // If true, the container height swells and contracts to fit contents
          autoSize
          style={{ height: '100%' }}
          width={width}
          breakpoints={breakpoints}
          cols={cols}
          rowHeight={rowHeight}
        >
          {dashboard.widgets.map((w) => (
            <ReadOnlyWidget key={w.uid} widget={w} />
          ))}
        </ResponsiveGridLayout>
      </Box>
    </DashboardContext.Provider>
  )
}

export default DashboardResponsiveView
