import { FilterPeriodSelectContainer, PeriodDefinition } from '@capturi/filters'
import {
  Box,
  ButtonGroup,
  Flex,
  Icon,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import React, { useRef } from 'react'
import { MdFullscreen, MdFullscreenExit } from 'react-icons/md'
import { useFullscreen, useToggle } from 'react-use'
import screenfull from 'screenfull'

import { DashboardEvent, logEvent } from '../../events'
import { useOnlyWeekdaysToggle } from '../../hooks'
import { SharedContextDialog } from '../SharedContextDialog'

type DashboardToolbarProps = {
  fullscreenElementRef?: React.RefObject<Element>
  onDatePeriodChange?: (period: PeriodDefinition) => void
  onEnterFullScreen?: () => void
  onLeaveFullScreen?: () => void
  usePortal?: boolean
}

export const DashboardToolbar: React.FC<DashboardToolbarProps> = ({
  fullscreenElementRef,
  onLeaveFullScreen,
  onEnterFullScreen,
  onDatePeriodChange,
  usePortal,
}) => {
  const [, PlotOnlyWeekdaysToggle] = useOnlyWeekdaysToggle()
  const documentRef = useRef<HTMLElement>(document.body)

  const [show, toggle] = useToggle(false)
  const isFullscreen = useFullscreen(
    fullscreenElementRef ?? documentRef,
    show,
    {
      onClose: () => {
        toggle(false)
        onLeaveFullScreen?.()
      },
    },
  )

  const onToggleFullScreen = (): void => {
    if (show) {
      onLeaveFullScreen?.()
    } else {
      onEnterFullScreen?.()
    }
    toggle()
  }

  const fullscreenLabel = isFullscreen
    ? t`Exit fullscreen mode`
    : t`View in fullscreen mode`
  return (
    <ButtonGroup size="sm">
      <SharedContextDialog />
      <Flex align="center" px={4}>
        <PlotOnlyWeekdaysToggle
          onChange={(e) =>
            logEvent(DashboardEvent.TogglePlotOnlyWeekdays, {
              onlyWeekdays: e.target.checked,
              view: 'toolbar',
            })
          }
        />
      </Flex>
      <Box>
        <FilterPeriodSelectContainer
          onSelectPeriod={onDatePeriodChange}
          usePortal={usePortal}
        />
      </Box>
      {screenfull.isEnabled && (
        <Tooltip label={fullscreenLabel}>
          <IconButton
            aria-label={fullscreenLabel}
            icon={
              <Icon
                as={isFullscreen ? MdFullscreenExit : MdFullscreen}
                boxSize={5}
              />
            }
            onClick={onToggleFullScreen}
          />
        </Tooltip>
      )}
    </ButtonGroup>
  )
}

export default DashboardToolbar
