import { Box, Fade } from '@chakra-ui/react'
import React from 'react'

type GridProps = {
  show?: boolean
  spacing: number
  colWidth: number
  rowHeight: number
}

const Grid: React.FC<GridProps> = ({
  show = false,
  spacing,
  colWidth,
  rowHeight,
}) => {
  const w = colWidth + spacing
  const h = rowHeight + spacing
  const style = {
    top: `${spacing}px`,
    right: `${spacing}px`,
    bottom: `${spacing}px`,
    left: `${spacing}px`,
    backgroundSize: `${w}px ${h}px`,
    backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${w}' height='${h}'><rect fill='rgb(240,240,240)' x='0' y='0' width='${colWidth}' height='${rowHeight}'/></svg>")`,
  }
  return (
    <Fade in={show}>
      <Box
        position="absolute"
        backgroundPosition="top left"
        backgroundRepeat="repeat"
        style={style}
      />
    </Fade>
  )
}

export default Grid
