import { FormLabel } from '@capturi/ui-components'
import {
  ControlProps,
  OnChangeValue,
  OptionProps,
  Select,
  SelectInstance,
  components,
} from '@capturi/ui-select'
import { Box, FormControl, HStack, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { Controller } from 'react-hook-form'
import { CgTimelapse } from 'react-icons/cg'
import { IoMdEyeOff } from 'react-icons/io'
import { MdOutlineTimer } from 'react-icons/md'

import { WidgetTrendType } from '../../../types'
import { FormField } from './types'

type BenchmarkSelectOption = {
  value: NonNullable<WidgetTrendType>
  label: string
  symbol: JSX.Element
}

const benchmarkSelectOptions = (): BenchmarkSelectOption[] => [
  {
    value: 'None',
    label: t`None`,
    symbol: <IoMdEyeOff />,
  },
  {
    value: 'Trend',
    label: t`Trend from prev. period`,
    symbol: <MdOutlineTimer />,
  },
  {
    value: 'MonthToDate',
    label: t`Month to date (MTD)`,
    symbol: <CgTimelapse />,
  },
  {
    value: 'QuarterToDate',
    label: t`Quarter to date (QTD)`,
    symbol: <CgTimelapse />,
  },
  {
    value: 'YearToDate',
    label: t`Year to date (YTD)`,
    symbol: <CgTimelapse />,
  },
]

const Option: React.ComponentType<OptionProps<BenchmarkSelectOption, false>> = (
  props,
) => {
  const { label, symbol, value } = props.data
  return (
    <Box
      borderBottom={value === 'None' ? '1px' : 'none'}
      borderColor="gray.200"
    >
      <components.Option {...props}>
        <HStack spacing="4">
          {symbol}
          <Box noOfLines={1} wordBreak="break-all">
            {i18n._(label)}
          </Box>
        </HStack>
      </components.Option>
    </Box>
  )
}

const Control = (
  props: ControlProps<BenchmarkSelectOption, false>,
): JSX.Element => {
  const { children } = props
  const { symbol } = props.getValue()[0]

  return (
    <components.Control {...props}>
      <HStack w="100%" spacing="2" pl="4">
        {symbol}
        {children}
      </HStack>
    </components.Control>
  )
}

type BenchmarkSelectProps = {
  id?: string
  isDisabled?: boolean
  onChange: (value: WidgetTrendType) => void
  options?: BenchmarkSelectOption[]
  value?: string | string[]
}

export const OptionalSelect = React.forwardRef<
  SelectInstance<BenchmarkSelectOption, false>,
  BenchmarkSelectProps
>(function OptionalSelect(
  { id, isDisabled, onChange, options = [], value: valueProp },
  ref,
) {
  const defaultValue = benchmarkSelectOptions()[0].value
  const value = options.find((opt) => opt.value === (valueProp ?? defaultValue))

  const handleChange = (
    option: OnChangeValue<BenchmarkSelectOption, boolean>,
  ): void => {
    const value = (option as BenchmarkSelectOption).value
    onChange(value as WidgetTrendType)
  }

  return (
    <Select
      mRef={ref}
      id={id}
      options={options}
      value={value}
      onChange={handleChange}
      isDisabled={isDisabled}
      isSearchable={false}
      components={{
        Option,
        Control,
      }}
    />
  )
})

export const BenchmarkField: FormField = () => {
  const name = 'previousPeriodTrendInterval'
  const id = `widget-${name}`

  return (
    <FormControl>
      <FormLabel htmlFor={id}>
        <Trans>Benchmark</Trans>
        <Text color="textMuted" fontSize="sm" fontWeight="normal">
          <Trans>Benchmark data with selected period</Trans>
        </Text>
      </FormLabel>
      <Controller
        name={name}
        render={({ field }) => (
          <OptionalSelect
            {...field}
            options={benchmarkSelectOptions()}
            onChange={field.onChange}
          />
        )}
      />
    </FormControl>
  )
}
