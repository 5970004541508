import { Skeleton } from '@chakra-ui/react'
import React from 'react'

const WidgetLoader: React.FC = () => {
  return (
    <Skeleton
      startColor="gray.50"
      endColor="gray.300"
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
    />
  )
}

export default WidgetLoader
