import { Box, BoxProps, Text } from '@chakra-ui/react'
import React from 'react'

import { defaultFormatFn } from './utils'

export type ValueWithUnitProps<T> = {
  value?: T | null
  unit?: string
  formatValue?: (value: T) => string
} & BoxProps

type Ref<T> =
  | ((instance: T | null) => void)
  | React.MutableRefObject<T | null>
  | null

function ValueWithUnitWithRef<T>(
  props: ValueWithUnitProps<T>,
  ref: Ref<HTMLDivElement>,
): React.ReactElement {
  const { value, unit, formatValue = defaultFormatFn, ...restProps } = props
  return (
    <Box ref={ref} lineHeight={1} {...restProps}>
      {value != null && (
        <>
          <Text as="span">{formatValue(value)}</Text>
          {unit != null && (
            <Text as="span" ml="0.1em" fontSize="0.5em">
              {unit}
            </Text>
          )}
        </>
      )}
    </Box>
  )
}

export const ValueWithUnit = React.forwardRef(ValueWithUnitWithRef) as <T>(
  props: ValueWithUnitProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReturnType<typeof ValueWithUnitWithRef>
