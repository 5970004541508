import analytics from '@capturi/analytics'
import { useHover } from '@capturi/react-utils'
import { Box, BoxProps, IconButton, Link } from '@chakra-ui/react'
import React, { CSSProperties, useRef } from 'react'
import { MdLaunch } from 'react-icons/md'

import useWidgetFilterLink from '../../hooks/useWidgetFilterLink'
import { WidgetModel } from '../../types'
import { useWidgetDefinition } from '../../widgets/registry'
import WidgetCard from './WidgetCard'
import WidgetErrorBoundary from './WidgetErrorBoundary'
import WidgetLoader from './WidgetLoader'

type ReadOnlyWidgetProps = {
  widget: WidgetModel
} & BoxProps

interface ReactGridItemProps {
  style?: CSSProperties
  className?: string
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>
  onMouseUp?: React.MouseEventHandler<HTMLDivElement>
  onTouchEnd?: React.TouchEventHandler<HTMLDivElement>
}

const ReadOnlyWidget = React.forwardRef<
  HTMLDivElement,
  ReadOnlyWidgetProps & ReactGridItemProps
>(
  (
    {
      // ReadOnlyWidgetProps
      widget,
      // ReactGridItemProps
      style,
      className,
      onMouseDown,
      onMouseUp,
      onTouchEnd,
      ...restProps
    },
    ref,
  ) => {
    const { Component } = useWidgetDefinition(widget.type)

    const widgetCardRef = useRef<HTMLDivElement>(null)
    const isHovering = useHover(widgetCardRef)
    const disabledLinksVisualTypes = ['List', 'TeamList', 'UserList']
    const widgetLink = useWidgetFilterLink({ widget })

    const widgetLinkEnabled = !disabledLinksVisualTypes.includes(widget.visual)
    return (
      <WidgetCard
        // ReactGridItemProps
        style={style}
        className={className}
        ref={ref}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchEnd={onTouchEnd}
        colorName={widget.backgroundColor}
        {...restProps}
      >
        <Box width="100%" height="100%" ref={widgetCardRef}>
          {widgetLink && widgetLinkEnabled && (
            <Box
              w="30px"
              h="8"
              position="absolute"
              top={1}
              transition="opacity 50ms ease-in"
              opacity={isHovering ? '1' : '0'}
              right={1}
            >
              <Link
                href={widgetLink}
                position="absolute"
                top={1}
                onClick={() => {
                  analytics.event('dashboard_widget_link_readonly', {
                    widgetType: widget.type,
                    visual: widget.visual,
                  })
                }}
                isExternal
              >
                <IconButton
                  size="xs"
                  bg="transparent"
                  aria-label="Segment link"
                  icon={<MdLaunch />}
                />
              </Link>
            </Box>
          )}
          <WidgetErrorBoundary widget={widget}>
            <React.Suspense fallback={<WidgetLoader />}>
              <Component {...widget} />
            </React.Suspense>
          </WidgetErrorBoundary>
        </Box>
      </WidgetCard>
    )
  },
)

export default ReadOnlyWidget
