import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useMeasure } from 'react-use'

import { WidgetGoal } from '../../../../types'
import {
  ValueDisplayContext,
  ValueDisplayContextType,
} from './ValueDisplayContext'
import { defaultFormatFn } from './utils'

export type ValueContainerProps = {
  value: number | null | undefined
  unit?: string
  isPreview?: boolean
  formatValue?: (val: number) => string
  goal?: WidgetGoal | null
  children?: React.ReactNode
}

export const ValueContainer: React.FC<ValueContainerProps> = ({
  value,
  unit,
  formatValue,
  goal,
  isPreview = false,
  children,
}) => {
  const [ref, { width, height }] = useMeasure<HTMLDivElement>()
  const defaultFontSize = Math.min(width / 3.5, (height * 2) / 3)
  const context: ValueDisplayContextType = {
    width,
    height,
    value: value ?? undefined,
    unit,
    isPreview,
    formatValue: formatValue ?? defaultFormatFn,
    goal: goal ?? undefined,
  }
  return (
    <ValueDisplayContext.Provider value={context}>
      <Flex
        ref={ref}
        align="center"
        justify="center"
        w="100%"
        h="100%"
        style={{
          fontSize: `${defaultFontSize}px`,
        }}
      >
        {children}
      </Flex>
    </ValueDisplayContext.Provider>
  )
}
