import { Flags, useFeatureFlags } from '@capturi/feature-flags'
import { Flex, Icon } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React from 'react'
import { GiSadCrab } from 'react-icons/gi'

import { Widget, WidgetModel, WidgetType } from '../types'
import * as ConversationsDuration from './ConversationsDuration'
import * as HeaderWidget from './HeaderWidget'
import * as Hitrate from './Hitrate'
import * as Score from './Score'
import * as TrackerHits from './TrackerHits'
import * as TrackerTimeSeries from './TrackerTimeSeries'
import { FormModel } from './configurator/formUtils'

export type WidgetComponent<P extends WidgetModel> = React.ComponentType<P>
export type WidgetConfigurator<P extends WidgetModel> = React.ComponentType<{
  formModel?: FormModel<Partial<P>>
  definition: WidgetDefinition<WidgetModel>
  onSubmit: (formModel: FormModel<P>) => void
  children?: React.ReactNode
}>

export type WidgetDefinition<T extends WidgetModel> = {
  type: WidgetType
  name: () => string
  getDescription?: () => React.ReactNode
  minWidth: number
  minHeight: number
  initialSize: {
    upgraded: {
      width: number
      height: number
    }
    unupgraded: {
      width: number
      height: number
    }
  }
  defaultVisual: T['visual']
  Component: WidgetComponent<T>
  Configurator?: WidgetConfigurator<T>
  isEnabled?: (featureFlags: Flags) => boolean
}

const NotFoundDef: WidgetDefinition<WidgetModel> = {
  type: Widget.WidgetNotFound,
  name: () => 'Widget Not Found',
  minWidth: 2,
  minHeight: 2,
  initialSize: {
    upgraded: {
      width: 8,
      height: 6,
    },
    unupgraded: {
      width: 2,
      height: 2,
    },
  },
  defaultVisual: 'Value',
  Component: function NotFoundComponent(): React.ReactElement {
    return (
      <Flex w="full" h="full" align="center" justify="center">
        <Icon as={GiSadCrab} w="100%" h="auto" maxW="100px" fill="gray.600" />
      </Flex>
    )
  },
}

const Registry = (() => {
  const map = new Map<WidgetType, WidgetDefinition<WidgetModel>>()

  function add<T extends WidgetModel>(def: WidgetDefinition<T>): void {
    map.set(def.type, def as unknown as WidgetDefinition<WidgetModel>)
  }

  function get<T extends WidgetModel>(type: WidgetType): WidgetDefinition<T> {
    return (map.get(type) ?? NotFoundDef) as unknown as WidgetDefinition<T>
  }

  function getAll(): WidgetDefinition<WidgetModel>[] {
    return [...map.values()]
  }

  return {
    add,
    get,
    getAll,
  }
})()

Registry.add({
  isEnabled: (flags) => !flags.useEmailChannelAsDefault,
  type: Widget.TrackerHits,
  name: () => t`Tracker (hit rate)`,
  getDescription: () => (
    <Trans>
      Displays the percentage of conversations where the selected tracker is
      recognized
    </Trans>
  ),
  minWidth: 2,
  minHeight: 2,
  initialSize: {
    upgraded: {
      width: 8,
      height: 6,
    },
    unupgraded: {
      width: 2,
      height: 2,
    },
  },
  defaultVisual: 'Value',
  Component: TrackerHits.TrackerHits,
  Configurator: TrackerHits.Configurator,
})
Registry.add({
  isEnabled: () => true,
  type: Widget.Header,
  name: () => t`Text widget`,
  getDescription: () => (
    <Trans>
      Displays a heading or/and a description that can be used to create a
      better overview in the dashboard.
    </Trans>
  ),
  minWidth: 1,
  minHeight: 1,
  initialSize: {
    upgraded: {
      width: 8,
      height: 6,
    },
    unupgraded: {
      width: 2,
      height: 2,
    },
  },
  defaultVisual: 'nope',
  Component: HeaderWidget.Widget,
  Configurator: HeaderWidget.Configurator,
})

Registry.add({
  isEnabled: (flags) => !flags.useEmailChannelAsDefault,
  type: Widget.TrackerTimeSeries,
  name: () => t`Tracker (development over time)`,
  getDescription: () => (
    <Trans>
      Displays a line graph with the historical development of one or more
      trackers
    </Trans>
  ),
  minWidth: 5,
  minHeight: 2,
  initialSize: {
    upgraded: {
      width: 20,
      height: 6,
    },
    unupgraded: {
      width: 5,
      height: 2,
    },
  },
  defaultVisual: 'Line',
  Component: TrackerTimeSeries.Widget,
  Configurator: TrackerTimeSeries.Configurator,
})

Registry.add({
  isEnabled: (flags) => !flags.useEmailChannelAsDefault,
  type: Widget.ConversationsDuration,
  name: () => t`Conversation duration`,
  getDescription: () => (
    <Trans>
      Displays the average conversation duration for the selected conversations
    </Trans>
  ),
  minWidth: 2,
  minHeight: 2,
  initialSize: {
    upgraded: {
      width: 8,
      height: 6,
    },
    unupgraded: {
      width: 2,
      height: 2,
    },
  },
  defaultVisual: 'Value',
  Component: ConversationsDuration.Widget,
  Configurator: ConversationsDuration.Configurator,
})

Registry.add({
  isEnabled: () => true,
  type: Widget.HitRate,
  name: () => t`Hit rate`,
  getDescription: () => (
    <Trans>
      Displays the percentage of conversations that match a given filter. Can be
      displayed as either a percentage, a number, development over time or a
      break down by employee
    </Trans>
  ),
  minWidth: 2,
  minHeight: 2,
  initialSize: {
    upgraded: {
      width: 8,
      height: 6,
    },
    unupgraded: {
      width: 2,
      height: 2,
    },
  },
  defaultVisual: 'Value',
  Component: Hitrate.Widget,
  Configurator: Hitrate.Configurator,
})

Registry.add({
  isEnabled: (flags) => !flags.useEmailChannelAsDefault,
  type: Widget.Score,
  name: () => t`Scoring`,
  getDescription: () => <Trans>Displays the average score</Trans>,
  minWidth: 2,
  minHeight: 2,
  initialSize: {
    upgraded: {
      width: 8,
      height: 6,
    },
    unupgraded: {
      width: 2,
      height: 2,
    },
  },
  defaultVisual: 'Value',
  Component: Score.Widget,
  Configurator: Score.Configurator,
})

export function useRegistry(): typeof Registry {
  const featureFlags = useFeatureFlags()
  return {
    get: Registry.get,
    add: Registry.add,
    getAll: () => {
      const list = Registry.getAll()
      return list.filter((x) => {
        if (typeof x.isEnabled === 'function') {
          return x.isEnabled(featureFlags)
        }
        return true
      })
    },
  }
}

export function useWidgetDefinition(
  type: WidgetType,
): WidgetDefinition<WidgetModel> {
  return Registry.get(type)
}

export default Registry
