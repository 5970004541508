import { WidgetComponent } from '../registry'
import { ScoreWidgetModel } from './types'
import { ScoreTeamBreakdown } from './variants/ScoreListVisual/ScoreTeamBreakdown'
import { ScoreUserBreakdown } from './variants/ScoreListVisual/ScoreUserBreakdown'
import { ScoreSingleValue } from './variants/ScoreSingleValue'
import { ScoreTimeSeries } from './variants/ScoreTimeSeries'

const HitRate: WidgetComponent<ScoreWidgetModel> = (widget) => {
  switch (widget.visual) {
    case 'GraphPerTeam':
    case 'GraphPerUser':
    case 'GraphTotal': {
      return <ScoreTimeSeries widget={widget} />
    }
    case 'TeamList': {
      return <ScoreTeamBreakdown widget={widget} />
    }
    case 'UserList': {
      return <ScoreUserBreakdown widget={widget} />
    }
    default: {
      return <ScoreSingleValue widget={widget} />
    }
  }
}

export default HitRate
