import { CapturiTheme } from '@capturi/ui-theme'
import { useTheme } from '@chakra-ui/react'
import { getColor, transparentize } from '@chakra-ui/theme-tools'
import React from 'react'
import { useDebounce, useMeasure } from 'react-use'

import { WidgetGoal } from '../../../../types'
import { isWithinRange } from '../../../../utils/referenceRange'
import { ValueDisplayContext } from './ValueDisplayContext'
import { ValueWithUnit } from './ValueWithUnit'

function getTextColors(
  colorName: string,
  theme: CapturiTheme,
): {
  text: string
  shadow: string
} {
  return {
    text: getColor(theme, colorName),
    shadow: transparentize(colorName, 0.5)(theme),
  }
}

export type ValueDisplayProps = {
  value: number | null | undefined
  unit?: string
  isPreview?: boolean
  formatValue?: (val: number) => string
  goal?: WidgetGoal | null
}

export const ValueDisplay: React.FC = () => {
  const { width, value, unit, formatValue, goal, isPreview } =
    React.useContext(ValueDisplayContext)
  const [valueRef, { width: valueTextWidth }] = useMeasure<HTMLDivElement>()
  const [textScale, setTextScale] = React.useState(1)

  const valueIsWithinRange = isWithinRange(value, goal?.min, goal?.max)
  const theme = useTheme<CapturiTheme>()
  const valueTextColors = getTextColors(
    valueIsWithinRange && !isPreview ? 'segmentSecondary' : 'text',
    theme,
  )

  const [, cancel] = useDebounce(
    () => {
      if (valueTextWidth <= 0) {
        return
      }
      /**
       * Check for value text overflowing the container.
       * Scale the text if necessary.
       */
      const scale = Math.floor((width / valueTextWidth) * 100) / 100
      if (scale <= 1) {
        setTextScale(scale)
      }
    },
    300,
    [value, valueTextWidth, width],
  )

  React.useEffect(() => {
    return () => {
      cancel()
    }
  }, [cancel])

  return (
    <ValueWithUnit
      // FIXME: poor typing on ref from `useMeasure`: https://github.com/streamich/react-use/issues/1264
      ref={valueRef as (instance: HTMLDivElement | null) => void}
      value={value}
      unit={unit}
      formatValue={formatValue}
      color={valueTextColors.text}
      filter={`drop-shadow(0px 4px 5px ${valueTextColors.shadow})`}
      letterSpacing="0.02em"
      fontWeight="medium"
      style={{
        transform: `scale(${textScale})`,
      }}
    />
  )
}
