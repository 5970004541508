import { t } from '@lingui/macro'
import { TeamShareStatistics, UserShareStatistics } from '../../types'

const padTokens = (x: number): string => x.toString().padStart(2, '0')
const zeroHoursRegex = /^00:/

const formatTime = (time = 0): string => {
  const hours = Math.floor(time / 3600)
  const minutes = Math.floor(time / 60) % 60
  const seconds = Math.floor(time) % 60

  return [hours, minutes, seconds]
    .map(padTokens)
    .join(':')
    .replace(zeroHoursRegex, '')
}

export const compareRows = (
  a: UserShareStatistics | TeamShareStatistics,
  b: UserShareStatistics | TeamShareStatistics,
): number => {
  return b.avgDuration - a.avgDuration
}

export function renderValue(
  x: UserShareStatistics | TeamShareStatistics,
): string {
  return formatTime(x.avgDuration)
}

export function renderSubText(
  x: UserShareStatistics | TeamShareStatistics,
): string {
  return t`${x.conversations} conversations`
}
