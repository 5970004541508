import { Period, useFilterPeriodContext } from '@capturi/filters'
import { ResponseError } from '@capturi/request'
import { Center } from '@chakra-ui/react'
import React from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'

import useWidgetData from '../../../hooks/useWidgetData'

import * as Widget from '../../components/Widget'
import GaugeDisplay from '../../components/visuals/GaugeDisplay'
import SingleValue from '../../components/visuals/SingleValueDisplay'
import { FormatHits } from '../../utils/format'
import { getTrendPeriod } from '../../utils/getTrendPeriod'
import { ScoreWidgetModel, SingleScoreData } from '../types'

export type ScoreSingleValueProps = {
  widget: ScoreWidgetModel
}

function useData(
  widget: ScoreWidgetModel,
  period?: Period,
  swrConfig: SWRConfiguration = {},
): SWRResponse<SingleScoreData, ResponseError> {
  return useWidgetData<SingleScoreData>(
    widget,
    () => ({
      /**
       * `visual` is not used by the API but works here as a part of the SWR cache key.
       * If not present, then after changing the visual type of a widget that widget could
       * initially be served data conforming to the previous visual type from the cache.
       **/
      visual: widget.visual,
    }),
    period,
    swrConfig,
  )
}

function usePreviousPeriodData(
  widget: ScoreWidgetModel,
  period: Period,
  isEnabled = false,
): ReturnType<typeof useData> {
  return useData(widget, period, {
    suspense: false,
    isPaused: () => !isEnabled,
  })
}

export const ScoreSingleValue: React.FC<ScoreSingleValueProps> = ({
  widget,
}) => {
  const {
    title,
    description,
    scoreGoal,
    showPreviousPeriodTrendIndicator,
    previousPeriodTrendInterval,
  } = widget

  const { data } = useData(widget)
  const { period: initialPeriod } = useFilterPeriodContext()
  const trendPeriod = getTrendPeriod(previousPeriodTrendInterval, initialPeriod)
  const benchmark = (() => {
    if (previousPeriodTrendInterval == null && showPreviousPeriodTrendIndicator)
      return 'Trend'
    return previousPeriodTrendInterval
  })()

  const value = data?.averageScore
  const { data: previousPeriodData } = usePreviousPeriodData(
    widget,
    trendPeriod,
    showPreviousPeriodTrendIndicator ?? false,
  )

  return (
    <Widget.Container>
      <Widget.Title>{title}</Widget.Title>
      <Widget.Description>{description}</Widget.Description>
      <Widget.Content>
        {widget.visual === 'Gauge' ? (
          <GaugeDisplay
            value={value}
            goal={scoreGoal}
            max={widget.scoreMaxPossibleScore}
            showPreviousPeriodTrendIndicator={showPreviousPeriodTrendIndicator}
            referenceValue={previousPeriodData?.averageScore}
            benchmark={benchmark}
          />
        ) : (
          <SingleValue.ValueContainer value={value} goal={scoreGoal}>
            <Center flexDirection="column">
              <SingleValue.ValueDisplay />
              {showPreviousPeriodTrendIndicator && (
                <SingleValue.TrendDisplay
                  referenceValue={previousPeriodData?.averageScore}
                  benchmark={benchmark}
                />
              )}
            </Center>
          </SingleValue.ValueContainer>
        )}
      </Widget.Content>
      <Widget.Footer>
        <Widget.Goal {...scoreGoal} value={value} />
        <Widget.SubText>
          <FormatHits total={data?.conversationsTotal} />
        </Widget.SubText>
      </Widget.Footer>
    </Widget.Container>
  )
}
