import {
  OnChangeValue,
  OptionProps,
  Select,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'

import { WidgetType } from '../../../types'
import { useRegistry } from '../../registry'
import { ConfiguratorContext } from '../context'
import { FormField } from './types'

type WidgetSelectOption = SelectOption & {
  getDescription?: () => React.ReactNode
}

const Option: React.ComponentType<OptionProps<WidgetSelectOption, boolean>> = (
  props,
) => {
  const { label, getDescription } = props.data as WidgetSelectOption
  const description = getDescription?.()
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all" mb="2px">
        {label}
      </Box>
      {description && (
        <Text fontSize="xs" color="textMuted">
          {description}
        </Text>
      )}
    </components.Option>
  )
}

export const TypeField: FormField = ({ isRequired, isDisabled }) => {
  const { watch } = useFormContext<{ type: WidgetType }>()
  const widgetRegistry = useRegistry()
  const configuratorCtx = useContext(ConfiguratorContext)
  const currentType = watch('type')
  const definition = widgetRegistry.get(currentType)

  const widgetTypeOptions = widgetRegistry
    .getAll()
    .filter((widget) => widget.isEnabled)
    .map<WidgetSelectOption>((w) => ({
      value: w.type.toString(),
      label: w.name(),
      getDescription: w.getDescription,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  return (
    <FormControl isRequired={isRequired}>
      <FormLabel htmlFor="widget-type">
        <Trans>Type</Trans>
      </FormLabel>
      <Select
        minMenuHeight={100}
        maxMenuHeight={612}
        id="widget-type"
        options={widgetTypeOptions}
        value={
          definition
            ? { value: definition.type, label: definition.name() }
            : null
        }
        onChange={(
          option: OnChangeValue<WidgetSelectOption, boolean>,
        ): void => {
          if (option == null) return
          const value = (option as WidgetSelectOption).value
          configuratorCtx.setType?.(value as WidgetType)
        }}
        placeholder={t`Please select a widget type`}
        components={{
          Option,
        }}
        isDisabled={isDisabled ?? configuratorCtx.isEditMode}
      />
      {typeof definition?.getDescription === 'function' && (
        <Text fontSize="sm" color="textMuted" p={2} pb={0}>
          {definition.getDescription()}
        </Text>
      )}
    </FormControl>
  )
}
