import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { t } from '@lingui/macro'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type Option = { value: string; label: string; description?: string }

export type RadioFieldProps = {
  name: string
  label: React.ReactNode
  description?: React.ReactNode
  isRequired?: boolean
  defaultValue?: string
  isDisabled?: boolean
  options: Option[]
  id?: string
} & Pick<RadioOptionsProps, 'orientation'>

type RadioOptionsProps = {
  value?: string
  onChange?: (nextValue: string) => void
  options: Option[]
  id?: string
  orientation?: 'vertical' | 'horizontal'
}

export const RadioOptions = React.forwardRef<HTMLDivElement, RadioOptionsProps>(
  function RadioOptions(
    { options, orientation = 'horizontal', ...props },
    ref,
  ) {
    return (
      <RadioGroup ref={ref} {...props}>
        <Wrap
          spacing={2}
          direction={orientation === 'vertical' ? 'column' : 'row'}
        >
          {options.map((o) => (
            <WrapItem key={o.value} flexDirection="column">
              {/**
               * Manually adding id on Radio due to bug:
               * https://github.com/chakra-ui/chakra-ui/issues/3074
               */}
              <Radio value={o.value} id={`radio-option_${o.value}`}>
                {o.label}
              </Radio>
              {o.description && (
                <Text color="textMuted" fontSize="sm" ml={6}>
                  {o.description}
                </Text>
              )}
            </WrapItem>
          ))}
        </Wrap>
      </RadioGroup>
    )
  },
)

export const RadioField: React.FC<RadioFieldProps> = ({
  name,
  label,
  description,
  defaultValue = '',
  isRequired,
  options,
  id: idProp,
  orientation,
}) => {
  const { formState } = useFormContext()
  const isInvalid = formState.errors[name] != null
  const id = idProp ?? `widget-${name}`
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor={id} mb={description ? 0 : 2}>
        {label}
      </FormLabel>
      {description && (
        <Text color="textMuted" fontSize="sm" fontWeight="normal" mb={2}>
          {description}
        </Text>
      )}
      <Controller
        name={name}
        defaultValue={defaultValue}
        rules={{
          required: isRequired ? t`Please select a value` : false,
        }}
        render={({ field }) => (
          <RadioOptions
            {...field}
            options={options}
            id={id}
            orientation={orientation}
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
