import {
  BoxProps,
  Table as ChakraTable,
  TableCellProps,
  TableProps,
  Td,
  chakra,
  useMergeRefs,
} from '@chakra-ui/react'
import React from 'react'
import { useMeasure, useScrolling } from 'react-use'

import { baseFontSize } from './constants'

export const Table: React.FC<TableProps> = (props) => (
  <ChakraTable
    variant="unstyled"
    sx={{ borderCollapse: 'collapse' }}
    {...props}
  />
)

export const Cell: React.FC<TableCellProps> = (props) => (
  <Td
    pr="0.4em"
    pl="0"
    py="0.1em"
    lineHeight="1.2"
    fontSize="0.75em"
    {...props}
  />
)

export const FlexibleCell: React.FC<TableCellProps> = (props) => (
  <Cell
    noOfLines={1}
    wordBreak="break-all"
    maxW="1px"
    w="100%"
    display="table-cell"
    {...props}
  />
)

export const CellIcon = chakra('div', {
  baseStyle: {
    padding: '2',
    position: 'absolute',
    top: '50%',
    right: '0',
    borderRadius: 'md',
    transform: 'translateY(-50%)',
    _hover: { backgroundColor: 'gray.300' },
  },
})

const ScrollArea = chakra('div', {
  baseStyle: {
    overflow: 'auto',
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
})

export const ListContainer = React.forwardRef<HTMLDivElement, BoxProps>(
  function ListContainerWithRef(props, ref) {
    return (
      <ScrollArea
        ref={ref}
        w="100%"
        h="100%"
        fontSize={baseFontSize}
        {...props}
      />
    )
  },
)

export function useListContainer(): {
  ref: React.Ref<HTMLDivElement>
  width: number
  isScrolling: boolean
} {
  const scrollRef = React.useRef<HTMLDivElement>(null)
  const isScrolling = useScrolling(scrollRef)
  const [measureRef, { width }] = useMeasure()
  const ref = useMergeRefs(scrollRef, measureRef) as React.Ref<HTMLDivElement>

  return {
    ref,
    width,
    isScrolling,
  }
}
