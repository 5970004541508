import { MessageDescriptor, i18n } from '@lingui/core'
import { msg } from '@lingui/macro'

import { WidgetTrendType } from '../../../../types'

export const defaultFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
}
// biome-ignore lint/suspicious/noExplicitAny: legacy
export const defaultFormatFn = (val: any): string => {
  if (typeof val === 'number') {
    return i18n.number(val, defaultFormat)
  }
  return val
}

export const trendLabels = new Map<
  WidgetTrendType | undefined,
  {
    short: MessageDescriptor
    title: MessageDescriptor
  }
>([
  [
    'MonthToDate',
    {
      short: msg`MTD`,
      title: msg`month to date`,
    },
  ],
  [
    'QuarterToDate',
    {
      short: msg`QTD`,
      title: msg`quarter to date`,
    },
  ],
  [
    'YearToDate',
    {
      short: msg`YTD`,
      title: msg`year to date`,
    },
  ],
])
