import { Speaker } from '@capturi/core'
import merge from 'lodash/merge'
import { FormProvider, useForm } from 'react-hook-form'

import ConfigurationPreviewWidget from '../components/ConfigurationPreviewWidget'
import {
  ConfiguratorColumn,
  ConfiguratorLayout,
  DescriptionField,
  MultiTrackerField,
  ResolutionField,
  SegmentationField,
  SpeakerField,
  TitleField,
  TypeField,
  WidgetPreviewColumn,
  YAxisRangeField,
} from '../configurator'
import { FormModel } from '../configurator/formUtils'
import { WidgetConfigurator } from '../registry'
import { TrackerTimeSeriesWidgetModel } from './types'

type TrackerTimeSeriesFormModel = FormModel<
  Partial<TrackerTimeSeriesWidgetModel>
>

const defaultFormModel: Partial<TrackerTimeSeriesFormModel> = {
  type: 'insights_speaker-trackerhits-time-series',
  visual: 'Line',
  speakerId: Speaker.All,
  trackerUids: [],
  resolution: 'Auto',
  yAxisRange: {
    min: undefined,
    max: undefined,
  },
}

const TrackerTimeSeriesConfigurator: WidgetConfigurator<
  TrackerTimeSeriesWidgetModel
> = ({ formModel, onSubmit, children }) => {
  const formMethods = useForm<TrackerTimeSeriesFormModel>({
    defaultValues: merge({}, defaultFormModel, formModel),
  })
  const { watch, handleSubmit } = formMethods
  const speakerId = watch('speakerId') as Speaker | undefined

  const title = watch('title')
  const description = watch('description')
  const trackerUids = watch('trackerUids')

  const widgetPreviewData = {
    title,
    description,
    trackerUidsCount: trackerUids?.length,
    visual: 'Line',
    speakerId,
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfiguratorLayout>
          <ConfiguratorColumn>
            <TypeField />
            <TitleField />
            <DescriptionField />
            <SegmentationField />
          </ConfiguratorColumn>
          <ConfiguratorColumn>
            <SpeakerField isRequired />
            <MultiTrackerField isRequired speakerFilter={speakerId} />
            <ResolutionField isRequired />
            <YAxisRangeField unit="%" />
          </ConfiguratorColumn>
          <WidgetPreviewColumn>
            <ConfigurationPreviewWidget previewWidget={widgetPreviewData} />
          </WidgetPreviewColumn>
        </ConfiguratorLayout>
        {children}
      </form>
    </FormProvider>
  )
}

export default TrackerTimeSeriesConfigurator
