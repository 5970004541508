import { useCurrentUser } from '@capturi/core'
import {
  OptionProps,
  PopoutSelect,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box, Flex, Icon } from '@chakra-ui/react'
import React from 'react'
import { IconType } from 'react-icons'
import { GrOrganization } from 'react-icons/gr'
import { MdCheck, MdPeople, MdPerson } from 'react-icons/md'

import {
  useOriginalSharedContextType,
  useSelectedSharedContext,
} from '../../../contexts/ContextualDashboardContext'
import { localizeSharedContextType } from '../../../messages'
import { SharedContextType } from '../../../types'

type TypeSelectOption = SelectOption & {
  value: SharedContextType
  icon?: IconType
}

type SharedContextTypeSelectProps = {
  onSelectType: (type: SharedContextType) => void
}

export const SharedContextTypeSelect: React.FC<
  SharedContextTypeSelectProps
> = ({ onSelectType }) => {
  const originalSharedContextType = useOriginalSharedContextType()
  const selectedSharedContext = useSelectedSharedContext()
  const user = useCurrentUser()

  const sharedContextOptions: TypeSelectOption[] = [
    {
      value: 'User',
      label: localizeSharedContextType('User'),
      icon: MdPerson,
    },
    {
      value: 'Team',
      label: localizeSharedContextType('Team'),
      icon: MdPeople,
    },
    {
      value: 'Organization',
      label: localizeSharedContextType('Organization'),
      icon: GrOrganization,
    },
  ]

  const sharedContextType =
    selectedSharedContext?.type ?? originalSharedContextType
  const typeValueOption = sharedContextType
    ? {
        value: sharedContextType,
        label: localizeSharedContextType(sharedContextType),
      }
    : undefined

  return (
    <PopoutSelect<TypeSelectOption, false>
      options={
        user.isTeamLead && originalSharedContextType !== 'Organization'
          ? sharedContextOptions.slice(0, 2)
          : sharedContextOptions
      }
      value={typeValueOption}
      onChange={(option) => {
        if (option?.value) {
          onSelectType(option.value)
        }
      }}
      components={{
        Option,
      }}
      isSearchable={false}
    />
  )
}

const Option: React.ComponentType<OptionProps<TypeSelectOption, false>> = (
  props,
) => {
  const { isSelected } = props
  const { label, icon } = props.data as TypeSelectOption
  return (
    <components.Option {...props}>
      <Flex align="center">
        <Icon
          as={icon}
          sx={{
            path: {
              stroke: 'currentColor',
            },
          }}
          boxSize={4}
          mr={2}
        />
        <Box>{label}</Box>
        {isSelected && <Icon as={MdCheck} boxSize={4} ml={4} />}
      </Flex>
    </components.Option>
  )
}
