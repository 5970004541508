import { useScores } from '@capturi/api-scoring'
import {
  OnChangeValue,
  OptionProps,
  Select,
  SelectInstance,
  SelectOption,
  StylesConfig,
  components,
} from '@capturi/ui-select'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  useToken,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, t } from '@lingui/macro'
import isEmpty from 'lodash/isEmpty'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormField } from './types'

type ScoreOptionType = SelectOption

const Option: React.ComponentType<OptionProps<ScoreOptionType, boolean>> = (
  props,
) => {
  const { label } = props.data as ScoreOptionType
  return (
    <components.Option {...props}>
      <Box noOfLines={1} wordBreak="break-all">
        {label}
      </Box>
    </components.Option>
  )
}

function useCustomStyles(
  isInvalid?: boolean,
): StylesConfig<SelectOption, boolean> {
  const red500 = useToken('colors', 'red.500') as string
  return {
    control: (base) => ({
      ...base,
      ...(isInvalid
        ? { borderColor: red500, boxShadow: `0 0 0 1px ${red500}` }
        : {}),
    }),
    multiValue: (base, { data }) => {
      return {
        ...base,
        ...((data as ScoreOptionType).isValidOption === false
          ? {
              backgroundColor: `${red500}90`,
            }
          : {}),
      }
    },
  }
}

type ScoreSelectProps = {
  id?: string
  isDisabled?: boolean
  isInvalid?: boolean
  onChange: (value: string[]) => void
  options?: ScoreOptionType[]
  value?: string | string[]
}

export const ScoreSelect = React.forwardRef<
  SelectInstance<ScoreOptionType, boolean>,
  ScoreSelectProps
>(function TrackerSelect(
  { id, isDisabled, isInvalid, onChange, options = [], value: valueProp },
  ref,
) {
  const customStyles = useCustomStyles(isInvalid)

  const value = useMemo(() => {
    const valueArray = Array.isArray(valueProp)
      ? valueProp
      : valueProp != null
        ? [valueProp]
        : []
    return valueArray.map(
      (val) =>
        options.find((x) => x.value === val) ?? {
          label: val,
          value: val,
          isValidOption: false,
        },
    )
  }, [options, valueProp])

  const handleChange = (
    option: OnChangeValue<ScoreOptionType, boolean>,
  ): void => {
    if (option == null) {
      onChange([])
    } else if (Array.isArray(option)) {
      const value = (option as ScoreOptionType[]).map((o) => o.value)
      onChange(value)
    } else {
      const value = (option as ScoreOptionType).value
      onChange([value])
    }
  }

  return (
    <Select
      mRef={ref}
      id={id}
      options={options}
      value={value}
      onChange={handleChange}
      isDisabled={isDisabled}
      components={{
        Option,
      }}
      placeholder={t`Please select a score`}
      hideSelectedOptions={false}
      styles={customStyles}
    />
  )
})

export const ScoreField: FormField = ({
  name = 'scoreUid',
  isRequired,
  isDisabled,
}) => {
  const { formState } = useFormContext()

  const { data: scores } = useScores()

  const scoreOptions = useMemo(() => {
    return (scores ?? []).map((x) => {
      return {
        label: x.name,
        value: x.uid,
      }
    })
  }, [scores])

  const { errors } = formState
  const isInvalid = errors[name] != null
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor="widget-score">
        <Trans>Score</Trans>
      </FormLabel>
      <Controller
        name={name}
        rules={{
          required: isRequired ? t`Please select a score` : false,
          validate: {
            scoreMustExist: (value) => {
              if (isEmpty(value) || isEmpty(scoreOptions)) return true
              return (
                scoreOptions.find((x) => x.value === value) != null ||
                t`The selected value is not a valid score`
              )
            },
          },
        }}
        render={({ field }) => (
          <ScoreSelect
            {...field}
            options={scoreOptions}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            onChange={(val) => field.onChange(val[0])}
            id="widget-score"
          />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
