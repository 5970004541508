export type WidgetBackgroundColorName =
  | 'default'
  | 'blue'
  | 'sand'
  | 'green'
  | 'yellow'
  | 'red'
  | 'transparent'

export const colors: Record<WidgetBackgroundColorName, string> = {
  default: '#FAFAFA',
  blue: '#C1D2DC',
  sand: '#E2E2DE',
  green: '#D1E5CC',
  yellow: '#F3E9CE',
  red: '#FED7D7',
  transparent: 'transparent',
}

export function getColorValue(
  colorName: string | null | undefined,
  fallback: WidgetBackgroundColorName = 'default',
): string {
  return colors[colorName as WidgetBackgroundColorName] || fallback
}
