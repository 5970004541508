import { Description, RadioButtonGroup } from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Tooltip,
  UseRadioProps,
  useRadio,
  useToken,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, t } from '@lingui/macro'
import constate from 'constate'
import range from 'lodash/range'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Line, LineChart, ResponsiveContainer } from 'recharts'

import { WidgetGoal } from '../../../types'
import GaugeDisplay from '../../components/visuals/GaugeDisplay'
import SingleValueDisplay from '../../components/visuals/SingleValueDisplay'
import { RadioFieldProps, RadioOptions } from './RadioField'

type PreviewLineData = { value: number }[]
type PreviewData = {
  singleValue: number
  goal: WidgetGoal
  lineData: PreviewLineData
}

function usePreviewData(): PreviewData {
  const singleValue = 35
  const lineData = [4, 4, 9, 3, 10, 1, 6, 5].map((x) => ({ value: x }))
  const goal = { min: 40, max: 80 }
  return {
    singleValue,
    goal,
    lineData,
  }
}

const [PreviewDataProvider, usePreviewDataContext] = constate(usePreviewData)

export type PreviewVisualType = 'Value' | 'Gauge' | 'Line' | 'List'

export type PreviewVisualOption = {
  value: string
  type: PreviewVisualType
  label?: string
}

const PreviewVisuals: Record<
  PreviewVisualType,
  {
    Component: React.FunctionComponent
    label: () => string
  }
> = {
  Gauge: {
    Component: () => {
      const { singleValue } = usePreviewDataContext()
      return <GaugeDisplay value={singleValue} />
    },
    label: () => t`Displayed as a gauge`,
  },
  Line: {
    Component: () => {
      const lineColor = useToken('colors', 'gray.600')
      const { lineData } = usePreviewDataContext()
      return (
        <Flex alignItems="center" h="100%">
          <ResponsiveContainer width="100%" height="70%">
            <LineChart width={300} height={100} data={lineData}>
              <Line
                type="monotone"
                dataKey="value"
                stroke={lineColor}
                strokeWidth={1.5}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Flex>
      )
    },
    label: () => t`Displayed as a line chart`,
  },
  List: {
    Component: () => {
      const color = useToken('colors', 'gray.500')
      const viewBoxSize = 100
      const lineCount = 4
      const lineHeight = 6
      const spacing = viewBoxSize / (lineCount + 1)
      return (
        <Flex alignItems="center" h="100%">
          <svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
            version="1.1"
          >
            {range(lineCount).map((x) => (
              <g
                key={x}
                transform={`translate(${spacing}, ${spacing + x * spacing})`}
                fill={color}
              >
                <circle cx={0} cy={0} r={(lineHeight * 2) / 3} fill={color} />
                <rect
                  x={lineHeight / 2 + spacing / 2}
                  y={lineHeight / -2}
                  width={48}
                  height={lineHeight}
                  rx={lineHeight / 2}
                />
              </g>
            ))}
          </svg>
        </Flex>
      )
    },
    label: () => t`Displayed as a list of employees or teams`,
  },
  Value: {
    Component: () => {
      const { singleValue, goal } = usePreviewDataContext()
      return (
        <SingleValueDisplay.ValueContainer value={singleValue} goal={goal}>
          <Center flexDirection="column">
            <SingleValueDisplay.ValueDisplay />
          </Center>
        </SingleValueDisplay.ValueContainer>
      )
    },
    label: () => t`Displayed as a number`,
  },
}

export function VisualField<TValue extends string>({
  name = 'visual',
  isRequired,
  options: optionsProp,
}: {
  name?: string
  isRequired?: boolean
  isDisabled?: boolean
  options: (
    | PreviewVisualType
    | {
        [key in PreviewVisualType]?: TValue | TValue[]
      }
  )[]
}): React.ReactElement {
  const { options, valueToTypeDict } = optionsProp.reduce<{
    options: { type: PreviewVisualType; value: TValue }[]
    valueToTypeDict: { [key: string]: PreviewVisualType }
  }>(
    (memo, o) => {
      if (typeof o === 'string') {
        memo.options.push({
          type: o,
          value: o as TValue,
        })
        memo.valueToTypeDict[o] = o
      } else {
        const [type, value] = Object.entries(o)[0]
        const firstValue = Array.isArray(value) ? value[0] : value
        memo.options.push({
          type: type as PreviewVisualType,
          value: firstValue,
        })
        ;(Array.isArray(value) ? value : [value]).forEach((x) => {
          memo.valueToTypeDict[x] = type as PreviewVisualType
        })
      }
      return memo
    },
    {
      options: [],
      valueToTypeDict: {},
    },
  )

  const visualPreviewSize = 40
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel htmlFor="widget-visual">
        <Trans>Visual</Trans>
      </FormLabel>
      <PreviewDataProvider>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <RadioButtonGroup
                {...field}
                value={valueToTypeDict[field.value]}
                onChange={(type) => {
                  const option = options.find((o) => o.type === type)
                  if (option) {
                    field.onChange(option.value)
                  }
                }}
                name="visual"
                spacing={2}
              >
                {options.map((o) => {
                  const { Component, label } = PreviewVisuals[o.type]
                  return (
                    <VisualRadioButton
                      key={o.type}
                      value={o.type}
                      h={`${visualPreviewSize}px`}
                      w={`${visualPreviewSize}px`}
                    >
                      <Tooltip label={label()}>
                        <Box w="full" h="full">
                          <Component />
                        </Box>
                      </Tooltip>
                    </VisualRadioButton>
                  )
                })}
              </RadioButtonGroup>
            )
          }}
        />
      </PreviewDataProvider>
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}

type VisualRadioButtonProps = {
  value?: string | number
  radioProps?: UseRadioProps
  colorScheme?: string
} & BoxProps

const VisualRadioButton = React.forwardRef<
  HTMLDivElement,
  VisualRadioButtonProps
>(function CustomRadio(
  { children, radioProps, colorScheme = 'primary', ...restProps },
  ref,
) {
  const { getInputProps, getCheckboxProps } = useRadio(radioProps)
  const inputProps = getInputProps()
  const checkboxProps = getCheckboxProps()
  const highlightColor = useToken('colors', `${colorScheme}.500`)
  return (
    <Box as="label" ref={ref} flexShrink={0} {...restProps}>
      <input {...inputProps} id={`visual-radio-${restProps.value}`} />
      <Box
        {...checkboxProps}
        fontSize="sm"
        fontWeight="medium"
        cursor="pointer"
        borderRadius="md"
        bg="gray.200"
        _checked={{
          bg: 'gray.300',
          color: 'white',
          boxShadow: `0 0 0 1px #fff, 0 0 0 3px ${highlightColor}`,
        }}
        p={1}
        h="full"
        w="full"
      >
        {children}
      </Box>
    </Box>
  )
})

export const VisualRadioField: React.FC<
  Pick<
    RadioFieldProps,
    'label' | 'description' | 'options' | 'id' | 'orientation'
  >
> = ({ label, description, options, id: idProp, orientation }) => {
  const name = 'visual'
  const { watch, setValue } = useFormContext()
  const id = idProp ?? 'widget-radio-field'
  const visualValue = watch(name)
  return (
    <FormControl>
      <FormLabel htmlFor={id} mb={description ? 0 : 2}>
        {label}
      </FormLabel>
      {description && (
        <Description fontSize="sm" mb={2}>
          {description}
        </Description>
      )}

      <RadioOptions
        id={id}
        value={visualValue}
        onChange={(nextValue) => {
          setValue(name, nextValue)
        }}
        options={options}
        orientation={orientation}
      />
    </FormControl>
  )
}
