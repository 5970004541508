import { TimeSeriesTooltipContent } from '@capturi/charts'
import { TimeResolution } from '@capturi/core'
import { List, ListItem, Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import React from 'react'
import { TooltipProps } from 'recharts'

import PopperPortal from './PopperPortal'

const formatNameWithValue = (
  name: string | undefined,
  formattedValue: string,
): string | undefined => {
  if (name === undefined || typeof name !== 'string' || name.length === 0) {
    return formattedValue
  }

  return `${name}: ${formattedValue}`
}

const formatPayload = (
  payload:
    | {
        value?: number
        name?: string
      }[]
    | undefined,
  customValueFormatter: (value: number) => string,
): React.ReactElement | undefined => {
  const lines = payload?.map((p) => {
    if (p.value == null) {
      return undefined
    }

    const formattedValue = customValueFormatter(p.value)
    const formattedLine = formatNameWithValue(p.name, formattedValue)
    if (formattedLine == null) {
      return undefined
    }
    return formattedLine
  })

  if (lines == null || lines.length === 0) {
    return undefined
  }

  return (
    <List alignItems="start" gap="0">
      {lines.map((l) => l && <ListItem key={l}>{l}</ListItem>)}
    </List>
  )
}

const CustomTooltip = ({
  active,
  payload,
  label,
  resolution,
  customValueFormatter,
}: TooltipProps<number, string> & {
  resolution: TimeResolution
  customValueFormatter: (value: number) => string
}): React.ReactElement | null => {
  const formattedPayload = formatPayload(
    payload
      ?.sort((p1, p2) => {
        return (p2.value ?? 0) - (p1.value ?? 0)
      })
      ?.map((p) => {
        return {
          value: p.value,
          name: p.name,
        }
      }),
    customValueFormatter,
  )

  if (!active || formattedPayload == null) return null
  const title = i18n.date(label, {
    dateStyle: 'long',
    timeStyle: resolution === 'Hours' ? 'short' : undefined,
  })
  return (
    <PopperPortal>
      <TimeSeriesTooltipContent>
        <Text fontWeight="medium">{title}</Text>
        {formattedPayload}
      </TimeSeriesTooltipContent>
    </PopperPortal>
  )
}

export default CustomTooltip
