import { PhoneFilterValues, TextFilterValues } from '@capturi/api-filters'
import { AccessLevel } from '@capturi/api-shared'
import {
  Acl,
  Nullable,
  PermissionPreset,
  Role,
  TimeResolution as _TimeResolution,
} from '@capturi/core'

import { WidgetBackgroundColorName } from '../widgets/colors'

export type AtLeast<T, K extends keyof T> = Partial<T> & Pick<T, K>

// Union type equivalent to Enum
export const Widget = {
  WidgetNotFound: '__WidgetNotFound__',
  TrackerHits: 'insights_speaker-trackerhits_single',
  TrackerTimeSeries: 'insights_speaker-trackerhits-time-series',
  ConversationsDuration: 'insights_summaries-activity_duration',
  HitRate: 'insights_share',
  Score: 'insights_score',
  Header: 'header',
} as const

export type WidgetType = (typeof Widget)[keyof typeof Widget]

type WidgetTextFiltersAsReference = {
  savedCaseFilterUid: string
} & {
  [P in keyof Nullable<TextFilterValues>]?: never
}

type WidgetTextFiltersAsValues = Nullable<TextFilterValues> & {
  savedCaseFilterUid?: never
}

/**
 * Widget text filters can either be a collection filter key/value pairs
 * or a single `savedCaseFilterUid` property with a reference to a saved filter
 */
export type WidgetTextFilters =
  | WidgetTextFiltersAsReference
  | WidgetTextFiltersAsValues

type WidgetFiltersAsReference = {
  savedFilterGroupUid: string
} & {
  [P in keyof PhoneFilterValues]?: never
}

type WidgetFiltersAsValues = Partial<PhoneFilterValues> & {
  savedFilterGroupUid?: never
}

/**
 * Widget filters can either be a collection filter key/value pairs
 * or a single `savedFilterGroupUid` property with a reference to a saved filter
 */
export type WidgetFilters = WidgetFiltersAsReference | WidgetFiltersAsValues

export type WidgetTrendType =
  | null
  | 'MonthToDate'
  | 'QuarterToDate'
  | 'YearToDate'
  | 'Trend' /* Only used in client to select */
  | 'None' /* Only used in client to disable showPreviousPeriodTrendIndicator */

export type WidgetModel = {
  uid: string
  title?: string | null
  description?: string | null
  position: {
    x: number
    y: number
  }
  size: {
    width: number
    height: number
  }

  filters: WidgetFilters | null // can be either savedFilterGroupUid or an object with values
  shareFilter: WidgetFilters | null // can be either savedFilterGroupUid or an object with values

  textFilters: WidgetTextFilters | null // can be either savedCaseFilterUid or an object with values
  textShareFilter: WidgetTextFilters | null // can be either savedCaseFilterUid or an object with values

  type: WidgetType
  scoreName?: string
  scoreUid?: string
  visual: string
  backgroundColor?: WidgetBackgroundColorName | null
  previousPeriodTrendInterval?: WidgetTrendType
  // Only applicable when `visual` is `Value`: Whether a trend indicator (relative to previous period) should be displayed in the widget.
  showPreviousPeriodTrendIndicator?: boolean
}

export type ValueRange = {
  min?: number | null
  max?: number | null
}

export type WidgetGoal = ValueRange

export type TimeResolution = _TimeResolution | 'Auto'

export type SharedContextType = 'Organization' | 'Team' | 'User'

export type WidgetCreateEditResult<P extends WidgetModel = WidgetModel> =
  AtLeast<P, 'type' | 'filters' | 'visual'>

export type Permissions = {
  roles?: Role[]
  users?: string[]
}

export type BaseDashboard = {
  columns: number
  description?: string
  folderUid?: string | null
  organizationUid: string
  rows: number
  shared: boolean
  sharedContextType: SharedContextType | null
  title: string
  uid: string
  permissions: Permissions | null
  permissionPreset: PermissionPreset
  permissionPresetUserUid: string | null
  createdByUserUid: string | null
  updatedByUserUid: string | null
  created: Date | null
  updated: Date | null
  acl: Acl | null
  accessLevel: AccessLevel
}

export type Dashboard = BaseDashboard & {
  widgets: Array<
    WidgetModel & {
      [key: string]: unknown
    }
  >
  accessKey: AccessKey | null
}

export type AccessKey = {
  key: string
}

export type DashboardListItem = BaseDashboard & {
  hasAccessKey: boolean
}

export type DashboardsListResponseModel = {
  dashboards: DashboardListItem[]
}

export type CreateDashboardRequestModel = {
  title: string
  description?: string
  folderUid?: string | null
  columns: number
  rows: number
  shared?: boolean
  sharedContextType?: SharedContextType
  permissions?: Permissions | null

  permissionPreset: PermissionPreset
  permissionPresetUserUid?: string | null
  acl?: Acl | null
}

export type CreateDashboardModel = CreateDashboardRequestModel & {
  hasWarnings: boolean
}

export type UpdateDashboardRequestModel = Partial<CreateDashboardRequestModel>
export type UpdateDashboardModel = Partial<CreateDashboardModel>

export type DashboardFolder = {
  uid: string
  title: string | null
  description: string | null
  accessLevel: AccessLevel
  permissionPreset: PermissionPreset
  permissionPresetUserUid?: string | null
  acl: Acl | null
}

export type DashboardFoldersListResponseModel = {
  folders: DashboardFolder[]
}

export type CreateDashboardFolderRequestModel = {
  title: string
  description?: string
  permissionPreset: PermissionPreset
  permissionPresetUserUid: string | null
  acl: Acl | null
}
