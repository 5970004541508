import { Trans, t } from '@lingui/macro'

import { NumberInputComponentProps, ValueRangeField } from './ValueRangeField'
import { FormField } from './types'

export const GoalField: FormField<null, NumberInputComponentProps> = (
  props,
) => {
  return (
    <ValueRangeField
      label={t`Goal`}
      name="goal"
      description={
        <Trans>
          Set a goal. The goal will be displayed as part of your widget
        </Trans>
      }
      {...props}
    />
  )
}
