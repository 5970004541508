import { AccessKey, Dashboard, WidgetModel } from '../types'

type MaybeDashboard = Dashboard | null | undefined
/**
 * Dashboard
 */
export const patchDashboard = (
  d1: MaybeDashboard,
  d2: Partial<Dashboard>,
): MaybeDashboard => (d1 ? { ...d1, ...d2 } : undefined)

/**
 * Widgets
 */
export const patchWidget = (
  d: MaybeDashboard,
  widget: Partial<WidgetModel>,
): MaybeDashboard =>
  d
    ? {
        ...d,
        widgets: d.widgets.map((x) =>
          x.uid === widget.uid ? { ...x, ...widget } : x,
        ),
      }
    : undefined

export const replaceWidget = (
  d: MaybeDashboard,
  widget: WidgetModel,
): MaybeDashboard =>
  d
    ? {
        ...d,
        widgets: d.widgets.map((x) => (x.uid === widget.uid ? widget : x)),
      }
    : undefined

export const deleteWidget = (d: MaybeDashboard, uid: string): MaybeDashboard =>
  d
    ? {
        ...d,
        widgets: d.widgets.filter((x) => x.uid !== uid),
      }
    : undefined

/**
 * Access keys
 */
export const addAccessKey = (
  d: MaybeDashboard,
  accessKey: AccessKey,
): MaybeDashboard => (d ? { ...d, accessKey } : undefined)

export const deleteAccessKey = (d: MaybeDashboard): MaybeDashboard =>
  d
    ? {
        ...d,
        accessKey: null,
      }
    : undefined
