import noop from 'lodash/noop'
import { createContext } from 'react'

import { WidgetType } from '../../types'

export type ConfiguratorContextType = {
  isEditMode: boolean
  setType?: (type: WidgetType) => void
}

export const ConfiguratorContext = createContext<ConfiguratorContextType>({
  isEditMode: false,
  setType: noop,
})
