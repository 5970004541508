import analytics, { EventParams } from '@capturi/analytics'

import { WidgetModel } from './types'

export enum DashboardEvent {
  TogglePlotOnlyWeekdays = 'dashboard_TogglePlotOnlyWeekdays',
  LeaveFullscreen = 'dashboard_LeaveFullscreen',
}

export enum DashboardAdminEvent {
  OpenCreateDialog = 'dashboard_OpenCreateDialog',
  OpenEditDialog = 'dashboard_OpenEditDialog',
  DashboardCreated = 'dashboard_DashboardCreated',
  DeleteDashboard = 'dashboard_DeleteDashboard',
  SetDatePeriod = 'dashboard_SetDatePeriod',
  InsufficientSpace = 'dashboard_InsufficientSpace',
  OpenCloneDialog = 'dashboard_OpenCloneDialog',
  DashboardCloned = 'dashboard_DashboardCloned',
  OpenDashboardSettings = 'dashboard_OpenDashboardSettings',
  DashboardSaved = 'dashboard_DashboardSaved',
  DashboardUpgradeDialogOpened = 'dashboard_upgradeDialog_Opened',
  DashboardUpgradeDialogDiscarded = 'dashboard_upgradeDialog_Discarded',
  DashboardUpgradeDialogProceeded = 'dashboard_upgradeDialog_Proceeded',
  // Sharing
  EnableSharing = 'dashboard_share_EnableSharing',
  DisableSharing = 'dashboard_share_DisableSharing',
  SetSharedContextType = 'dashboard_share_SetSharedContextType',
  OverrideSharedContext = 'dashboard_share_OverrideSharedContext',
  EnableSharedLink = 'dashboard_share_EnableSharedLink',
  DisableSharedLink = 'dashboard_share_DisableSharedLink',
  SetSharedLinkTitle = 'dashboard_share_SetSharedLinkTitle',
  CopySharedLink = 'dashboard_share_CopySharedLink',
  OpenSharedLink = 'dashboard_share_OpenSharedLink',
  // Create widget
  OpenCreateWidgetDialog = 'dashboard_widgets_OpenCreateWidgetDialog',
  // Folders
  OpenCreateFolderDialog = 'dashboard_OpenCreateFolderDialog',
  OpenEditFolderDialog = 'dashboard_OpenEditeFolderDialog',
  DashboardEditorFolderCleared = 'dashboard_editor_FolderCleared',
  DashboardFolderCreated = 'dashboard_FolderCreated',
  DashboardEditorFolderSelected = 'dashboard_editor_FolderSelected',
  DashboardFolderAssignedViaQuickMenu = 'dashboard_FolderAssignedViaQuickMenu',
  DashboardFolderClearedViaQuickMenu = 'dashboard_FolderClearedViaQuickMenu',
}

export enum WidgetEvent {
  OpenEditDialog = 'dashboard_widgets_OpenEditDialog',
  WidgetAdded = 'dashboard_widgets_WidgetAdded',
  WidgetEdited = 'dashboard_widgets_WidgetEdited',
  DeleteWidget = 'dashboard_widgets_DeleteWidget',
  CloneWidget = 'dashboard_widgets_CloneWidget',
  WidgetResized = 'dashboard_widgets_WidgetResized',
  WidgetMoved = 'dashboard_widgets_WidgetMoved',
}

export enum ErrorWidgetEvent {
  ErrorWidgetImpression = 'dashboard_ErrorWidget_Impression',
  RefreshWidgetClicked = 'dashboard_ErrorWidget_RefreshClicked',
  EditWidgetClicked = 'dashboard_ErrorWidget_EditClicked',
}

export function logEvent(
  event: DashboardAdminEvent | DashboardEvent | string,
  data?: EventParams,
): void {
  analytics.event(event, data)
}

export function logWidgetEvent(
  event: WidgetEvent | ErrorWidgetEvent,
  widget: Pick<WidgetModel, 'type' | 'visual'>,
  data?: EventParams,
): void {
  analytics.event(event, {
    ...data,
    type: widget.type,
    visual: widget.visual,
  })
}
