import { Box, TextProps } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

export const QuoteBox: React.FC<{ children: ReactNode } & TextProps> = ({
  children,
  ...textProps
}) => {
  return (
    <Box w="100%">
      <Box
        borderLeft="3px solid"
        borderColor="blackAlpha.200"
        margin="2"
        p="2"
        {...textProps}
      >
        {children}
      </Box>
    </Box>
  )
}
