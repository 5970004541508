import { css } from '@emotion/react'
import clamp from 'lodash/clamp'

export function getFontSize(containerWidth: number): number {
  // font size get calculated based on the container width
  return clamp(containerWidth * 0.1, 16, 64)
}

export function getAvatarSize(containerWidth: number): string {
  if (containerWidth >= 400) return 'md'
  if (containerWidth >= 300) return 'sm'
  return 'xs'
}

export const labelCss = css`
  font-size: min(32px, max(14px, 0.75em));
  line-height: 1.2;
`

export const subLabelCss = css`
  font-size: min(16px, max(12px, 0.6em));
  line-height: 1.2;
`

export const valueCss = css`
  font-size: min(32x, max(14px, 0.75em));
  font-weight: 500;
  line-height: 1.2;
`
