import { PrimaryLine } from '@capturi/charts'
import { Box, Center, Flex, Table, Text, chakra } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import React from 'react'
import { BiCaretUp } from 'react-icons/bi'

import { WidgetModel, WidgetTrendType } from '../../../types'
import { HitRateWidgetModel } from '../../Hitrate/types'

import GaugeDisplay from '../visuals/GaugeDisplay'
import {
  Cell,
  FlexibleCell,
  ListContainer,
  getFontSize,
} from '../visuals/ListDisplay'
import SingleValue from '../visuals/SingleValueDisplay'
import { trendLabels } from '../visuals/SingleValueDisplay/utils'
import { PreviewChartContainer } from './PreviewChartContainer'
import { lines } from './PreviewWidgetData'

type Props = {
  type?: WidgetModel['type']
  goal?: HitRateWidgetModel['goal']
  value?: HitRateWidgetModel['value']
  visual: WidgetModel['visual']
  showTrendIndicator?: HitRateWidgetModel['showPreviousPeriodTrendIndicator']
  trackerUidsCount?: number
  benchmark?: WidgetTrendType
  unit: '%' | ''
}

export const VisualGraph: React.FC<Props> = ({
  visual,
  benchmark,
  goal,
  showTrendIndicator,
  trackerUidsCount,
  type,
  value,
  unit,
}) => {
  const isDuration = type === 'insights_summaries-activity_duration'

  const visualValue = value === 'AveragePerUser' ? 3.5 : 35
  const trendLabel = trendLabels.get(benchmark)
  const isBenchmarkTrend = benchmark === 'Trend'

  switch (visual) {
    case 'Value':
      return (
        <Box h="60">
          <SingleValue.ValueContainer
            value={visualValue}
            goal={goal}
            unit={unit}
            isPreview={true}
            formatValue={(val) => {
              if (isDuration) return '05:25'
              return i18n.number(val)
            }}
          >
            <Center flexDirection="column">
              <SingleValue.ValueDisplay />
              {showTrendIndicator && (
                <Flex
                  mt="0.2em"
                  color="gray.500"
                  fontWeight="medium"
                  fontSize="0.2em"
                  align="center"
                  gap="0.5"
                >
                  {isBenchmarkTrend && <BiCaretUp />}
                  {trendLabel && (
                    <Text fontWeight="normal" fontSize="0.5em">
                      {i18n._(trendLabel.short)}
                    </Text>
                  )}

                  <Box bg="gray.500" p="2" borderRadius="50" w="30px" h="2" />
                  {unit}
                </Flex>
              )}
            </Center>
          </SingleValue.ValueContainer>
        </Box>
      )

    case 'UserList':
    case 'List': {
      const fontSize = getFontSize(30)
      return (
        <ListContainer h="60">
          <Table fontSize={`${fontSize}px`}>
            <chakra.tbody>
              {Array(20)
                .fill(45)
                .map((_, i) => {
                  return (
                    <chakra.tr key={i}>
                      <Cell border="none" pl="0">
                        <Box
                          bg="gray.300"
                          borderRadius="50%"
                          w="8"
                          h="8"
                          mt="1"
                        />
                      </Cell>
                      <FlexibleCell border="none">
                        <Box bg="gray.300" w="45%" h="3" borderRadius="50px" />
                        <Box
                          bg="gray.300"
                          w="25%"
                          h="3"
                          mt="1"
                          borderRadius="50px"
                        />
                      </FlexibleCell>
                      <Cell border="none" pr="0">
                        <Flex>
                          <Box
                            bg="gray.300"
                            w="30px"
                            h="4"
                            borderRadius="50px"
                            display="inline-block"
                            mr={value === 'Percent' ? '1' : 'auto'}
                          />
                          {unit && (
                            <Flex alignItems="center">
                              <Text as="span">{unit}</Text>
                            </Flex>
                          )}
                        </Flex>
                      </Cell>
                    </chakra.tr>
                  )
                })}
            </chakra.tbody>
          </Table>
        </ListContainer>
      )
    }

    case 'TeamList':
      return (
        <ListContainer h="60">
          <Table>
            <chakra.tbody>
              {new Array(20).fill(45).map((_x, i) => {
                return (
                  <chakra.tr key={i}>
                    <FlexibleCell border="none" pl="0">
                      <Box>
                        <Box bg="gray.300" w="45%" h="3" borderRadius="50px" />
                      </Box>
                      <Box
                        bg="gray.300"
                        w="25%"
                        h="3"
                        mt="1"
                        borderRadius="50px"
                      />
                    </FlexibleCell>
                    <Cell border="none" pr="0">
                      <Flex>
                        <Box
                          bg="gray.300"
                          w="30px"
                          h="4"
                          borderRadius="50px"
                          display="inline-block"
                          mr={value === 'Percent' ? '1' : 'auto'}
                        />
                        {unit && (
                          <Flex alignItems="center">
                            <Text as="span">{unit}</Text>
                          </Flex>
                        )}
                      </Flex>
                    </Cell>
                  </chakra.tr>
                )
              })}
            </chakra.tbody>
          </Table>
        </ListContainer>
      )

    case 'Line':
      return (
        <PreviewChartContainer isPercentageValue={value === 'Percent'}>
          {lines.tracker.slice(0, trackerUidsCount).map((line) => (
            <PrimaryLine key={line.id} line={line} />
          ))}
        </PreviewChartContainer>
      )
    case 'Graph':
    case 'GraphTotal':
      return (
        <PreviewChartContainer isPercentageValue={value === 'Percent'}>
          {lines.primary.map((line) => (
            <PrimaryLine key={line.id} line={line} />
          ))}
        </PreviewChartContainer>
      )
    case 'GraphPerTeam':
      return (
        <PreviewChartContainer isPercentageValue={value === 'Percent'}>
          {lines.teams.map((line) => (
            <PrimaryLine key={line.id} line={line} />
          ))}
        </PreviewChartContainer>
      )
    case 'GraphPerUser':
      return (
        <PreviewChartContainer isPercentageValue={value === 'Percent'}>
          {lines.users.map((line) => (
            <PrimaryLine key={line.id} line={line} />
          ))}
        </PreviewChartContainer>
      )

    default:
      return (
        <Box h="60">
          <GaugeDisplay
            isPreview={true}
            value={visualValue}
            unit={unit}
            referenceValue={80}
            showPreviousPeriodTrendIndicator={showTrendIndicator}
            benchmark={benchmark}
          />
        </Box>
      )
  }
}
