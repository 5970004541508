import { Period, useFilterPeriodContext } from '@capturi/filters'
import { ResponseError } from '@capturi/request'
import { Center } from '@chakra-ui/react'
import React from 'react'
import { SWRConfiguration, SWRResponse } from 'swr'
import useWidgetData from '../../../hooks/useWidgetData'
import {
  Container,
  Content,
  Description,
  Footer,
  Goal,
  SubText,
  Title,
} from '../../components/Widget'
import GaugeDisplay from '../../components/visuals/GaugeDisplay'
import SingleValue from '../../components/visuals/SingleValueDisplay'
import { FormatHits } from '../../utils/format'
import { getTrendPeriod } from '../../utils/getTrendPeriod'
import { HitRateData, HitRateWidgetModel } from '../types'

export type TextShareStatisticsResponseModel = {
  cases: number
  casesShare: number
  casesSharePercent: number
  casesAveragePerUser: number
  casesShareAveragePerUser: number
}

export type ConversationsShareStatisticsResponseModel = {
  conversations: number
  conversationsShare: number
  conversationsSharePercent: number
  conversationsAveragePerUser: number
  conversationsShareAveragePerUser: number
}

function getValuesByVisualType(
  valueType: HitRateWidgetModel['value'],
  data?: HitRateData,
): {
  value?: number
  unit?: string
  maxValue?: number
} {
  if (data == null) {
    return {}
  }
  switch (valueType) {
    case 'AveragePerUser':
      return {
        value: data.shareAveragePerUser,
        maxValue: data.share,
      }
    case 'Number':
      return {
        value: data.share,
        maxValue: data.entities,
      }
    case 'Percent':
      return {
        value: data.sharePercent,
        maxValue: 100,
        unit: '%',
      }
    default:
      return {}
  }
}

export type HitRateSingleValueProps = {
  widget: HitRateWidgetModel
}

export type TargetStatistics = {
  targets: number
  targetsShare: number
  targetsSharePercent: number
  users: {
    userUid: string
    targets: number
    targetsShare: number
    targetsSharePercent: number
  }[]
}

function useData(
  widget: HitRateWidgetModel,
  period?: Period,
  swrConfig: SWRConfiguration = {},
): SWRResponse<
  ConversationsShareStatisticsResponseModel | TextShareStatisticsResponseModel,
  ResponseError
> {
  return useWidgetData<
    ConversationsShareStatisticsResponseModel | TextShareStatisticsResponseModel
  >(
    widget,
    () => ({
      /**
       * `visual` is not used by the API but works here as a part of the SWR cache key.
       * If not present, then after changing the visual type of a widget that widget could
       * initially be served data conforming to the previous visual type from the cache.
       **/
      visual: widget.visual,
    }),
    period,
    swrConfig,
  )
}

function usePreviousPeriodData(
  widget: HitRateWidgetModel,
  period: Period,
  isEnabled = false,
): ReturnType<typeof useData> {
  return useData(widget, period, {
    suspense: false,
    isPaused: () => !isEnabled,
  })
}

function mapWidgetData(
  data:
    | TextShareStatisticsResponseModel
    | ConversationsShareStatisticsResponseModel
    | undefined,
): HitRateData | undefined {
  if (data === undefined) return undefined
  if ('conversations' in data) {
    const d = data as ConversationsShareStatisticsResponseModel
    return {
      averagePerUser: d.conversationsAveragePerUser,
      entities: d.conversations,
      share: d.conversationsShare,
      shareAveragePerUser: d.conversationsShareAveragePerUser,
      sharePercent: d.conversationsSharePercent,
    }
  }
  const d = data as TextShareStatisticsResponseModel
  return {
    averagePerUser: d.casesAveragePerUser,
    entities: d.cases,
    share: d.casesShare,
    shareAveragePerUser: d.casesShareAveragePerUser,
    sharePercent: d.casesSharePercent,
  }
}

export const HitRateSingleValue: React.FC<HitRateSingleValueProps> = ({
  widget,
}) => {
  const {
    title,
    description,
    goal,
    showPreviousPeriodTrendIndicator,
    previousPeriodTrendInterval,
  } = widget

  const { data: rawData } = useData(widget)

  const data = mapWidgetData(rawData)
  const { period: initialPeriod } = useFilterPeriodContext()
  const trendPeriod = getTrendPeriod(previousPeriodTrendInterval, initialPeriod)
  const { value, maxValue, unit } = getValuesByVisualType(widget.value, data)
  const benchmark = (() => {
    if (previousPeriodTrendInterval == null && showPreviousPeriodTrendIndicator)
      return 'Trend'
    return previousPeriodTrendInterval
  })()

  const { data: rawPreviousData } = usePreviousPeriodData(
    widget,
    trendPeriod,
    showPreviousPeriodTrendIndicator ?? false,
  )
  const previousPeriodData = mapWidgetData(rawPreviousData)
  const { value: previousValue } = getValuesByVisualType(
    widget.value,
    previousPeriodData,
  )

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Content>
        {widget.visual === 'Gauge' ? (
          <GaugeDisplay
            value={value}
            goal={goal}
            unit={unit}
            max={maxValue}
            showPreviousPeriodTrendIndicator={showPreviousPeriodTrendIndicator}
            referenceValue={previousValue}
            benchmark={benchmark}
          />
        ) : (
          <SingleValue.ValueContainer value={value} goal={goal} unit={unit}>
            <Center flexDirection="column">
              <SingleValue.ValueDisplay />
              {showPreviousPeriodTrendIndicator && (
                <SingleValue.TrendDisplay
                  referenceValue={previousValue}
                  benchmark={benchmark}
                />
              )}
            </Center>
          </SingleValue.ValueContainer>
        )}
      </Content>
      <Footer>
        <Goal {...goal} unit={unit} value={value} />
        <SubText>
          <FormatHits
            hits={widget.value === 'Percent' ? data?.share : undefined}
            total={data?.entities}
          />
        </SubText>
      </Footer>
    </Container>
  )
}
