import { Period } from '@capturi/filters'

import { TimeResolution } from '../../types'
import { getResolutionFromTimespan } from './timeResolution'

export function getEffectiveTimeResolution(
  resolution: TimeResolution,
  period: Period,
): Exclude<TimeResolution, 'Auto'> {
  if (resolution === 'Auto')
    return getResolutionFromTimespan(period.from, period.to)
  return resolution
}
