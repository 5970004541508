import { useUsers } from '@capturi/stores'
import {
  CheckboxOption,
  GroupBase,
  OptionProps,
  PopoutSelect,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box, Text } from '@chakra-ui/react'
import { Plural, t } from '@lingui/macro'
import React from 'react'

import { useSelectedSharedContext } from '../../../contexts/ContextualDashboardContext'

type UserSelectOption = SelectOption & {
  title?: string
  email?: string
}

type UserSelectProps = {
  onSelectUsers: (uids: string[]) => void
}

export const UserSelect: React.FC<UserSelectProps> = ({ onSelectUsers }) => {
  const { users, getUserByUid } = useUsers()
  const selectedSharedContext = useSelectedSharedContext()

  const allUserOptions: UserSelectOption[] = React.useMemo(() => {
    return users
      .map((x) => ({
        value: x.uid,
        label: x.name + (x.title ? ` (${x.title})` : ''),
        title: x.title,
        email: x.email,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [users])

  const [selectedUids, setSelectedUids] = React.useState<string[] | undefined>(
    selectedSharedContext && selectedSharedContext.type === 'User'
      ? selectedSharedContext.value
      : undefined,
  )

  const selectedOptions = React.useMemo(
    () =>
      selectedUids?.map((x) => {
        const user = getUserByUid(x)
        if (user) {
          return {
            value: user.uid,
            label: user.name + (user.title ? ` (${user.title})` : ''),
            title: user.title,
            email: user.email,
          }
        }

        return {
          value: x,
          label: x,
        }
      }) ?? [],
    [selectedUids, getUserByUid],
  )

  const selectedOptionsGroup: GroupBase<UserSelectOption> = React.useMemo(
    () => ({
      label: t`Selected`,
      options: selectedOptions,
    }),
    [selectedOptions],
  )

  const allUserOptionsGroup: GroupBase<UserSelectOption> = React.useMemo(
    () => ({
      label: t`All users`,
      options: allUserOptions,
    }),
    [allUserOptions],
  )

  const options = [selectedOptionsGroup, allUserOptionsGroup]

  return (
    <PopoutSelect<UserSelectOption, true>
      isMulti
      closeMenuOnSelect={false}
      components={{ Option }}
      formatSelectedCount={(count: string | number) => (
        <Plural value={count} one={'1 selected'} other={'# selected'} />
      )}
      isSubmitDisabled={selectedOptions.length === 0}
      onChange={(option) => {
        setSelectedUids(option.map((o) => o.value))
      }}
      onSubmit={(option) => {
        onSelectUsers?.(option.map((o) => o.value))
      }}
      options={options}
      selectAllText={t`Select all`}
      value={selectedOptions}
    />
  )
}

const Option: React.ComponentType<OptionProps<UserSelectOption, true>> = (
  props,
) => {
  const { label, title, email } = props.data as UserSelectOption
  const subtext = [title, email].filter(Boolean).join(', ')
  return (
    <components.Option {...props}>
      <CheckboxOption isSelected={props.isSelected}>
        <Box maxW="xs">
          <Box noOfLines={1} wordBreak="break-all">
            {label}
          </Box>
          <Text
            fontSize="xs"
            color="textMuted"
            noOfLines={1}
            wordBreak="break-all"
          >
            {subtext}
          </Text>
        </Box>
      </CheckboxOption>
    </components.Option>
  )
}
