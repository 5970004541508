import { Role, useCurrentUser, useTeams } from '@capturi/core'
import { useUsers } from '@capturi/stores'
import { Button, ButtonProps } from '@capturi/ui-components'
import { Tooltip } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import noop from 'lodash/noop'
import React from 'react'
import { MdRemoveRedEye } from 'react-icons/md'

import {
  SharedContext,
  useOriginalSharedContextType,
  useSelectedSharedContext,
} from '../../contexts/ContextualDashboardContext'
import { SharedContextType } from '../../types'

type UseValue = (
  type: SharedContextType | undefined,
  value: string[] | undefined,
) => UseValueReturn

type UseValueReturn = {
  tooltip: string | undefined
  value: string | undefined
}

const useUsersValue: UseValue = (type, value) => {
  const isApplicable = type === 'User' && value != null && value.length > 0
  const { getUserByUid } = useUsers(!isApplicable)
  if (!isApplicable) return { tooltip: undefined, value: undefined }

  const usersCount = value.length
  if (usersCount === 1) {
    const user = getUserByUid(value[0])
    return { tooltip: undefined, value: user?.name ?? value[0] }
  }
  const userNames = value.map((uid) => getUserByUid(uid).name)
  return { tooltip: userNames.join(', '), value: t`${usersCount} users` }
}

const useTeamsValue: UseValue = (type, value) => {
  const isApplicable = type === 'Team' && value != null && value.length > 0
  const { getTeamByUid, mutate } = useTeams({
    revalidateOnFocus: false,
    suspense: false,
    isPaused: () => !isApplicable,
  })

  React.useEffect(() => {
    if (isApplicable) {
      mutate()
    }
  }, [isApplicable, mutate])

  if (!isApplicable) return { tooltip: undefined, value: undefined }

  const teamsCount = value.length
  if (teamsCount === 1) {
    const selectedTeam = getTeamByUid(value[0])
    return { tooltip: undefined, value: selectedTeam?.name ?? value[0] }
  }
  const teamNames = value.reduce<string[]>((acc, uid) => {
    const team = getTeamByUid(uid)
    if (team != null) {
      acc.push(team.name)
    }
    return acc
  }, [])
  return { tooltip: teamNames.join(', '), value: t`${teamsCount} teams` }
}

export const SharedContextButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(function SharedContextButtonWithRef(props, ref) {
  const currentUser = useCurrentUser()

  let sharedContext = useSelectedSharedContext()
  const originalSharedContextType = useOriginalSharedContextType()

  if (currentUser.role === Role.user) {
    /**
     * 'normal' users are not allowed to override the shared context.
     * In this case set the appropriate values here so that we can
     * show the user info about the implicit context.
     **/
    const selfContext: SharedContext = {
      type: 'User',
      value: [currentUser.id],
    }
    if (originalSharedContextType === 'Organization') {
      sharedContext = {
        type: 'Organization',
      }
    }
    if (originalSharedContextType === 'User') {
      sharedContext = selfContext
    }
    if (originalSharedContextType === 'Team') {
      sharedContext =
        currentUser.teamUid != null
          ? {
              type: originalSharedContextType,
              value: [currentUser.teamUid],
            }
          : selfContext
    }
  }

  // Resolve user or team from shared context value
  const usersValue = useUsersValue(sharedContext?.type, sharedContext?.value)
  const teamsValue = useTeamsValue(sharedContext?.type, sharedContext?.value)

  const { tooltip, value } = ((): UseValueReturn => {
    switch (sharedContext?.type ?? originalSharedContextType) {
      case 'Organization':
        return { tooltip: undefined, value: t`Organization` }
      case 'Team':
        return teamsValue
      case 'User':
        return usersValue
    }
    return { tooltip: undefined, value: undefined }
  })()

  let additionalButtonProps = {}
  if (!currentUser.isAdminOrTeamLead) {
    additionalButtonProps = {
      variant: 'outline',
      pointerEvents: 'none',
      onClick: noop,
      tabIndex: -1,
    }
  }

  return (
    <Tooltip hasArrow label={tooltip} aria-label={tooltip} placement="top">
      <Button
        {...additionalButtonProps}
        {...props}
        ref={ref}
        leftIcon={<MdRemoveRedEye />}
      >
        <Trans>Data is displayed for: {value}</Trans>
      </Button>
    </Tooltip>
  )
})
