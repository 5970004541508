import { Flex, Stack, StackProps, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

export const ConfiguratorLayout: React.FC<StackProps> = (props) => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      borderBottom="1px solid"
      borderColor="gray.200"
      {...props}
    />
  )
}

export const ConfiguratorColumn: React.FC<StackProps> = (props) => {
  return (
    <Stack
      spacing={4}
      pos="relative"
      py="6"
      px="10"
      flex={1}
      _notLast={{
        borderRight: '1px solid',
        borderColor: 'gray.200',
      }}
      {...props}
    />
  )
}
export const WidgetPreviewColumn: React.FC<{
  children: React.ReactNode
  alwaysShow?: boolean
}> = ({ children, alwaysShow = false }) => {
  return (
    <Flex
      flex={1}
      height="auto"
      bg="gray.100"
      p="6"
      borderLeft="1px solid"
      borderColor="gray.200"
      display={{ base: alwaysShow ? 'block' : 'none', lg: 'block' }}
    >
      <Text fontSize="md" fontWeight="medium" mr="2" mb="3">
        <Trans>Example</Trans>
      </Text>
      <Flex
        bg="white"
        p="4"
        borderRadius="4"
        height="fit-content"
        mx="auto"
        boxShadow="lg"
        width="100%"
        direction="column"
      >
        {children}
      </Flex>
    </Flex>
  )
}
