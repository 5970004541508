import { Team } from '@capturi/core'
import {
  CheckboxOption,
  GroupBase,
  OptionProps,
  PopoutSelect,
  SelectOption,
  components,
} from '@capturi/ui-select'
import { Box } from '@chakra-ui/react'
import { Plural, t } from '@lingui/macro'
import React from 'react'
import useSWR from 'swr'

import { useSelectedSharedContext } from '../../../contexts/ContextualDashboardContext'

type TeamSelectProps = {
  onSelectTeams: (uid: string[]) => void
}

export const TeamSelect: React.FC<TeamSelectProps> = ({ onSelectTeams }) => {
  const { data } = useSWR<{ teams: Team[] }>('authentication/filter/teams', {
    suspense: true,
  })

  const selectedSharedContext = useSelectedSharedContext()

  const allTeamsOptions: SelectOption[] = React.useMemo(() => {
    return (data?.teams ?? [])
      .map((x) => ({
        value: x.uid,
        label: x.name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [data])

  const [selectedUids, setSelectedUids] = React.useState<string[] | undefined>(
    selectedSharedContext && selectedSharedContext.type === 'Team'
      ? selectedSharedContext.value
      : undefined,
  )

  const selectedOptions: SelectOption[] = React.useMemo(
    () =>
      selectedUids?.map((x) => {
        const option = allTeamsOptions.find((option) => option.value === x)
        // fixes scroll issue in react-select
        return (option && { ...option }) ?? { value: x, label: x }
      }) ?? [],
    [selectedUids, allTeamsOptions],
  )

  const selectedOptionsGroup: GroupBase<SelectOption> = React.useMemo(
    () => ({
      label: t`Selected`,
      options: selectedOptions,
    }),
    [selectedOptions],
  )

  const allUserOptionsGroup: GroupBase<SelectOption> = React.useMemo(
    () => ({
      label: t`All users`,
      options: allTeamsOptions,
    }),
    [allTeamsOptions],
  )

  const options = [selectedOptionsGroup, allUserOptionsGroup]

  return (
    <PopoutSelect<SelectOption, true>
      isMulti
      closeMenuOnSelect={false}
      components={{ Option }}
      formatSelectedCount={(count: string | number) => (
        <Plural value={count} one={'1 selected'} other={'# selected'} />
      )}
      isSubmitDisabled={selectedOptions.length === 0}
      onChange={(option) => {
        setSelectedUids(option.map((o) => o.value))
      }}
      onSubmit={(option) => {
        onSelectTeams?.(option.map((o) => o.value))
      }}
      options={options}
      selectAllText={t`Select all`}
      value={selectedOptions}
    />
  )
}

const Option: React.ComponentType<OptionProps<SelectOption, true>> = (
  props,
) => {
  return (
    <components.Option {...props}>
      <CheckboxOption isSelected={props.isSelected}>
        <Box maxW="xs">
          <Box noOfLines={1} wordBreak="break-all">
            {props.data.label}
          </Box>
        </Box>
      </CheckboxOption>
    </components.Option>
  )
}
