import { useCurrentUser } from '@capturi/core'
import {
  FilterMenuPlacementProvider,
  SegmentsSubFilterContainer,
  SingleSegmentContainer,
  segmentConfigurations,
  useFilterDefinitions,
} from '@capturi/filters'
import { Button, Caption, Underlined } from '@capturi/ui-components'
import {
  Box,
  Collapse,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, t } from '@lingui/macro'
import invert from 'lodash/invert'
import merge from 'lodash/merge'
import React from 'react'
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form'

import ConfigurationPreviewWidget from '../components/ConfigurationPreviewWidget'
import {
  BenchmarkField,
  ConfiguratorColumn,
  ConfiguratorLayout,
  DescriptionField,
  GoalField,
  RadioField,
  ResolutionField,
  TitleField,
  TypeField,
  VisualField,
  WidgetPreviewColumn,
  YAxisRangeField,
} from '../configurator'
import { RadioOptions } from '../configurator/fields/RadioField'
import { FormModel } from '../configurator/formUtils'
import { useSegmentStatesFormSync } from '../configurator/useSegmentStatesFormSync'
import { WidgetConfigurator } from '../registry'
import { HitRateValueType, HitRateWidgetModel } from './types'

type ValueTypeOption = {
  value: HitRateValueType
  label: string
  description?: string
}
const createValueTypeOptions = (
  visual: HitRateWidgetModel['visual'],
): ValueTypeOption[] => {
  const options: ValueTypeOption[] = [
    {
      value: 'Percent',
      label: t`Percentage`,
      description: t`Displays the hit rate as a percentage (ex. 27%)`,
    },
    {
      value: 'Number',
      label: t`Number`,
      description: t`Displays the hit rate as a total number (ex. 567 conversations)`,
    },
  ]
  if (
    !(['Graph', 'List', 'TeamList'] as HitRateWidgetModel['visual'][]).includes(
      visual,
    )
  ) {
    options.push({
      value: 'AveragePerUser',
      label: t`Average per user`,
      description: t`Displays the hit rate as an average number per user (ex. 31 conversations)`,
    })
  }
  return options
}

type HitRateFormModel = FormModel<Partial<HitRateWidgetModel>>

const defaultFormModel = (): Partial<HitRateFormModel> => ({
  type: 'insights_share',
  title: t`Hit rate`,
  visual: 'Value',
  goal: {
    min: null,
    max: null,
  },
  yAxisRange: {
    min: undefined,
    max: undefined,
  },
  filters: null,
  shareFilter: null,
  textFilters: null,
  textShareFilter: null,
  resolution: 'Auto',
  value: 'Percent',
  minimumConversationsForListView: null,
  showPreviousPeriodTrendIndicator: true,
})

// wrapping with FormProvider so we can use useSegmentStatesFormSync with useFormContext in HitRateConfigurator
const HitRateConfiguratorWrapper: WidgetConfigurator<HitRateWidgetModel> = (
  props,
) => {
  const formMethods = useForm<HitRateFormModel>({
    defaultValues: merge({}, defaultFormModel(), props.formModel),
  })

  return (
    <FormProvider {...formMethods}>
      <HitRateConfigurator {...props} />
    </FormProvider>
  )
}

const HitRateConfigurator: WidgetConfigurator<HitRateWidgetModel> = ({
  formModel,
  onSubmit,
  children,
}) => {
  const { watch, handleSubmit } = useFormContext<HitRateFormModel>()
  const visualType = watch('visual') as HitRateWidgetModel['visual']
  const valueType = watch('value')
  const goal = watch('goal') ?? null
  const title = watch('title')
  const description = watch('description')
  const previousPeriodTrendInterval = watch('previousPeriodTrendInterval')

  const { color, label } = segmentConfigurations[0]

  const currentUser = useCurrentUser()

  useSegmentStatesFormSync() // syncs segment states -> form state

  const filterDefinitions = useFilterDefinitions(currentUser)

  const widgetPreviewData = {
    title,
    description,
    goal,
    value: valueType,
    visual: visualType,
    showTrendIndicator: !(previousPeriodTrendInterval === 'None'),
    benchmark: previousPeriodTrendInterval,
  }

  const isListType = /List$/.test(visualType)

  const unit = valueType === 'Percent' ? '%' : ''

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConfiguratorLayout>
        <ConfiguratorColumn>
          <TypeField />
          <TitleField />
          <DescriptionField />
          <Box>
            <Heading mb={2}>
              <Trans>Select conversations</Trans>
            </Heading>
            <FormLabel mb={0}>
              1. <Trans>Data basis</Trans>
            </FormLabel>
            <Caption color="textMuted">
              <Trans>
                Select the <Underlined>total amount</Underlined> of
                conversations you want to analyze on. <br />
                If nothing is selected, all conversations are used in the
                analysis.
              </Trans>
            </Caption>
            <Stack spacing={2}>
              <FilterMenuPlacementProvider value="top-start">
                <SingleSegmentContainer
                  filterDefinitions={filterDefinitions}
                  resetSavedFilterOnValuesChange={true}
                  showExportButton={false}
                  showNotificationButton={false}
                  showSaveButton={false}
                  disabledChannels={
                    formModel?.type === 'insights_share' ? [] : ['email']
                  }
                />
                <FormLabel mb={0}>
                  2. <Trans>Filtering</Trans>
                </FormLabel>
                <Caption color="textMuted">
                  <Trans>
                    Select the <Underlined>filtered subset</Underlined> of
                    conversations you want to see hit rates for.
                  </Trans>
                </Caption>
                <SegmentsSubFilterContainer
                  filterDefinitions={filterDefinitions}
                  resetSavedFilterOnValuesChange={true}
                  segmentProfiles={[{ color: color, label: label }]}
                  disabledChannels={
                    formModel?.type === 'insights_share' ? [] : ['email']
                  }
                />
              </FilterMenuPlacementProvider>
            </Stack>
          </Box>
        </ConfiguratorColumn>
        <ConfiguratorColumn>
          <VisualField<HitRateWidgetModel['visual']>
            options={[
              'Value',
              'Gauge',
              { Line: 'Graph' },
              { List: ['List', 'TeamList'] },
            ]}
          />
          <RadioField
            name="value"
            defaultValue="Percent"
            label={<Trans>Format</Trans>}
            description={
              <Trans>Specify how you want the hit rate calculated</Trans>
            }
            isRequired
            options={createValueTypeOptions(visualType)}
            orientation="vertical"
          />
          <Collapse in={visualType === 'Graph'}>
            <Box>
              <ResolutionField isRequired />
            </Box>
            <Box mt={4}>
              <YAxisRangeField unit={unit} />
            </Box>
          </Collapse>
          <Collapse in={!isListType}>
            <GoalField unit={unit} />
          </Collapse>
          <Collapse in={isListType}>
            <Box>
              <ListTypeField />
            </Box>
            <Box mt={4}>
              <MinimumConversationsField />
            </Box>
          </Collapse>
          {(visualType === 'Value' || visualType === 'Gauge') && (
            <BenchmarkField />
          )}
        </ConfiguratorColumn>
        <WidgetPreviewColumn>
          <ConfigurationPreviewWidget previewWidget={widgetPreviewData} />
        </WidgetPreviewColumn>
      </ConfiguratorLayout>
      {children}
    </form>
  )
}

type ListType = 'List' | 'TeamList'
type ListBreakdownType = 'employees' | 'teams'
const visualToValueMap: { [key in ListType]: ListBreakdownType } = {
  List: 'employees',
  TeamList: 'teams',
}

const ListTypeField: React.FC = () => {
  const name = 'visual'
  const { formState, watch, setValue } = useFormContext<HitRateFormModel>()
  const isInvalid = formState.errors[name] != null
  const id = 'widget-list-visual-type'
  const visualValue = watch(name)

  const visualToValue = (
    visualValue: HitRateFormModel['visual'],
  ): ListBreakdownType => {
    if (visualValue === undefined) return 'employees'
    return visualToValueMap[visualValue as ListType] ?? 'employees'
  }

  const valueToVisual = (value: ListBreakdownType | undefined): ListType => {
    if (value === undefined) return 'List'
    return invert(visualToValueMap)[value] as ListType
  }

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={id}>
        <Text>
          <Trans>List type</Trans>
        </Text>
        <Text color="textMuted" fontSize="sm" fontWeight="normal">
          <Trans>
            Choose whether the list should be divided into employees or teams.
          </Trans>
        </Text>
      </FormLabel>
      <RadioOptions
        value={visualToValue(visualValue)}
        onChange={(nextValue: string) => {
          const visual = valueToVisual(nextValue as ListBreakdownType)
          setValue(name, visual)
        }}
        options={[
          { value: 'employees', label: t`Employees` },
          { value: 'teams', label: t`Teams` },
        ]}
      />
    </FormControl>
  )
}

const MinimumConversationsField: React.FC = () => {
  const name = 'minimumConversationsForListView'
  const { formState } = useFormContext<HitRateFormModel>()
  const isInvalid = formState.errors[name] != null
  const id = `widget-${name}`

  const format = (val: number | string | undefined | null): string => {
    if (!Number.isInteger(val)) {
      return ''
    }
    return `${val}`
  }

  return (
    <FormControl isInvalid={isInvalid}>
      <FormLabel htmlFor={id}>
        <Text>
          <Trans>Minimum number of conversations</Trans>
        </Text>
        <Text color="textMuted" fontSize="sm" fontWeight="normal">
          <Trans>
            Specify the minimum number of conversations a person or team must
            have to be displayed in the list.
          </Trans>
        </Text>
      </FormLabel>
      <Controller<HitRateFormModel, 'minimumConversationsForListView'>
        name={name}
        render={({ field }) => (
          <Box>
            <Text fontSize="sm">
              <Trans>Min. number of conversations</Trans>
            </Text>
            <NumberInput
              {...field}
              step={1}
              min={0}
              inputMode="numeric"
              onChange={(_valueString, valueAsNumber) => {
                field.onChange?.(
                  Number.isInteger(valueAsNumber) ? valueAsNumber : undefined,
                )
              }}
              value={format(field.value)}
              allowMouseWheel
              w={28}
              size="sm"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Button
              variant="link"
              size="sm"
              onClick={() => field.onChange(null)}
              tabIndex={-1}
            >
              <Trans>Reset</Trans>
            </Button>
          </Box>
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}

export default HitRateConfiguratorWrapper
