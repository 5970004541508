import { Select } from '@capturi/ui-select'
import {
  FormControl,
  FormLabel,
  Grid,
  HStack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import merge from 'lodash/merge'
import { useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  ConfiguratorColumn,
  TitleField,
  TypeField,
  WidgetPreviewColumn,
} from '../configurator'
import { FormModel } from '../configurator/formUtils'
import { WidgetConfigurator } from '../registry'
import MarkdownRenderer from './MarkdownRenderer'
import MarkdownTooltip from './MarkdownTooltip'
import { HeaderWidgetModel } from './types'

type HeadertWidgetFormModel = FormModel<Partial<HeaderWidgetModel>>

type Horizontal = {
  value: HeaderWidgetModel['horizontalAlignment']
  label: string
}

type Vertical = {
  value: HeaderWidgetModel['verticalAlignment']
  label: string
}

const HeaderWidgetConfigurator: WidgetConfigurator<HeaderWidgetModel> = ({
  formModel,
  onSubmit,
  children,
}) => {
  const defaultFormModel: Partial<HeadertWidgetFormModel> = {
    type: 'header',
    title: t`Acknowledge satisfaction`,
    description: t`Amount conversations`,
  }

  const formMethods = useForm<HeadertWidgetFormModel>({
    defaultValues: merge({}, defaultFormModel, formModel),
  })

  const optionsHorizontal = useMemo<Horizontal[]>(
    () => [
      { value: 'Left', label: t`Left` },
      { value: 'Center', label: t`Center` },
      { value: 'Right', label: t`Right` },
    ],
    [],
  )
  const [horizontalAlignment, setHorizontalAlignment] = useState<Horizontal>(
    optionsHorizontal.find(
      ({ value }) => value === formModel?.horizontalAlignment,
    ) || optionsHorizontal[0],
  )

  const handleHorizontalChange = (value: Horizontal | null): void => {
    if (value) {
      setHorizontalAlignment(value)
    }
  }

  const optionsVertical = useMemo<Vertical[]>(
    () => [
      { value: 'Top', label: t`Top` },
      { value: 'Center', label: t`Center` },
      { value: 'Bottom', label: t`Bottom` },
    ],
    [],
  )

  const [verticalAlignment, setVerticalAlignment] = useState<Vertical>(
    optionsVertical.find(
      ({ value }) => value === formModel?.verticalAlignment,
    ) || optionsVertical[0],
  )
  const handleVerticalChange = (value: Vertical | null): void => {
    if (value) {
      setVerticalAlignment(value)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={formMethods.handleSubmit((e) => {
          onSubmit({
            ...e,
            horizontalAlignment: horizontalAlignment.value,
            verticalAlignment: verticalAlignment.value,
          })
        })}
      >
        <Grid templateColumns={{ sm: '1fr', md: '50% 50%' }}>
          <ConfiguratorColumn>
            <TypeField />
            <TitleField />
            <FormControl>
              <FormLabel htmlFor="widget-description">
                <Trans>Description</Trans>
              </FormLabel>
              <Textarea
                height="300px"
                id="widget-description"
                {...formMethods.register('description')}
              />
              <HStack gap="1">
                <Text>
                  <Trans>
                    You can use markdown in the title and description. See the
                    options{' '}
                  </Trans>
                </Text>
                <MarkdownTooltip>
                  <Text as="span" textDecoration="underline" cursor="pointer">
                    <Trans>here</Trans>
                  </Text>
                </MarkdownTooltip>
              </HStack>
            </FormControl>

            <FormControl>
              <FormLabel>
                <Trans>Horizontal alignment</Trans>
              </FormLabel>
              <Select
                isSearchable={false}
                value={horizontalAlignment}
                options={optionsHorizontal}
                onChange={handleHorizontalChange}
              />
            </FormControl>

            <FormControl>
              <FormLabel>
                <Trans>Vertical alignment</Trans>
              </FormLabel>
              <Select
                isSearchable={false}
                value={verticalAlignment}
                options={optionsVertical}
                onChange={handleVerticalChange}
              />
            </FormControl>
          </ConfiguratorColumn>

          <WidgetPreviewColumn alwaysShow>
            <MarkdownRenderer
              horizontalAlignment={horizontalAlignment.value}
              verticalAlignment={verticalAlignment.value}
              markdown={formMethods.watch('description') || ''}
              title={formMethods.watch('title') || ''}
            />
          </WidgetPreviewColumn>
        </Grid>
        {children}
      </form>
    </FormProvider>
  )
}

export default HeaderWidgetConfigurator
