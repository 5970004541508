import { WidgetComponent } from '../registry'
import { HitRateWidgetModel } from './types'
import { HitRateTeamBreakdown } from './variants/HitRateListVisual/HitRateTeamBreakdown'
import { HitRateUserBreakdown } from './variants/HitRateListVisual/HitRateUserBreakdown'
import { HitRateSingleValue } from './variants/HitRateSingleValue'
import { HitRateTimeSeries } from './variants/HitRateTimeSeries'

const HitRate: WidgetComponent<HitRateWidgetModel> = (widget) => {
  switch (widget.visual) {
    case 'Graph': {
      return <HitRateTimeSeries widget={widget} />
    }
    case 'List': {
      return <HitRateUserBreakdown widget={widget} />
    }
    case 'TeamList': {
      return <HitRateTeamBreakdown widget={widget} />
    }
    default: {
      return <HitRateSingleValue widget={widget} />
    }
  }
}

export default HitRate
