import analytics from '@capturi/analytics'
import { useIsHovering } from '@capturi/react-utils'
import { generateImageUrl } from '@capturi/request'
import { useUsers } from '@capturi/stores'
import {
  Avatar,
  Box,
  Flex,
  Link,
  Text,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { MdLaunch } from 'react-icons/md'
import { ResponsiveContainer } from 'recharts'

import { useDashboardPublicContext } from '../../../../contexts/DashboardPublicContext'
import useWidgetData from '../../../../hooks/useWidgetData'
import useWidgetFilterLink from '../../../../hooks/useWidgetFilterLink'
import { WidgetModel } from '../../../../types'
import * as Widget from '../../../components/Widget'
import {
  Cell,
  CellIcon,
  FlexibleCell,
  ListContainer,
  Table,
  getAvatarSize,
  getFontSize,
  labelCss,
  subLabelCss,
  useListContainer,
  valueCss,
} from '../../../components/visuals/ListDisplay'
import {
  ConversationDurationUserBreakdownData,
  ConversationDurationWidgetModel,
  ConversationUserModel,
  UserShareStatistics,
} from '../../types'
import { compareRows, renderSubText, renderValue } from './shared'

type ConversationDurationUserBreakdownProps = {
  widget: ConversationDurationWidgetModel
}

type UserBreakdownProps = {
  data: ConversationDurationUserBreakdownData
  widget: WidgetModel
}

type UserBreakdownRowProps = {
  value: string
  subText: string
  avatarSize: string
  row: UserShareStatistics
  widget: WidgetModel
  isPublicDashboard: boolean
  index: number
  isTooltipDisabled: boolean
  showAvatar: boolean
}

const MIN_WIDTH_TO_SHOW_AVATARS = 220

const UserBreakdownRow: React.FC<UserBreakdownRowProps> = ({
  value,
  subText,
  avatarSize,
  row,
  widget,
  isPublicDashboard,
  index,
  isTooltipDisabled,
  showAvatar,
}) => {
  const hitRowRef = useRef<HTMLTableRowElement>(null)
  const isHovering = useIsHovering(hitRowRef)
  const { ref: scrollRef, width } = useListContainer()
  const imageSrc = generateImageUrl(row.userProfileImage?.key, { size: 64 })

  const hitLink = useWidgetFilterLink({
    widget,
    additionalUserFilter: [row.userUid],
  })

  const infoText = `${row.userName}: ${value} (${subText})`

  const isLinkHidden = index === 0 && widget.title === ''

  return (
    <chakra.tr ref={hitRowRef}>
      {showAvatar && (
        <Cell>
          <Avatar
            name={row.userName}
            aria-label={row.userName}
            src={imageSrc}
            size={avatarSize}
          />
        </Cell>
      )}
      <FlexibleCell>
        <Tooltip label={infoText} isDisabled={isTooltipDisabled}>
          <Box>
            {hitLink && !isPublicDashboard ? (
              <Flex
                alignItems="baseline"
                width="100%"
                flexFlow={width < 300 ? 'wrap' : 'nowrap'}
                ref={scrollRef}
              >
                <Link
                  flex="1 100%"
                  width="100%"
                  overflow="hidden"
                  href={hitLink}
                  isExternal
                  onClick={() => {
                    analytics.event('dashboard_widget_link_list_username', {
                      widgetType: widget.type,
                      visual: widget.visual,
                    })
                  }}
                >
                  <Text as="div" css={labelCss}>
                    <Text w="100%" noOfLines={1} wordBreak="break-all">
                      {row.userName}
                    </Text>
                  </Text>
                </Link>
                <Text as="div" css={subLabelCss} minW="fit-content">
                  <Text color="textMuted">{subText}</Text>
                </Text>
              </Flex>
            ) : (
              <Flex
                alignItems="baseline"
                width="100%"
                flexFlow={width < 300 ? 'wrap' : 'nowrap'}
                ref={scrollRef}
              >
                <Box flex="1 100%" width="100%" overflow="hidden">
                  <Text as="div" css={labelCss}>
                    <Text w="100%" noOfLines={1} wordBreak="break-all">
                      {row.userName}
                    </Text>
                  </Text>
                </Box>
                <Text as="div" css={subLabelCss} minW="fit-content">
                  <Text color="textMuted">{subText}</Text>
                </Text>
              </Flex>
            )}
          </Box>
        </Tooltip>
      </FlexibleCell>
      <Cell isNumeric textAlign="end" pos="relative">
        {isHovering && hitLink && !isPublicDashboard ? (
          <Link
            href={hitLink}
            isExternal
            css={valueCss}
            onClick={() => {
              analytics.event('dashboard_widget_link_list', {
                widgetType: widget.type,
                visual: widget.visual,
              })
            }}
          >
            <CellIcon mr={isLinkHidden ? '8' : undefined}>
              <MdLaunch />
            </CellIcon>
          </Link>
        ) : (
          <Text as="div" css={valueCss}>
            <Text>{value}</Text>
          </Text>
        )}
      </Cell>
    </chakra.tr>
  )
}

function mapWidgetData(
  data: ConversationUserModel | undefined,
): ConversationDurationUserBreakdownData | undefined {
  if (data === undefined) return undefined
  return {
    users: data.users.map((d) => {
      return {
        userUid: d.userUid,
        userName: d.userName,
        userProfileImage: d.userProfileImage,
        userIsDeleted: d.userIsDeleted,
        avgDuration: d.avgDuration,
        conversations: d.conversations,
      }
    }),
  }
}

const UserBreakdown: React.FC<UserBreakdownProps> = ({ data, widget }) => {
  const isPublicDashboard = useDashboardPublicContext()
  /**
   * When viewing dashboard with a public link `userName` and `userProfileImage` will be populated by the API.
   * Block this request in that case (request will fail otherwise because it is not exposed as a public endpoint)
   */

  const { getUserByUid } = useUsers(isPublicDashboard)

  const { ref: scrollRef, width, isScrolling } = useListContainer()
  const showAvatar = width >= MIN_WIDTH_TO_SHOW_AVATARS

  const rows = useMemo(() => {
    const rows = data.users.reduce<UserShareStatistics[]>((acc, x) => {
      /**
       * Only when viewing dashboard with a public link will
       * `userName` and `userProfileImage` be populated
       */
      if (x.userName === undefined) {
        const user = getUserByUid(x.userUid)
        if (user.isDeleted) return acc
        acc.push({
          ...x,
          userName: user.name,
          userProfileImage: user.profileImage,
        })
      } else if (!x.userIsDeleted) {
        acc.push(x)
      }
      return acc
    }, [])
    return rows.sort((a, b) => {
      const diff = compareRows(a, b)
      if (diff !== 0) return diff
      return (a.userName ?? '').localeCompare(b.userName ?? '')
    })
  }, [data, getUserByUid])

  const fontSize = getFontSize(width)
  const avatarSize = getAvatarSize(width)
  return (
    <ListContainer ref={scrollRef}>
      <Table fontSize={`${fontSize}px`}>
        <chakra.tbody>
          {rows.map((x, i) => {
            const value = renderValue(x)
            const subText = renderSubText(x)

            return (
              <UserBreakdownRow
                row={x}
                key={x.userUid}
                index={i}
                value={value}
                subText={subText}
                avatarSize={avatarSize}
                widget={widget}
                isPublicDashboard={isPublicDashboard}
                isTooltipDisabled={isScrolling}
                showAvatar={showAvatar}
              />
            )
          })}
        </chakra.tbody>
      </Table>
    </ListContainer>
  )
}

export const ConversationDurationUserBreakdown: React.FC<
  ConversationDurationUserBreakdownProps
> = ({ widget }) => {
  const { title, description } = widget
  const { data: rawData } = useWidgetData<ConversationUserModel>(
    widget,
    () => ({
      /**
       * `visual` is not used by the API but works here as a part of the SWR cache key.
       * If not present, then after changing the visual type of a widget that widget could
       * initially be served data conforming to the previous visual type from the cache.
       **/
      visual: widget.visual,
    }),
  )

  const data = mapWidgetData(rawData)

  return (
    <Widget.Container>
      <Widget.Title>{title}</Widget.Title>
      <Widget.Description>{description}</Widget.Description>
      <Widget.Content>
        {data && (
          <ResponsiveContainer height="100%" width="100%">
            <UserBreakdown data={data} widget={widget} />
          </ResponsiveContainer>
        )}
      </Widget.Content>
    </Widget.Container>
  )
}
