import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useFormContext } from 'react-hook-form'

import { FormField } from './types'

export const DescriptionField: FormField = ({
  name = 'description',
  isRequired,
}) => {
  const { register } = useFormContext()
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel htmlFor="widget-description">
        <Trans>Description</Trans>
      </FormLabel>
      <Input
        id="widget-description"
        {...register(name, {
          required: isRequired ? t`Please add a description` : false,
        })}
        size="sm"
      />
    </FormControl>
  )
}
