import { TimeResolution } from '@capturi/core'
import { differenceInDays, differenceInHours } from 'date-fns'

export function getResolutionFromTimespan(
  from: Date,
  to: Date,
): TimeResolution {
  if (differenceInHours(to, from) < 48) return 'Hours'
  if (differenceInDays(to, from) < 40) return 'Days'
  if (differenceInDays(to, from) < 90) return 'Weeks'
  return 'Months'
}
