import { Flex, Icon, Text, Tooltip, useToken } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'
import { IconType } from 'react-icons'
import { BiCaretDown, BiCaretUp } from 'react-icons/bi'

import { WidgetTrendType } from '../../../../types'
import {
  ValueDisplayContext,
  ValueDisplayContextType,
} from './ValueDisplayContext'
import { ValueWithUnit } from './ValueWithUnit'
import { defaultFormat, trendLabels } from './utils'

const formatTrendPercentage = (val: number): string =>
  i18n.number(val, defaultFormat)

function getPercentageChange(
  value: number | undefined,
  referenceValue: number | undefined,
): number | undefined {
  if (referenceValue === 0 && value === 0) {
    return 0
  }
  if (
    referenceValue === undefined ||
    value === undefined ||
    referenceValue === 0
  ) {
    return undefined
  }
  return (value - referenceValue) / referenceValue
}

const createTooltipText = (
  value: number | undefined,
  referenceValue: number,
  // biome-ignore lint/style/useDefaultParameterLast: <explanation>
  unit = '',
  percentageChange: number | undefined,
  formatValue: ValueDisplayContextType['formatValue'],
  trendLabel: string | undefined,
): string => {
  if (trendLabel) return t`Shows hit rate from ${trendLabel}`

  if (referenceValue === 0 && value !== 0) {
    return t`The percentage change is not defined since the value of the previous period is ${formatValue(
      referenceValue,
    )}${unit}.`
  }
  if (value === undefined || percentageChange === undefined) {
    return t`The percentage change is not defined.`
  }
  const sign = percentageChange > 0 ? '+' : ''
  const formattedPercentageChange = `${sign}${formatTrendPercentage(
    percentageChange * 100,
  )}%`
  return t`The percentage change between the value of the previous period (${formatValue(
    referenceValue,
  )}${unit}) and the value of the current period (${formatValue(
    value,
  )}${unit}) is ${formattedPercentageChange}`
}

const getTrendIcon = (
  percentageChange: number | undefined,
): IconType | null => {
  if (percentageChange === undefined) return null
  if (percentageChange < 0) return BiCaretDown
  return BiCaretUp
}

export type TrendDisplayProps = {
  referenceValue?: number
  benchmark: WidgetTrendType
}

export const TrendDisplay: React.FC<TrendDisplayProps> = ({
  referenceValue,
  benchmark,
}) => {
  const { value, unit, formatValue } = React.useContext(ValueDisplayContext)
  const trendTextColor = useToken('colors', 'gray.600')

  if (referenceValue === undefined) {
    return null
  }

  const percentageChange = getPercentageChange(value, referenceValue)
  const benchmarkLabel = trendLabels.get(benchmark)
  const isBenchmarkTrend = benchmark === 'Trend'

  const trendFontSizeEM = 0.27
  const TrendIcon = getTrendIcon(percentageChange)

  return (
    <Tooltip
      label={createTooltipText(
        value,
        referenceValue,
        unit,
        percentageChange,
        formatValue,
        benchmarkLabel ? i18n._(benchmarkLabel.title) : undefined,
      )}
    >
      <Flex
        gap="1"
        mt="0.2em"
        color={trendTextColor}
        fontWeight="medium"
        align="end"
        css={css`
          font-size: ${trendFontSizeEM}em;
          font-size: max(12px, ${trendFontSizeEM}em);
        `}
      >
        {benchmarkLabel && (
          <Text fontWeight="normal" fontSize="0.5em">
            {i18n._(benchmarkLabel.short)}
          </Text>
        )}
        <Flex>
          {percentageChange !== 0 && isBenchmarkTrend && TrendIcon != null && (
            <Icon as={TrendIcon} />
          )}
          <ValueWithUnit
            value={
              isBenchmarkTrend
                ? percentageChange === undefined
                  ? '-'
                  : percentageChange * 100
                : referenceValue
            }
            color="gray.800"
            unit="%"
            formatValue={(value) => {
              if (typeof value === 'number') {
                return formatTrendPercentage(Math.abs(value))
              }
              return value
            }}
            letterSpacing={0}
          />
        </Flex>
      </Flex>
    </Tooltip>
  )
}
