import {
  CartesianGrid,
  DateBucket,
  GoalReferenceArea,
  LineDescriptor,
  PrimaryLine,
  TimeSeriesChart,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
  adjustDateForDSTOverlap,
  pctAutoDomain,
  timeSeriesTickFormatters,
} from '@capturi/charts'
import { useFilterPeriodContext } from '@capturi/filters'
import { t } from '@lingui/macro'
import { isWeekend } from 'date-fns'
import React from 'react'
import { Customized, ResponsiveContainer } from 'recharts'

import useOnlyWeekdaysToggle from '../../../hooks/useOnlyWeekdaysToggle'
import useWidgetData from '../../../hooks/useWidgetData'
import CustomTooltip from '../../components/CustomTooltip'
import * as Widget from '../../components/Widget'
import { getEffectiveTimeResolution } from '../../utils/getEffectiveResolution'
import { getResolutionFromTimespan } from '../../utils/timeResolution'
import {
  ConversationsTimeSeriesModel,
  HitRateTimeSeriesData,
  HitRateWidgetModel,
  TextTimeSeriesModel,
} from '../types'

export type HitRateTimeSeriesProps = {
  widget: HitRateWidgetModel
}

function mapWidgetData(
  data: TextTimeSeriesModel | ConversationsTimeSeriesModel | undefined,
): HitRateTimeSeriesData | undefined {
  if (data === undefined) return undefined
  return {
    series: data.series.map((d) => {
      if ('conversations' in d) {
        return {
          dateTime: d.dateTime,
          entities: d.conversations,
          share: d.conversationsShare,
          sharePercent: d.conversationsSharePercent,
        }
      }
      return {
        dateTime: d.dateTime,
        entities: d.cases,
        share: d.casesShare,
        sharePercent: d.casesSharePercent,
      }
    }),
  }
}

export const HitRateTimeSeries: React.FC<HitRateTimeSeriesProps> = ({
  widget,
}) => {
  const { title, description, goal, yAxisRange } = widget
  const { data: rawData } = useWidgetData<
    TextTimeSeriesModel | ConversationsTimeSeriesModel
  >(widget, ({ period }) => {
    return {
      resolution: getResolutionFromTimespan(period.from, period.to),
      /**
       * `visual` is not used by the API but works here as a part of the SWR cache key.
       * If not present, then after changing the visual type of a widget that widget could
       * initially be served data conforming to the previous visual type from the cache.
       **/
      visual: widget.visual,
    }
  })

  const data = mapWidgetData(rawData)

  const [plotOnlyWeekdays] = useOnlyWeekdaysToggle()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1
  const resolution = getEffectiveTimeResolution(widget.resolution, period)

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = { date }
      }
      return buckets[bucketKey]
    }

    data?.series.forEach((x) => {
      const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)

      // Omit weekend data?
      if (
        plotOnlyWeekdays &&
        isWeekend(date) &&
        (resolution === 'Hours' || resolution === 'Days')
      ) {
        return
      }

      const bucket = getOrCreateBucket(date)
      bucket.value = x.share
      bucket.percent =
        x.sharePercent == null ? null : Math.round(x.sharePercent) / 100
    })

    return {
      data: Object.values(buckets).sort(
        (a, b) => a.date.getTime() - b.date.getTime(),
      ),
    }
  }, [data, tzOffset, plotOnlyWeekdays, resolution])

  const config = getChartConfig(widget)
  const hasUserDefinedDomain =
    yAxisRange?.min != null || yAxisRange?.max != null

  const line: LineDescriptor = {
    color: 'segments.primary',
    label: title ?? t`hit rate`,
    getValue: config.selectData,
  }

  return (
    <Widget.Container>
      <Widget.Title>{title}</Widget.Title>
      <Widget.Description>{description}</Widget.Description>
      <Widget.Content fontSize="0.75em">
        <ResponsiveContainer height="100%" minWidth={100}>
          <TimeSeriesChart data={results.data} resolution={resolution}>
            <CartesianGrid />
            <XAxis dataKey="date" />
            <YAxis
              tickFormatter={config.tickFormatter}
              domain={config.domain}
              allowDataOverflow={hasUserDefinedDomain}
            />
            <Tooltip
              content={
                <CustomTooltip
                  resolution={resolution}
                  customValueFormatter={(value) =>
                    config.style === 'number'
                      ? timeSeriesTickFormatters.yAxis.value(value)
                      : timeSeriesTickFormatters.yAxis.percent(value)
                  }
                />
              }
            />
            <Customized
              component={<GoalReferenceArea goal={goal} style={config.style} />}
            />
            <PrimaryLine line={line} />
          </TimeSeriesChart>
        </ResponsiveContainer>
      </Widget.Content>
      <Widget.Footer>
        <Widget.Goal {...goal} unit={config.unit} />
      </Widget.Footer>
    </Widget.Container>
  )
}

function getChartConfig(widget: HitRateWidgetModel): {
  domain: YAxisProps['domain']
  tickFormatter?: (value: number) => string
  style: 'percent' | 'number'
  unit?: string
  selectData: (data: DateBucket) => number
} {
  const { value: widgetValueType, yAxisRange } = widget

  if (widgetValueType === 'Number') {
    return {
      domain: [yAxisRange?.min ?? 0, yAxisRange?.max ?? 'auto'],
      tickFormatter: timeSeriesTickFormatters.yAxis.value,
      style: 'number',
      unit: '',
      selectData: (data: DateBucket) => data.value,
    }
  }
  return {
    domain: [
      yAxisRange?.min != null ? yAxisRange.min / 100 : pctAutoDomain[0],
      yAxisRange?.max != null ? yAxisRange.max / 100 : pctAutoDomain[1],
    ],
    tickFormatter: timeSeriesTickFormatters.yAxis.percent,
    style: 'percent',
    unit: '%',
    selectData: (data: DateBucket) => data.percent,
  }
}
