import { Period, adjustDateForDSTOverlap } from '@capturi/filters'
import { RequestOptions } from '@capturi/request'

import { WidgetModel } from '../types'
import { baseQuery, controller } from './utils'

export type WidgetCreateModel = Omit<WidgetModel, 'uid'>
export type WidgetUpdateModel = Partial<WidgetModel>

export default {
  create: <T extends WidgetCreateModel>(
    dashboardUid: string,
    model: T,
  ): RequestOptions => ({
    url: `${controller}/${dashboardUid}/widgets`,
    query: baseQuery,
    method: 'post',
    json: model,
  }),
  delete: (dashboardUid: string, uid: string): RequestOptions => ({
    url: `${controller}/${dashboardUid}/widgets/${uid}`,
    query: baseQuery,
    method: 'delete',
  }),
  get: (dashboardUid: string, uid: string): RequestOptions => ({
    url: `${controller}/${dashboardUid}/widgets/${uid}`,
    query: baseQuery,
  }),
  update: <T extends WidgetUpdateModel>(
    dashboardUid: string,
    uid: string,
    model: T,
  ): RequestOptions => ({
    url: `${controller}/${dashboardUid}/widgets/${uid}`,
    query: baseQuery,
    method: 'patch',
    json: model,
  }),
  replace: <T extends WidgetCreateModel>(
    dashboardUid: string,
    uid: string,
    model: T,
  ): RequestOptions => ({
    url: `${controller}/${dashboardUid}/widgets/${uid}`,
    query: baseQuery,
    method: 'put',
    json: model,
  }),
  getData: (
    dashboardUid: string,
    uid: string,
    period: Period,
    sharedContextType?: string,
    sharedContextValue?: string[],
  ): RequestOptions => {
    const fromTZOffset = period.from.getTimezoneOffset()
    return {
      url: `${controller}/${dashboardUid}/widgets/${uid}/data`,
      query: {
        ...baseQuery,
        fromInclusive: period.from.toISOString().replace(/Z$/, ''),
        toInclusive: adjustDateForDSTOverlap(period.to, fromTZOffset)
          .toISOString()
          .replace(/Z$/, ''),
        offset: period.from.getTimezoneOffset() * -1,
        sharedContextType,
        sharedContextValue,
      },
    }
  },
}
