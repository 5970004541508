import { useCurrentUser } from '@capturi/core'
import { ErrorBoundary } from '@capturi/react-utils'
import { Spinner } from '@capturi/ui-components'
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react'
import React from 'react'

import {
  useOriginalSharedContextType,
  useSelectedSharedContextSetter,
} from '../../contexts/ContextualDashboardContext'
import { DashboardAdminEvent, logEvent } from '../../events'
import { SharedContextType } from '../../types'
import { SharedContextButton } from './SharedContextButton'
import { SharedContextTypeSelect } from './views/SharedContextTypeSelect'
import { TeamSelect } from './views/TeamSelect'
import { UserSelect } from './views/UserSelect'

const PopoverDialog: React.FC<
  Omit<PopoverProps, 'children'> & { children?: React.ReactNode }
> = ({ children }) => {
  return (
    <Popover isLazy variant="responsive">
      {({ onClose }) => (
        <>
          <PopoverTrigger>{children}</PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverBody p={0}>
                <ErrorBoundary>
                  <React.Suspense
                    fallback={<Spinner display="block" m="1rem auto" />}
                  >
                    <ViewManager onClose={onClose} />
                  </React.Suspense>
                </ErrorBoundary>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  )
}

const SharedContextDialog: React.FC = () => {
  const currentUser = useCurrentUser()

  if (currentUser.isAdminOrTeamLead) {
    return (
      <PopoverDialog>
        <SharedContextButton />
      </PopoverDialog>
    )
  }
  return <SharedContextButton />
}

export default SharedContextDialog

const ViewManager: React.FC<{
  onClose: () => void
}> = ({ onClose }) => {
  const setSelectedSharedContext = useSelectedSharedContextSetter()
  const originalSharedContextType = useOriginalSharedContextType()

  const [currentView, setView] = React.useState<
    'select-type' | 'select-team' | 'select-user'
  >('select-type')

  const handleSelectType = (type: SharedContextType): void => {
    if (type === 'Organization') {
      if (originalSharedContextType === 'Organization') {
        // If Dashboard has been configured with sharedContextType === Organization, then we allow
        // team leads to select sharedContextType === Organization. However, we do this by _not sending_
        // sharedContextType in network request, thus allowing the server to use the default value.
        // This is a quirk of API validation, which does not allow team leads to override sharedContextType
        // to Organization, but allows to use default Dashboard.sharedContextType value === Organization.
        setSelectedSharedContext(undefined)
      } else {
        setSelectedSharedContext({ type })
      }

      logEvent(DashboardAdminEvent.OverrideSharedContext, { type })
      onClose()
    }
    if (type === 'Team') {
      setView('select-team')
    }
    if (type === 'User') {
      setView('select-user')
    }
  }

  const handleSelectTeams = (uids: string[]): void => {
    if (uids.length === 0) {
      handleSelectType('Organization')
      return
    }

    setSelectedSharedContext({ type: 'Team', value: uids })
    logEvent(DashboardAdminEvent.OverrideSharedContext, { type: 'Team' })
    onClose()
  }

  const handleSelectUsers = (uids: string[]): void => {
    if (uids.length === 0) {
      handleSelectType('Organization')
      return
    }

    setSelectedSharedContext({ type: 'User', value: uids })
    logEvent(DashboardAdminEvent.OverrideSharedContext, { type: 'User' })
    onClose()
  }

  return (
    <>
      {currentView === 'select-type' && (
        <SharedContextTypeSelect onSelectType={handleSelectType} />
      )}
      {currentView === 'select-team' && (
        <TeamSelect onSelectTeams={handleSelectTeams} />
      )}
      {currentView === 'select-user' && (
        <UserSelect onSelectUsers={handleSelectUsers} />
      )}
    </>
  )
}
