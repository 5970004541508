import { Period } from '@capturi/filters'
import {
  endOfDay,
  isEqual,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  subDays,
  subSeconds,
} from 'date-fns'

import { WidgetTrendType } from '../../types'

export const getTrendPeriod = (
  key: WidgetTrendType,
  currentPeriod: Period,
): Period => {
  const today = new Date()

  switch (key) {
    case 'MonthToDate':
      return new Period(startOfMonth(today), endOfDay(today))
    case 'QuarterToDate':
      return new Period(startOfQuarter(today), endOfDay(today))
    case 'YearToDate':
      return new Period(startOfYear(today), endOfDay(today))
  }

  /* Trend */
  const fullSingleDay =
    isSameDay(currentPeriod.from, currentPeriod.to) &&
    isEqual(startOfDay(currentPeriod.from), currentPeriod.from) &&
    isEqual(endOfDay(currentPeriod.to), currentPeriod.to)
  if (fullSingleDay) {
    // Special case where we want to compare with exactly the same period on previous day
    // For selected 07.11.2023 00:00-23:59 period period.
    // We compare it to 06.11.2023 00:00-23:59
    return new Period(
      subDays(currentPeriod.from, 1),
      subDays(currentPeriod.to, 1),
    )
  }
  const differenceInDays = currentPeriod.days()
  if (differenceInDays >= 1) {
    // More than a day (e.g. 1+ day)
    // For selected 06.11.2023-07.11.2023 period.
    // We compare it to 05.11.2023-06.11.2023 (end of day).
    return new Period(
      subDays(currentPeriod.from, differenceInDays),
      subDays(currentPeriod.to, differenceInDays),
    )
  }
  // Less than a day (e.g. 1 hour)
  // For selected 07.11.2023 13:00-16:00 period.
  // We compare it to 07.11.2023 10:00-13:00.
  const differenceInSeconds = currentPeriod.seconds()
  return new Period(
    subSeconds(currentPeriod.from, differenceInSeconds),
    subSeconds(currentPeriod.to, differenceInSeconds),
  )
}
