import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'

import { HitRateValueType, ShareStatistics } from '../../types'

const formatValue = (x: number): string => i18n.number(x)
const formatHitRate = (x: number): string =>
  i18n.number(x / 100, {
    style: 'percent',
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  })

export const compareRows = (
  a: ShareStatistics,
  b: ShareStatistics,
  valueType: HitRateValueType,
): number => {
  if (valueType === 'Number') {
    return b.share - a.share
  }
  return b.sharePercent - a.sharePercent
}

export function renderValue(
  x: ShareStatistics,
  valueType: HitRateValueType,
): string {
  if (valueType === 'Number') {
    return formatValue(x.share)
  }
  return formatHitRate(x.sharePercent)
}

export function renderSubText(
  x: ShareStatistics,
  valueType: HitRateValueType,
): string {
  if (valueType === 'Number') {
    const hitRate = formatHitRate(x.sharePercent)
    return t`${hitRate} of ${x.entities}`
  }
  return `${x.share}/${x.entities}`
}
