import {
  FormControl,
  FormLabel,
  Switch,
  SwitchProps,
  Tooltip,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { Store, useStore } from '@tanstack/react-store'
import React from 'react'

const onlyWeekdaysStore = new Store<boolean>(true)

function useOnlyWeekdaysToggle(): [boolean, React.FC<SwitchProps>] {
  const onlyWeekdays = useStore(onlyWeekdaysStore)

  const ToggleComponent: React.FC<SwitchProps> = ({ onChange, ...props }) => (
    <Tooltip
      label={t`Hides the graphical display of Saturdays and Sundays in line graphs. Activation does not influence data, but only changes the graphical display so that Saturdays and Sundays do not appear in line graphs.`}
    >
      <FormControl display="flex" alignItems="center">
        <FormLabel htmlFor="plot-only-weekdays-toggle" mb={0}>
          <Trans>Hide weekends</Trans>
        </FormLabel>
        <Switch
          colorScheme="primary"
          isChecked={onlyWeekdays}
          onChange={(e) => {
            try {
              onChange?.(e)
            } finally {
              onlyWeekdaysStore.setState(() => e.target.checked)
            }
          }}
          id="plot-only-weekdays-toggle"
          {...props}
        />
      </FormControl>
    </Tooltip>
  )

  return [onlyWeekdays, ToggleComponent]
}

export default useOnlyWeekdaysToggle
