import React from 'react'

import { WidgetGoal } from '../../../../types'
import { defaultFormatFn } from './utils'

export type ValueDisplayContextType = {
  width: number
  height: number
  value?: number
  unit?: string
  isPreview: boolean
  formatValue: (val: number) => string
  goal?: WidgetGoal
}

export const ValueDisplayContext = React.createContext<ValueDisplayContextType>(
  {
    width: 0,
    height: 0,
    isPreview: false,
    formatValue: defaultFormatFn,
  },
)
