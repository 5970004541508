type Point = {
  x: number
  y: number
}

type Rect = Point & {
  width: number
  height: number
}

// Check if rects overlap
function intersects(rect1: Rect, rect2: Rect): boolean {
  if (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  ) {
    return true
  }
  return false
}

/**
 * Scan dashboard grid from top-left and find and return the first
 * position where the widget will fit.
 *
 * @param cols dashboard columns
 * @param rows dashboard rows
 * @param width widget width
 * @param height widget height
 * @param layouts layout of existing widgets
 */
function findBestPosition(
  cols: number,
  rows: number,
  width: number,
  height: number,
  items: Rect[],
): Point | undefined {
  let x = 0
  let y = 0
  const xMax = cols - width
  const yMax = rows - height
  while (x <= xMax && y <= yMax) {
    // Rect position to test
    const rect: Rect = { x, y, width, height }

    // Test against all other items
    const collisionRects = items.filter((item) => {
      return intersects(rect, {
        x: item.x,
        y: item.y,
        width: item.width,
        height: item.height,
      })
    })

    if (collisionRects.length === 0) {
      // Position found
      return {
        x,
        y,
      }
    }

    /**
     * Optimisation:
     * Skip over x-positions that are within the collisions rects
     */
    const nextX = collisionRects.reduce((x, rect) => {
      const x2 = rect.x + rect.width
      if (x2 > x) return x2
      return x
    }, x + 1)

    x = nextX
    if (x > xMax) {
      // Reset to next row
      x = 0
      y++
    }
  }
  // No fitting position found
  return undefined
}

export default findBestPosition
