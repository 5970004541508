import { useAPI } from '@capturi/api-utils'
import { Period, useFilterPeriodContext } from '@capturi/filters'
import { useErrorHandler } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { StringifiableRecord } from 'query-string'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { SWRConfiguration, SWRResponse } from 'swr'

import dashboardsAPI from '../api'
import { useSelectedSharedContext } from '../contexts/ContextualDashboardContext'
import { useDashboardContext } from '../contexts/DashboardContext'
import { WidgetModel } from '../types'

function getRefreshInterval(period: Period): number {
  const days = period.days()
  if (days >= 28) {
    return 5 * 60_000
  }
  if (days >= 14) {
    return 2 * 60_000
  }
  if (days >= 7) {
    return 60_000
  }
  if (days > 2) {
    return 40_000
  }
  return 20_000
}

/**
 * Determine if widget configuration has changed
 */
function areConfigsEqual(w1?: WidgetModel, w2?: WidgetModel): boolean {
  if (w1 === undefined || w2 === undefined || w1 === w2) {
    return false
  }
  const excludedProps = ['title', 'description', 'position', 'size']
  // Check for configuration change on properties that might affect data
  return !isEqual(omit(w1, ...excludedProps), omit(w2, ...excludedProps))
}

/**
 * Hook which will call `callback` when widget configuration changes
 */
export function useOnWidgetConfigChange(
  widget: WidgetModel,
  callback: () => void,
): void {
  const previousWidget = usePrevious(widget)
  useEffect(() => {
    if (areConfigsEqual(widget, previousWidget)) {
      callback()
    }
  }, [widget, previousWidget, callback])
}

type AdditionalParamsProps = {
  period: Period
}

function useWidgetData<Data, Error = ResponseError>(
  widget: WidgetModel,
  getAdditionalParams?: (
    props: AdditionalParamsProps,
  ) => StringifiableRecord | undefined,
  periodParam?: Period,
  swrConfig: SWRConfiguration = {},
): SWRResponse<Data, Error> {
  const dashboardUid = useDashboardContext()
  const { period: contextPeriod } = useFilterPeriodContext()
  const sharedContext = useSelectedSharedContext()
  const widgetsAPI = dashboardsAPI.widgets
  const period = periodParam ?? contextPeriod

  const handleError = useErrorHandler()

  const resp = useAPI<Data, Error>(
    widgetsAPI.getData(
      dashboardUid,
      widget.uid,
      period,
      sharedContext?.type,
      sharedContext?.value,
    ),
    () => getAdditionalParams?.({ period }) ?? {},
    {
      suspense: true,
      revalidateOnFocus: false,
      refreshWhenHidden: true,
      refreshInterval: getRefreshInterval(period),
      onError: (err) => {
        /**
         * Error boundaries can only handle errors thrown during React's lifecycles,
         * so here we use react-error-boundary's helper function
         * */
        handleError(err)
      },
      ...swrConfig,
    },
  )
  useOnWidgetConfigChange(widget, resp.mutate)
  return resp
}

export default useWidgetData
