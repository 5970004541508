import { Box, Flex, Icon, Text } from '@chakra-ui/react'
import React from 'react'
import { MdFlag } from 'react-icons/md'
import { WidgetModel, WidgetTrendType } from '../../../types'
import { HitRateWidgetModel } from '../../Hitrate/types'
import { ScoreWidgetModel } from '../../Score/types'
import { Description, Footer, Title } from '../Widget'
import { VisualGraph } from './VisualGraph'

type PreviewFormDataWidget = {
  title?: WidgetModel['title']
  type?: WidgetModel['type']
  goal?: HitRateWidgetModel['goal']
  value?: HitRateWidgetModel['value']
  visual: WidgetModel['visual']
  description?: WidgetModel['description']
  showTrendIndicator?: HitRateWidgetModel['showPreviousPeriodTrendIndicator']
  scoreMaxPossibleScore?: ScoreWidgetModel['scoreMaxPossibleScore']
  trackerUidsCount?: number
  benchmark?: WidgetTrendType
}

type ConfigurationPreviewProps = {
  previewWidget: PreviewFormDataWidget
}

export const skeletonXAxis = (props: {
  x: number
  y: number
}): React.ReactElement => {
  const { x, y } = props

  return (
    <g transform={`translate(${x - 20},${y})`}>
      <rect
        width="40"
        height="12"
        rx="8"
        ry="8"
        style={{
          fill: '#e0e0e0',
        }}
      />
    </g>
  )
}

export const skeletonYAxis = (
  props: { x: number; y: number },
  isPercentageValue: boolean,
): React.ReactElement => {
  const { x, y } = props

  return (
    <g>
      <rect
        width="15"
        height="12"
        rx="6"
        ry="6"
        transform={`translate(${x - (isPercentageValue ? 35 : 20)}, ${y - 5})`}
        textAnchor="start"
        style={{
          fill: '#e0e0e0',
        }}
      />
      {isPercentageValue && (
        <text textAnchor="end" fill="#9e9e9e" x={x - 5} y={y + 5}>
          %
        </text>
      )}
    </g>
  )
}

export const legendColors = [
  'segmentPrimary',
  'segmentSecondary',
  'segmentTertiary',
]

const isListOrGraphList = new Set([
  'List',
  'TeamList',
  'UserList',
  'Graph',
  'Line',
  'GraphTotal',
  'GraphPerTeam',
  'GraphPerUser',
])

export default function ConfigurationPreviewWidget({
  previewWidget,
}: ConfigurationPreviewProps): React.ReactElement {
  const {
    title,
    description,
    goal,
    visual,
    value,
    showTrendIndicator,
    type,
    trackerUidsCount,
    benchmark,
  } = previewWidget

  const isListOrGraph = isListOrGraphList.has(visual)
  const unit = value === 'Percent' ? '%' : ''

  const minHasValue = goal?.min !== null && goal?.min !== undefined
  const maxHasValue = goal?.max !== null && goal?.max !== undefined

  return (
    <>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      <VisualGraph
        visual={visual}
        benchmark={benchmark}
        goal={goal}
        showTrendIndicator={showTrendIndicator}
        trackerUidsCount={trackerUidsCount}
        type={type}
        value={value}
        unit={unit}
      />
      {!isListOrGraph && (
        <Footer>
          {goal && (minHasValue || maxHasValue) && (
            <Flex align="center">
              <Icon as={MdFlag} mr="1" color="text" />
              {minHasValue && !goal.max && <Text mx="1">&#62;</Text>}
              {minHasValue && (
                <Box bg="gray.300" borderRadius="10" w="50px" h="4" />
              )}
              {minHasValue && maxHasValue && <Text mx="1">-</Text>}

              {!goal.min && maxHasValue && <Text mx="1">&#62;</Text>}
              {maxHasValue && (
                <Box bg="gray.300" borderRadius="10" w="50px" h="4" />
              )}
              {unit && (
                <Flex alignItems="center">
                  <Text as="span">{unit}</Text>
                </Flex>
              )}
            </Flex>
          )}
          <Box />
          <Box bg="gray.300" borderRadius="10" w="50px" h="2.5" />
        </Footer>
      )}
    </>
  )
}
