import { CurrentUser, Role, useCurrentUser } from '@capturi/core'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import React from 'react'
import useSWR from 'swr'

import { SharedContextType } from '../types'

export type SharedContext = {
  type?: SharedContextType
  value?: string[]
}

const OriginalSharedContextTypeContext = React.createContext<
  SharedContextType | undefined
>(undefined)

const SelectedSharedContext = React.createContext<SharedContext | undefined>({})

const SetSelectedSharedContextContext =
  React.createContext<(sharedContext: SharedContext | undefined) => void>(noop)

export function SharedContextProvider({
  defaultType,
  children,
}: React.PropsWithChildren<{
  defaultType?: SharedContextType | null
}>): React.ReactElement {
  const user = useCurrentUser()
  const defaultContextValue = useDefaultContextValue(user, defaultType)
  const [selectedSharedContext, setSelectedSharedContext] = React.useState<
    SharedContext | undefined
  >(defaultContextValue)
  return (
    <OriginalSharedContextTypeContext.Provider value={defaultType ?? undefined}>
      <SelectedSharedContext.Provider value={selectedSharedContext}>
        <SetSelectedSharedContextContext.Provider
          value={setSelectedSharedContext}
        >
          {children}
        </SetSelectedSharedContextContext.Provider>
      </SelectedSharedContext.Provider>
    </OriginalSharedContextTypeContext.Provider>
  )
}

export const useOriginalSharedContextType = (): React.ContextType<
  typeof OriginalSharedContextTypeContext
> => React.useContext(OriginalSharedContextTypeContext)

export const useSelectedSharedContext = (): React.ContextType<
  typeof SelectedSharedContext
> => React.useContext(SelectedSharedContext)

export const useSelectedSharedContextSetter = (): React.ContextType<
  typeof SetSelectedSharedContextContext
> => React.useContext(SetSelectedSharedContextContext)

function useDefaultContextValue(
  user: CurrentUser,
  contextType: SharedContextType | null | undefined,
): SharedContext | undefined {
  const firstTeamLeadTeam = useFirstTeamLeadTeam(user)
  switch (user.role) {
    case Role.administrator: {
      return {
        type: 'Organization',
        value: undefined,
      }
    }
    case Role.teamlead: {
      if (contextType === 'Organization') {
        // Team lead allowed to select Organization only if Dashboard.sharedContextType is Organization
        // In that case we don't need to send sharedContextType to API, so we return undefined
        return undefined
      }
      if (contextType === 'Team') {
        if (firstTeamLeadTeam === undefined) {
          return {
            type: 'Organization',
            value: undefined,
          }
        }
        return {
          type: contextType,
          value: [firstTeamLeadTeam.uid],
        }
      }
      if (contextType === 'User') {
        const members = firstTeamLeadTeam?.members ?? []
        return {
          type: contextType,
          value:
            members.length === 0
              ? [user.id]
              : [members.sort((a, b) => a.name.localeCompare(b.name))[0].uid],
        }
      }
    }
  }

  return {
    type: undefined,
    value: undefined,
  }
}

type Team = {
  uid: string
  name: string
  members: {
    name: string
    uid: string
  }[]
}

function useFirstTeamLeadTeam(user: CurrentUser): Team | undefined {
  const { data: teams } = useSWR<Team[]>(
    () => {
      if (!user.isAdminOrTeamLead) return null
      return 'authentication/organization/teams/details'
    },
    {
      suspense: true,
    },
  )
  return React.useMemo(() => {
    if (isEmpty(user.teamLeadTeamUids)) {
      return undefined
    }
    return (teams ?? [])
      .filter((x) => user.teamLeadTeamUids?.includes(x.uid))
      .sort((a, b) => a.name.localeCompare(b.name))[0]
  }, [teams, user])
}
