import { useMemo } from 'react'
import { Layout } from 'react-grid-layout'

import { WidgetModel } from '../types'
import Registry from '../widgets/registry'

function generateLayout(widgets: WidgetModel[], readOnly = true): Layout[] {
  return widgets.map((widget) => {
    const definition = Registry.get(widget.type)
    const { size, position } = widget

    const x = position?.x ?? 0
    const y = position?.y ?? 0
    const w = size?.width ?? definition.minWidth
    const h = size?.height ?? definition.minHeight

    return {
      i: widget.uid,
      x,
      y,
      w,
      h,
      static: readOnly,
      minW: definition.minWidth,
      minH: definition.minHeight,
    }
  })
}

function useLayouts(widgets: WidgetModel[], readOnly?: boolean): Layout[] {
  return useMemo(() => {
    return generateLayout(widgets, readOnly)
  }, [widgets, readOnly])
}

export default useLayouts
