import { Emoji } from '@capturi/ui-components'
import { Box, Center, Fade, Flex, Heading, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useRef } from 'react'
import ReactGridLayout, { ReactGridLayoutProps } from 'react-grid-layout'
import { useScrollbarWidth, useWindowSize } from 'react-use'

import useAspectRatioGrid from '../../hooks/useAspectRatioGrid'
import { UPGRADED_DASHBOARD_ROWS } from '../../utils/constants'
import DashedLine from '../../views/DashedLine'
import Grid from './Grid'

export type DashboardCanvasProps = {
  columns?: number
  rows?: number
  showGrid?: boolean
  dashboardUpgraded?: boolean
  canEditDashboard?: boolean
  placeholder?: string
  children?: React.ReactNode
} & ReactGridLayoutProps

const UNUPGRADED_BOTTOM_EDITOR_PADDING = 48

const DashboardCanvas: React.FC<DashboardCanvasProps> = ({
  columns = 12,
  rows = 8,
  showGrid = false,
  dashboardUpgraded = false,
  canEditDashboard = false,
  placeholder,
  layout,
  children,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const { height } = useWindowSize()
  const scrollBarWidth = useScrollbarWidth() || 0
  const bottomPadding =
    canEditDashboard && !dashboardUpgraded
      ? UNUPGRADED_BOTTOM_EDITOR_PADDING
      : 8
  const cappedContainerHeight =
    height -
    (containerRef.current?.getBoundingClientRect().top || 0) -
    bottomPadding
  const { ref, width, colWidth, rowHeight, spacing } = useAspectRatioGrid({
    columns,
    rows,
    containerHeight: cappedContainerHeight,
    addAdditionalRow: canEditDashboard && dashboardUpgraded,
    paddingRight: !canEditDashboard && dashboardUpgraded ? scrollBarWidth : 0,
  })

  const hasContents = layout != null && layout.length > 0
  const showPlaceholder = !hasContents && placeholder

  const maxY =
    (layout?.reduce((acc, item) => Math.max(acc, item.y + item.h), 0) || 0) *
    (rowHeight + spacing)
  const overflows = maxY > cappedContainerHeight
  const scrollBarPadding =
    !canEditDashboard && dashboardUpgraded && overflows ? scrollBarWidth : 0
  return (
    <Box
      className="dashboard-container"
      w="100%"
      h="100%"
      flex="1"
      position="relative"
      overflowX="hidden"
      overflowY={dashboardUpgraded && canEditDashboard ? 'auto' : 'inherit'}
      ref={containerRef}
    >
      {showPlaceholder && (
        <Center position="absolute" w="100%" h="100%">
          <Heading fontSize="4xl" color="gray.400" textAlign="center">
            {placeholder}
            <Emoji
              symbol="👆"
              label="finger pointing up"
              fontSize="5xl"
              pl={2}
            />
          </Heading>
        </Center>
      )}

      <Box
        ref={ref}
        position="relative"
        minH={`${cappedContainerHeight}px`}
        h={
          dashboardUpgraded && canEditDashboard
            ? 'auto'
            : `${cappedContainerHeight}px`
        }
        pb={canEditDashboard ? 8 : 0}
      >
        <Grid
          show={showGrid}
          spacing={spacing}
          colWidth={colWidth}
          rowHeight={rowHeight}
        />

        {dashboardUpgraded && canEditDashboard && (
          <Fade in={showGrid}>
            <Flex
              align="center"
              justify="space-between"
              position="absolute"
              gap={0}
              left={2.5}
              right={2.5}
              top={`${
                (spacing + rowHeight) * UPGRADED_DASHBOARD_ROWS + spacing / 2
              }`}
              transform="translate(0, -50%)"
            >
              <Box w="100%" overflow="hidden" flex="1">
                <DashedLine />
              </Box>

              <Text
                bg="white"
                py={1}
                px={2}
                borderRadius="2xl"
                borderWidth={2}
                borderColor="gray.400"
                textColor="gray.400"
              >
                <Trans>Screen height</Trans>
              </Text>

              <Box w="100%" overflow="hidden" flex="1">
                <DashedLine />
              </Box>
            </Flex>
          </Fade>
        )}

        {hasContents && (
          <ReactGridLayout
            className="layout"
            layout={layout}
            // This turns off compaction so items can be placed everywhere.
            compactType={null}
            // This turns off rearrangement so items will not be pushed arround.
            preventCollision
            // If true, the container height swells and contracts to fit contents
            autoSize={dashboardUpgraded}
            // If true, items can be moved only within grid.
            isBounded={!dashboardUpgraded}
            isDraggable={canEditDashboard}
            isResizable={canEditDashboard}
            resizeHandles={canEditDashboard ? undefined : []}
            style={dashboardUpgraded ? {} : { height: '100%' }}
            width={width - scrollBarPadding}
            useCSSTransforms={false}
            cols={columns}
            rowHeight={rowHeight}
            margin={[spacing, spacing]}
            containerPadding={[spacing, spacing]}
            {...props}
            onResize={(
              _layout,
              _oldItem,
              _newItem,
              _placeholder,
              event,
              element,
            ) => {
              props.onResize?.(
                _layout,
                _oldItem,
                _newItem,
                _placeholder,
                event,
                element,
              )

              if (!(containerRef.current && dashboardUpgraded)) return

              const resizeHandleClientRect = element.getBoundingClientRect()
              const containerRect = containerRef.current.getBoundingClientRect()

              const safetyMargin = 50
              const bottomVisible =
                resizeHandleClientRect.bottom <=
                containerRect.bottom - safetyMargin

              if (!bottomVisible) {
                containerRef.current?.scrollTo({
                  top: containerRef.current.scrollTop + safetyMargin,
                  behavior: 'instant',
                })
              }
            }}
            onDrag={(
              _layout,
              _oldItem,
              _newItem,
              _placeholder,
              event,
              element,
            ) => {
              props.onDrag?.(
                _layout,
                _oldItem,
                _newItem,
                _placeholder,
                event,
                element,
              )

              if (!(containerRef.current && dashboardUpgraded)) return

              const elementTop = element.offsetTop
              const elementBottom = elementTop + element.offsetHeight

              const containerScrollTop = containerRef.current.scrollTop
              const containerHeight = containerRef.current.clientHeight

              const safetyMargin = 50
              const isTopVisible = elementTop >= containerScrollTop
              const isBottomVisible =
                elementBottom <=
                containerScrollTop + containerHeight - safetyMargin

              if (!(isTopVisible && isBottomVisible)) {
                containerRef.current?.scrollTo({
                  top: elementBottom - containerHeight + safetyMargin,
                  behavior: 'smooth',
                })
              }
            }}
          >
            {children}
          </ReactGridLayout>
        )}
      </Box>
    </Box>
  )
}

export default DashboardCanvas
