import { MessageDescriptor } from '@lingui/core'
import { msg, select } from '@lingui/macro'

import { SharedContextType, WidgetModel } from '../types'
import { WidgetDefinition } from '../widgets/registry'

export const InsufficientSpace = msg`Insufficient space on dashboard`
export const InsufficientSpace_Desc = msg`Reorganize or delete one or more widgets in order to make room for more widgets.`

export const MakeMoreSpaceForWidget = (
  def: WidgetDefinition<WidgetModel>,
): MessageDescriptor =>
  msg`Reorganize or delete one or more widgets in order to make room for a "${def.name}" widget. The minimum required space is ${def.minWidth}x${def.minHeight} cells`

export const localizeSharedContextType = (
  type: SharedContextType | undefined | null,
): string => {
  if (type == null) return '?'
  return select(type, {
    Organization: 'Organization',
    Team: 'Team',
    User: 'Employee',
    other: 'N/A',
  })
}
