import { i18n } from '@lingui/core'

type Value = number | null | undefined
type FormatValueFn = (value: number) => string

const defaultFormatFn: FormatValueFn = (val) =>
  i18n.number(val, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

export const formatRange = (
  min: Value,
  max: Value,
  formatValue: FormatValueFn = defaultFormatFn,
): string => {
  if (min != null && max != null) {
    return `${formatValue(min)}-${formatValue(max)}`
  }
  if (min != null) {
    return `> ${formatValue(min)}`
  }
  if (max != null) {
    return `< ${formatValue(max)}`
  }
  return ''
}

export const isWithinRange = (
  value: Value,
  min: Value,
  max: Value,
): boolean => {
  if (value == null) return false
  if (min != null && max != null) {
    return min <= value && value <= max
  }
  if (min != null) {
    return min <= value
  }
  if (max != null) {
    return value <= max
  }
  return false
}
