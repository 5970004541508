import { useMeasure } from 'react-use'

const GRID_SPACING = 4
const MIN_CELL_SIZE = 10

type UseAspectRatioGridProps = {
  columns: number
  rows: number
  containerHeight: number
  addAdditionalRow: boolean
  paddingRight?: number
  spacing?: number
  minCellSize?: number
}

function useAspectRatioGrid({
  columns,
  rows,
  containerHeight,
  addAdditionalRow,
  spacing = GRID_SPACING,
  minCellSize = MIN_CELL_SIZE,
}: UseAspectRatioGridProps): {
  ref: (element: HTMLDivElement) => void
  width: number
  colWidth: number
  rowHeight: number
  spacing: number
} {
  const [ref, { width }] = useMeasure<HTMLDivElement>()

  const totalSpacingX = spacing * (columns + 1)
  const totalSpacingY = spacing * (rows + 1)

  const minWidth = totalSpacingX + columns * minCellSize
  const actualWidth = Math.max(minWidth, width)

  // Calculate column width
  const colWidth = (actualWidth - totalSpacingX) / columns
  // Calculate row height
  const rowHeight =
    (containerHeight - totalSpacingY) / (rows + (addAdditionalRow ? 1 : 0))

  return {
    ref,
    width: actualWidth,
    colWidth,
    rowHeight,
    spacing,
  }
}

export default useAspectRatioGrid
