import { Tooltip } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'

export const integerFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}

export const decimalFormat: Intl.NumberFormatOptions = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
}

export const FormatHits: React.FC<{ hits?: number; total?: number }> = ({
  hits,
  total,
}) => {
  const formattedValues = [hits, total]
    .filter((x) => x !== undefined)
    .map((x) => i18n.number(x ?? 0, integerFormat))
    .join('/')
  return (
    <Tooltip label={t`${formattedValues} conversations`}>
      <span>{formattedValues}</span>
    </Tooltip>
  )
}
