import { useTracker } from '@capturi/api-trackers'
import { Speaker } from '@capturi/core'
import { Collapse } from '@chakra-ui/react'
import merge from 'lodash/merge'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  ConfiguratorColumn,
  ConfiguratorLayout,
  DescriptionField,
  GoalField,
  SegmentationField,
  SpeakerField,
  TitleField,
  TrackerField,
  TypeField,
  VisualField,
} from '../configurator'
import { FormModel } from '../configurator/formUtils'
import { WidgetConfigurator } from '../registry'
import { TrackerHitsWidgetModel } from './types'

type TrackerHitsFormModel = FormModel<Partial<TrackerHitsWidgetModel>>

const defaultFormModel: Partial<TrackerHitsFormModel> = {
  type: 'insights_speaker-trackerhits_single',
  visual: 'Value',
  goal: {
    min: null,
    max: null,
  },
}

const TrackerHitsConfigurator: WidgetConfigurator<TrackerHitsWidgetModel> = ({
  formModel,
  onSubmit,
  children,
}) => {
  const formMethods = useForm<TrackerHitsFormModel>({
    defaultValues: merge({}, defaultFormModel, formModel),
  })
  const { watch, handleSubmit, formState, setValue } = formMethods
  const trackerUid = watch('trackerUid', '')
  const speakerId = watch('speakerId')

  const initialTitleValue = formModel?.title
  const titleTouched = !!formState.touchedFields.title

  const { data: tracker } = useTracker(trackerUid)

  React.useEffect(() => {
    if (tracker) {
      if (
        tracker.speech &&
        tracker.speech.speakerId !== Speaker.All &&
        tracker.speech.speakerId !== speakerId
      ) {
        setValue('speakerId', tracker.speech.speakerId)
      }
      if (!(initialTitleValue || titleTouched)) {
        setValue('title', tracker.name, { shouldTouch: false })
      }
    }
  }, [tracker, speakerId, setValue, initialTitleValue, titleTouched])

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ConfiguratorLayout>
          <ConfiguratorColumn>
            <TypeField />
            <TitleField />
            <DescriptionField />
            <SegmentationField />
          </ConfiguratorColumn>
          <ConfiguratorColumn>
            <TrackerField isRequired />
            <Collapse in={tracker?.speech?.speakerId === Speaker.All}>
              <SpeakerField isRequired />
            </Collapse>
            <VisualField<TrackerHitsWidgetModel['visual']>
              options={['Value', 'Gauge']}
            />
            <GoalField unit="%" />
          </ConfiguratorColumn>
        </ConfiguratorLayout>
        {children}
      </form>
    </FormProvider>
  )
}

export default TrackerHitsConfigurator
