import {
  CartesianGrid,
  DateBucket,
  LineDescriptor,
  PrimaryLine,
  TimeSeriesChart,
  Tooltip,
  XAxis,
  YAxis,
  YAxisProps,
  adjustDateForDSTOverlap,
  pctAutoDomain,
  timeSeriesTickFormatters,
} from '@capturi/charts'
import { useFilterPeriodContext } from '@capturi/filters'
import { isWeekend } from 'date-fns'
import React from 'react'
import { Legend, ResponsiveContainer } from 'recharts'

import useOnlyWeekdaysToggle from '../../hooks/useOnlyWeekdaysToggle'
import useWidgetData from '../../hooks/useWidgetData'
import CustomTooltip from '../components/CustomTooltip'
import * as Widget from '../components/Widget'
import { WidgetComponent } from '../registry'
import { getEffectiveTimeResolution } from '../utils/getEffectiveResolution'
import { getResolutionFromTimespan } from '../utils/timeResolution'
import { TrackerTimeSeriesData, TrackerTimeSeriesWidgetModel } from './types'

function getLineColor(index: number): string {
  const colorNames = [
    'segments.primary',
    'segments.secondary',
    'segments.tertiary',
    'segments.quaternary',
    'segments.quinary',
  ]
  return colorNames[index % colorNames.length]
}

const roundHitRate = (value: number): number => {
  if (value < 0.01) return value
  return Math.round(value * 100) / 100
}

function toFraction(value?: number | null): number | undefined {
  if (value == null) return undefined
  return value / 100
}

const DEFAULT_ARRAY: TrackerTimeSeriesData['trackers'] = []

const TrackerTimeSeriesWidget: WidgetComponent<TrackerTimeSeriesWidgetModel> = (
  widget,
) => {
  const { title, description, yAxisRange } = widget
  const { period } = useFilterPeriodContext()
  const resolution = getEffectiveTimeResolution(widget.resolution, period)
  const [plotOnlyWeekdays] = useOnlyWeekdaysToggle()

  const { data } = useWidgetData<TrackerTimeSeriesData>(
    widget,
    ({ period }) => {
      if (widget.resolution !== 'Auto') return
      return {
        resolution: getResolutionFromTimespan(period.from, period.to),
      }
    },
  )

  const trackers = data?.trackers ?? DEFAULT_ARRAY

  const hasUserDefinedDomain =
    yAxisRange?.min != null || yAxisRange?.max != null
  const domain: YAxisProps['domain'] = [
    toFraction(yAxisRange?.min) ?? pctAutoDomain[0],
    toFraction(yAxisRange?.max) ?? pctAutoDomain[1],
  ]

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = { date }
      }
      return buckets[bucketKey]
    }

    trackers.forEach((t) => {
      t.series.forEach((x) => {
        const date = adjustDateForDSTOverlap(
          x.dateTime,
          period.from.getTimezoneOffset() * -1,
        )

        // Omit weekend data?
        if (
          plotOnlyWeekdays &&
          isWeekend(date) &&
          (resolution === 'Hours' || resolution === 'Days')
        ) {
          return
        }

        const bucket = getOrCreateBucket(date)
        const value =
          x.conversationsWithHitPercent == null
            ? null
            : x.conversationsWithHitPercent / 100
        bucket[t.trackerUid] = value
      })
    })

    const lines = trackers.map<LineDescriptor>((t, i) => {
      return {
        /**
         * Including `widget.uid` in the line id due to the fact that the line
         * id "will be used to generate unique clip path id internally" (https://recharts.org/en-US/api/Line#id)
         * But having multiple charts (widgets on dashboards) on the same page where there are lines with the same id (trackerUid) will
         * create clip paths that will have an affect on each other.
         * Fx. resizing a widget could affect a clip path in another widget.
         */
        id: `${widget.uid}:${t.trackerUid}`,
        color: getLineColor(i),
        label: t.trackerName ?? '?',
        getValue: (d) => {
          const value = d[t.trackerUid]
          if (value == null) return null
          return roundHitRate(value)
        },
      }
    }, [])

    return {
      data: Object.values(buckets),
      lines,
    }
  }, [trackers, period.from, widget.uid, plotOnlyWeekdays, resolution])

  return (
    <Widget.Container>
      <Widget.Title>{title}</Widget.Title>
      <Widget.Description>{description}</Widget.Description>
      <Widget.Content fontSize="0.75em">
        <ResponsiveContainer height="100%" minWidth={100}>
          <TimeSeriesChart
            data={results.data}
            resolution={resolution ?? 'Days'}
          >
            <Legend />
            <CartesianGrid />
            <XAxis dataKey="date" />
            <YAxis
              domain={domain}
              allowDataOverflow={hasUserDefinedDomain}
              tickFormatter={timeSeriesTickFormatters.yAxis.percent}
            />
            <Tooltip
              content={
                <CustomTooltip
                  resolution={resolution}
                  customValueFormatter={(value) =>
                    timeSeriesTickFormatters.yAxis.percent(value)
                  }
                />
              }
            />
            {results.lines.map((line) => (
              <PrimaryLine key={line.id} line={line} />
            ))}
          </TimeSeriesChart>
        </ResponsiveContainer>
      </Widget.Content>
    </Widget.Container>
  )
}

export default TrackerTimeSeriesWidget
