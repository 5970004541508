import { CartesianGrid, TimeSeriesChart, XAxis, YAxis } from '@capturi/charts'
import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { Legend, ResponsiveContainer } from 'recharts'
import {
  legendColors,
  skeletonXAxis,
  skeletonYAxis,
} from './ConfigurationPreviewWidget'
import { timeSeriesChartData } from './PreviewWidgetData'

export const PreviewChartContainer: React.FC<{
  children: React.ReactNode
  isPercentageValue: boolean
}> = ({ children, isPercentageValue }) => (
  <ResponsiveContainer minHeight={250}>
    <TimeSeriesChart data={timeSeriesChartData}>
      <Legend
        content={({ payload }) => {
          return (
            <Flex wrap="wrap" gap="2" justify="center">
              {(payload ?? []).map((entry, index) => {
                return (
                  <Box key={`item-${index}`}>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 32 32"
                      version="1.1"
                      style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        marginRight: '4px',
                      }}
                    >
                      <path
                        strokeWidth="4"
                        fill="none"
                        stroke={entry.color}
                        d="M0,16h10.666666666666666
                    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
                    H32M21.333333333333332,16
                    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                      />
                    </svg>
                    <Box
                      display="inline-block"
                      w="8"
                      h="3"
                      bg={legendColors[index]}
                      borderRadius="50"
                    />
                  </Box>
                )
              })}
            </Flex>
          )
        }}
      />
      <CartesianGrid />
      <XAxis tick={skeletonXAxis} />
      <YAxis
        width={40}
        tick={(props) => skeletonYAxis(props, isPercentageValue)}
      />
      {children}
    </TimeSeriesChart>
  </ResponsiveContainer>
)
