import { Trans, t } from '@lingui/macro'

import { NumberInputComponentProps, ValueRangeField } from './ValueRangeField'
import { FormField } from './types'

export const YAxisRangeField: FormField<null, NumberInputComponentProps> = (
  props,
) => {
  return (
    <ValueRangeField
      label={t`Value of the Y-axis`}
      name="yAxisRange"
      description={
        <Trans>
          Specify whether the y-axis (the vertical axis) should have a certain
          range
        </Trans>
      }
      {...props}
    />
  )
}
