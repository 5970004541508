import { createContext } from 'react'

const EditableWidgetContext = createContext<
  | {
      onEditWidget?: () => void
    }
  | undefined
>(undefined)

export default EditableWidgetContext
