import React from 'react'

const DashedLine: React.FC = () => {
  return (
    <svg
      width="1016"
      height="3"
      viewBox="0 0 1016 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 1.32349L1109.28 1.32339"
        stroke="#BDBDBD"
        strokeWidth="2"
        strokeDasharray="10 10"
      />
    </svg>
  )
}

export default DashedLine
