import analytics from '@capturi/analytics'
import { useAPI } from '@capturi/api-utils'
import request from '@capturi/request'
import { useQueryClient } from '@tanstack/react-query'
import memoize from 'lodash/memoize'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { KeyedMutator, SWRConfiguration } from 'swr'

import dashboardsAPI, { WidgetCreateModel } from '../api'
import { useDashboardContext } from '../contexts/DashboardContext'
import {
  AccessKey,
  Dashboard,
  UpdateDashboardRequestModel,
  WidgetModel,
} from '../types'
import * as cacheUpdaters from '../utils/cacheUpdaters'
import { isDashboardUpgraded } from '../utils/constants'
import findBestPosition from '../utils/findBestPosition'
import { cacheKey as dashboardsCacheKey } from './useDashboards'

const createWidgetsByUid = (
  widgets: WidgetModel[] | undefined,
): { [key: string]: WidgetModel } => {
  return (widgets ?? []).reduce<{ [uid: string]: WidgetModel }>((dict, w) => {
    dict[w.uid] = w
    return dict
  }, {})
}

const memoizedCreateWidgetsByUid = memoize(createWidgetsByUid)

type UpdateWidgetOptions = {
  optimistic?: boolean
}

interface DashboardActions {
  deleteDashboard: () => Promise<void>
  cloneDashboard: (title: string, description?: string) => Promise<Dashboard>
  updateDashboard: (model: UpdateDashboardRequestModel) => Promise<Dashboard>
  addWidget: <T extends WidgetCreateModel>(model: T) => Promise<T>
  deleteWidget: (uid: string) => Promise<void>
  updateWidget: <T extends WidgetModel>(
    uid: string,
    model: Partial<T>,
    options?: UpdateWidgetOptions,
  ) => Promise<T>
  addAccessKey: () => Promise<AccessKey>
  deleteAccessKey: () => Promise<void>
}

type UseDashboardResponse = {
  dashboard?: Dashboard | null
  widgets: Dashboard['widgets']
  isRefreshing: boolean
  isLoading: boolean
  refreshDashboard: () => void
  findWidgetPosition: (
    width: number,
    height: number,
  ) => ReturnType<typeof findBestPosition>
  getWidget: (uid: string) => WidgetModel | undefined
  actions: DashboardActions
}

type UseDashboardOptions = {
  uid?: string
  onDashboardNotFound?: () => void
}

export function useDashboard(
  options: UseDashboardOptions = {},
  swrConfig: SWRConfiguration = {},
): UseDashboardResponse {
  const uidContext = useDashboardContext()
  const uid = options.uid ?? uidContext
  const api = dashboardsAPI.dashboards

  if (!uid) throw new Error('No `uid` provided to `useDashboard`.')

  const optionsRef = useRef(options)

  const {
    data: dashboard,
    error,
    isValidating,
    mutate,
  } = useAPI<Dashboard | null>(api.get(uid), {
    suspense: true,
    ...swrConfig,
  })

  useEffect(() => {
    if (dashboard === null) {
      /*
        When dashboard is `null`, fx. due to a 204 No Content response from server when
        a dashboard entity with given uid can not be resolved.
      */
      optionsRef.current.onDashboardNotFound?.()
    }
  }, [dashboard])

  const widgets = dashboard?.widgets ?? []

  const getWidget = (uid: string): WidgetModel | undefined => {
    const map = memoizedCreateWidgetsByUid(dashboard?.widgets)
    return map[uid]
  }

  const refreshDashboard = useCallback(() => mutate(), [mutate])

  const dashboardUpgraded = isDashboardUpgraded(dashboard)
  const findWidgetPosition = useCallback(
    (width: number, height: number): ReturnType<typeof findBestPosition> => {
      if (dashboard == null) return undefined
      return findBestPosition(
        dashboard.columns,
        dashboardUpgraded ? Number.MAX_SAFE_INTEGER : dashboard.rows,
        width,
        height,
        dashboard.widgets.map((w) => ({
          x: w.position.x,
          y: w.position.y,
          width: w.size.width,
          height: w.size.height,
        })),
      )
    },
    [dashboard, dashboardUpgraded],
  )
  const actions = useDashboardActions({ uid, onMutate: mutate })

  return {
    dashboard,
    widgets,
    isLoading: !(dashboard || error),
    isRefreshing: isValidating,
    refreshDashboard,
    findWidgetPosition,
    getWidget,
    actions,
  }
}

type UseDashboardActionsOptions = {
  uid?: string
  onMutate?: KeyedMutator<Dashboard | null>
}

export function useDashboardActions(
  options: UseDashboardActionsOptions = {},
): DashboardActions {
  const uidContext = useDashboardContext()
  const uid = options.uid ?? uidContext
  const api = dashboardsAPI.dashboards
  const widgetsAPI = dashboardsAPI.widgets

  const queryClient = useQueryClient()
  const mutate = useCallback<KeyedMutator<Dashboard | null>>(
    (data, shouldRevalidate = true) => {
      if (shouldRevalidate) {
        queryClient.refetchQueries({ queryKey: dashboardsCacheKey })
      }

      if (options.onMutate == null) return Promise.resolve(undefined)

      if (typeof data === 'function') {
        return options.onMutate((prevData) => {
          const newData = data(prevData)
          return newData ?? prevData
        }, shouldRevalidate)
      }
      return options.onMutate(data, shouldRevalidate)
    },
    [options, queryClient],
  )

  const actions = useMemo<DashboardActions>(() => {
    return {
      deleteDashboard: async (): Promise<void> => {
        return await request(api.delete(uid))
      },
      updateDashboard: async (
        model: UpdateDashboardRequestModel,
      ): Promise<Dashboard> => {
        try {
          // update swr cache optimistically
          mutate((data) => cacheUpdaters.patchDashboard(data, model), false)
          // update dashboard and set resolved data in cache
          const resp = await request<Dashboard>(api.update(uid, model))
          mutate(resp)
          return resp
        } catch (error) {
          // update to server truth on error
          mutate()
          // rethrow to let client react to error
          throw error
        }
      },
      cloneDashboard: async (
        title: string,
        description?: string,
      ): Promise<Dashboard> => {
        return await request<Dashboard>(api.clone(uid, title, description))
      },
      addWidget: async <T extends WidgetCreateModel>(model: T): Promise<T> => {
        try {
          return await request<T>(widgetsAPI.create(uid, model))
        } finally {
          mutate()
        }
      },
      deleteWidget: async (widgetUid: string): Promise<void> => {
        try {
          // optimistic update
          mutate((d) => cacheUpdaters.deleteWidget(d, widgetUid), false)
          await request(widgetsAPI.delete(uid, widgetUid))
        } finally {
          mutate()
        }
      },
      /**
       * TODO: consider creating two update functions:
       * 1. to update position and size on dashboard
       * 2. to update widget configuration
       */
      updateWidget: async <T extends WidgetModel>(
        widgetUid: string,
        model: Partial<T>,
        optionsArg: UpdateWidgetOptions = {},
      ): Promise<T> => {
        try {
          const options = {
            optimistic: true,
            ...optionsArg,
          }

          if (options.optimistic) {
            // update swr cache optimistically
            mutate(
              (data) =>
                cacheUpdaters.patchWidget(data, { ...model, uid: widgetUid }),
              false,
            )
          }
          // update widget and set resolved data in cache
          const resp = await request<T>(
            widgetsAPI.update(uid, widgetUid, model),
          )
          mutate((d) => cacheUpdaters.replaceWidget(d, resp))
          return resp
        } catch (error) {
          // update to server truth on error
          mutate()
          // rethrow to let client react to error
          throw error
        }
      },
      addAccessKey: async (): Promise<AccessKey> => {
        try {
          const resp = await request<AccessKey>(api.addAccessKey(uid))
          mutate((d) => cacheUpdaters.addAccessKey(d, resp), false)
          analytics.event('publicDashboardLink', resp)
          return resp
        } finally {
          mutate()
        }
      },
      deleteAccessKey: async (): Promise<void> => {
        try {
          // optimistic update
          mutate((d) => cacheUpdaters.deleteAccessKey(d), false)
          await request(api.deleteAccessKey(uid))
        } finally {
          mutate()
        }
      },
    }
  }, [api, uid, mutate, widgetsAPI])
  return actions
}
