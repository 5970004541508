import { ErrorBoundary, FallbackProps } from '@capturi/react-utils'
import { ResponseError } from '@capturi/request'
import { Button } from '@capturi/ui-components'
import { Box, ButtonGroup, Icon, Stack, Text } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useContext } from 'react'
import { MdEdit, MdErrorOutline, MdRefresh } from 'react-icons/md'
import { useEffectOnce } from 'react-use'

import EditableWidgetContext from '../../contexts/EditableWidgetContext'
import { ErrorWidgetEvent, logWidgetEvent } from '../../events'
import { useOnWidgetConfigChange } from '../../hooks/useWidgetData'
import { WidgetModel } from '../../types'
import * as Widget from '../../widgets/components/Widget'

type WidgetErrorBoundaryProps = {
  widget: WidgetModel
  children?: React.ReactNode
}

const WidgetFallbackComponent: React.FC<
  FallbackProps & WidgetErrorBoundaryProps
> = ({ widget, error, resetErrorBoundary }) => {
  const editableWidgetCtx = useContext(EditableWidgetContext)

  const attemptWidgetReset = (): void => {
    /**
     * Reset data (swr cache). This must be called before attempting to
     * recover from the error by calling `resetErrorBoundary` otherwise
     * the SWR cache will respond with the same error that triggered this error boundary in the first place.
     * */
    try {
      resetErrorBoundary()
    } catch (_e) {
      //
    }
  }
  /**
   * Listen for changes to the widget configuration and attempt
   * to recover from the error
   */
  useOnWidgetConfigChange(widget, attemptWidgetReset)
  // biome-ignore lint/suspicious/noExplicitAny: legacy
  let status: number | undefined = (error as any)?.status
  if (error instanceof ResponseError) {
    status = error.statusCode
  }

  useEffectOnce(() => {
    logWidgetEvent(ErrorWidgetEvent.ErrorWidgetImpression, widget, {
      errorStatus: status,
    })
  })

  let title = t`Error`
  let desc = t`An error occurred`
  if (status != null && status >= 400) {
    if (status === 409) {
      title = t`Configuration error`
      desc = t`Please check the configuration for this widget.`
    } else {
      desc = t`Data could not be fetched`
    }
  }
  return (
    <Widget.Container>
      <Widget.Title>{widget.title}</Widget.Title>
      <Widget.Description>{widget.description}</Widget.Description>
      <Stack
        spacing={2}
        align="center"
        justify="center"
        flex={1}
        overflow="hidden"
      >
        <Box w="100%" maxW={12} maxH={12} color="gray.600" overflow="hidden">
          <Icon as={MdErrorOutline} w="100%" h="100%" fill="gray.400" />
        </Box>
        <Box color="textMuted" flex="0 0 auto" textAlign="center">
          <Text fontWeight="medium">{title}</Text>
          <Text fontSize="sm">{desc}</Text>
          <ButtonGroup mt={1} size="xs" variant="outline">
            <Button
              onClick={() => {
                logWidgetEvent(ErrorWidgetEvent.RefreshWidgetClicked, widget)
                attemptWidgetReset()
              }}
              leftIcon={<MdRefresh />}
            >
              <Trans>Refresh</Trans>
            </Button>
            {typeof editableWidgetCtx?.onEditWidget === 'function' && (
              <Button
                variant="solid"
                colorScheme="primary"
                onClick={() => {
                  logWidgetEvent(ErrorWidgetEvent.EditWidgetClicked, widget)
                  editableWidgetCtx?.onEditWidget?.()
                }}
                leftIcon={<MdEdit />}
              >
                <Trans>Edit</Trans>
              </Button>
            )}
          </ButtonGroup>
        </Box>
      </Stack>
    </Widget.Container>
  )
}

const WidgetErrorBoundary: React.FC<WidgetErrorBoundaryProps> = ({
  widget,
  ...props
}) => {
  return (
    <ErrorBoundary
      fallbackRender={(fallbackProps: FallbackProps) => (
        <WidgetFallbackComponent {...fallbackProps} widget={widget} />
      )}
      {...props}
    />
  )
}

export default WidgetErrorBoundary
