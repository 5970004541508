import { Speaker } from '@capturi/core'
import { useOrganization } from '@capturi/stores'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans, select, t } from '@lingui/macro'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FormField } from './types'

type TrackerSpeakerSelect = Omit<RadioGroupProps, 'children' | 'onChange'> & {
  onChange: (speaker: Speaker) => void
}

export const TrackerSpeakerSelect = React.forwardRef<
  HTMLDivElement,
  TrackerSpeakerSelect
>(function TrackerSpeakerSelect(props, ref) {
  const { organizationType } = useOrganization()
  return (
    <RadioGroup
      ref={ref}
      {...props}
      value={props.value?.toString()}
      onChange={(val) => {
        props.onChange?.(Number.parseInt(val.toString(), 10))
      }}
    >
      <HStack spacing={4}>
        {/**
         * Manually adding id on Radio due to bug:
         * https://github.com/chakra-ui/chakra-ui/issues/3074
         */}
        <Radio value={Speaker.All.toString()} id="speaker-all">
          <Trans>All</Trans>
        </Radio>
        <Radio value={Speaker.Employee.toString()} id="speaker-employee">
          <Trans>Employee</Trans>
        </Radio>
        <Radio value={Speaker.Customer.toString()} id="speaker-customer">
          {select(organizationType, {
            public: 'Citizen',
            other: 'Customer',
          })}
        </Radio>
      </HStack>
    </RadioGroup>
  )
})

export const SpeakerField: FormField<Speaker> = ({
  name = 'speakerId',
  isRequired,
}) => {
  const { formState } = useFormContext()
  const isInvalid = formState.errors[name] != null
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <FormLabel htmlFor="widget-speakerId">
        <Trans>Speaker</Trans>
      </FormLabel>
      <Controller
        name={name}
        rules={{
          required: isRequired ? t`Please select a speaker` : false,
        }}
        render={({ field }) => (
          <TrackerSpeakerSelect {...field} id="widget-speakerId" />
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
