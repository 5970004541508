import { Center } from '@chakra-ui/react'
import { i18n } from '@lingui/core'

import useWidgetData from '../../hooks/useWidgetData'

import {
  Container,
  Content,
  Description,
  Footer,
  Goal,
  SubText,
  Title,
} from '../components/Widget'
import GaugeDisplay from '../components/visuals/GaugeDisplay'
import SingleValue from '../components/visuals/SingleValueDisplay'
import { WidgetComponent } from '../registry'
import { FormatHits } from '../utils/format'
import { TrackerHitsData, TrackerHitsWidgetModel } from './types'

/**
 * Tracker hit rates are rounded percentages in order to limit the precision.
 * However, if the value is below 1% we should a single decimal precision.
 * @param value number between [0...1]
 * @returns formated decimal
 */
export const formatHitRate = (value?: number): string => {
  if (value === undefined) return ''
  return i18n.number(value, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: value < 1 ? 1 : 0,
  })
}

const TrackerHits: WidgetComponent<TrackerHitsWidgetModel> = (widget) => {
  const { title, description, goal } = widget
  const { data } = useWidgetData<TrackerHitsData>(widget)

  const hits = data?.conversationsWithHit
  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Content>
        {widget.visual === 'Gauge' ? (
          <GaugeDisplay
            value={data?.conversationsWithHitPercent}
            goal={goal}
            unit="%"
            formatValue={formatHitRate}
          />
        ) : (
          <SingleValue.ValueContainer
            value={data?.conversationsWithHitPercent}
            goal={goal}
            unit="%"
            formatValue={formatHitRate}
          >
            <Center flexDirection="column">
              <SingleValue.ValueDisplay />
            </Center>
          </SingleValue.ValueContainer>
        )}
      </Content>
      <Footer>
        <Goal {...goal} value={data?.conversationsWithHitPercent} unit="%" />
        <SubText>
          <FormatHits hits={hits} total={data?.conversations} />
        </SubText>
      </Footer>
    </Container>
  )
}

export default TrackerHits
