import { useCurrentUser } from '@capturi/core'
import {
  FilterMenuPlacementProvider,
  FilterPeriodProvider,
  SegmentationFieldContext,
  SingleSegmentContainer,
  useFilterDefinitions,
} from '@capturi/filters'
import { FormControl, FormLabel, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import { useSegmentStatesFormSync } from '../useSegmentStatesFormSync'

type SegmentationFieldProps = {
  title?: React.ReactNode
  description?: React.ReactNode
  maxWidth?: number | string
}
export const SegmentationField: React.FC<SegmentationFieldProps> = ({
  title = <Trans>Filtering</Trans>,
  description,
  maxWidth,
}) => {
  const currentUser = useCurrentUser()

  useSegmentStatesFormSync() // syncs segment states -> form state

  const filterDefinitions = useFilterDefinitions(currentUser, {})
  return (
    <FormControl>
      <FormLabel>
        <Text>{title}</Text>
        {description && (
          <Text color="textMuted" fontSize="sm" fontWeight="normal">
            {description}
          </Text>
        )}
      </FormLabel>
      <FilterPeriodProvider>
        <SegmentationFieldContext.Provider value={maxWidth}>
          <FilterMenuPlacementProvider value="top-start">
            <SingleSegmentContainer
              filterDefinitions={filterDefinitions}
              resetSavedFilterOnValuesChange={true}
              showExportButton={false}
              showNotificationButton={false}
              showSaveButton={false}
            />
          </FilterMenuPlacementProvider>
        </SegmentationFieldContext.Provider>
      </FilterPeriodProvider>
    </FormControl>
  )
}
