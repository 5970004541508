import { Box, BoxProps } from '@chakra-ui/react'
import React, { useMemo } from 'react'

import { WidgetBackgroundColorName, getColorValue } from '../../widgets/colors'

interface WidgetCardProps extends BoxProps {
  colorName?: WidgetBackgroundColorName | null | undefined
  dragging?: boolean
}

const WidgetCard = React.forwardRef<HTMLDivElement, WidgetCardProps>(
  ({ colorName, dragging, ...props }, ref) => {
    const backgroundColor = getColorValue(colorName)

    const transparent = backgroundColor === 'transparent'

    const bgColor = useMemo(() => {
      switch (backgroundColor) {
        case 'transparent':
          if (dragging) {
            return 'white'
          }
          return
        case 'default':
          return '#FAFAFA' // Default background color
        default:
          return backgroundColor // Use the custom background color
      }
    }, [dragging, backgroundColor])

    return (
      <Box
        ref={ref}
        boxShadow={transparent ? undefined : 'lg'}
        borderRadius="md"
        p={2}
        bg={bgColor}
        border={transparent ? 0 : '1px'}
        borderColor="blackAlpha.50"
        overflow="hidden"
        {...props}
      />
    )
  },
)

export default WidgetCard
