import analytics from '@capturi/analytics'
import { useTeams } from '@capturi/core'
import { useIsHovering } from '@capturi/react-utils'
import {
  Box,
  Link,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { MdLaunch } from 'react-icons/md'
import { ResponsiveContainer } from 'recharts'

import { useDashboardPublicContext } from '../../../../contexts/DashboardPublicContext'
import useWidgetData from '../../../../hooks/useWidgetData'
import useWidgetFilterLink from '../../../../hooks/useWidgetFilterLink'
import { WidgetModel } from '../../../../types'
import * as Widget from '../../../components/Widget'
import {
  Cell,
  CellIcon,
  FlexibleCell,
  ListContainer,
  Table,
  getFontSize,
  labelCss,
  subLabelCss,
  useListContainer,
  valueCss,
} from '../../../components/visuals/ListDisplay'
import {
  ConversationTeamModel,
  HitRateTeamBreakdownData,
  HitRateValueType,
  HitRateWidgetModel,
  TeamShareStatistics,
  TextTeamModel,
} from '../../types'
import { compareRows, renderSubText, renderValue } from './shared'

export type HitRateTeamBreakdownProps = {
  widget: HitRateWidgetModel
}

type TeamBreakdownProps = {
  width?: number
  height?: number
  valueType?: HitRateValueType
  data: HitRateTeamBreakdownData
  widget: WidgetModel
}

type TeamBreakdownRowProps = {
  value: string
  subText: string
  teamName: string
  row: TeamShareStatistics
  widget: WidgetModel
  isPublicDashboard: boolean
  index: number
  isTooltipDisabled: boolean
}

const TeamBreakDownRow: React.FC<TeamBreakdownRowProps> = ({
  value,
  subText,
  teamName,
  row,
  widget,
  isPublicDashboard,
  index,
  isTooltipDisabled,
}) => {
  const widgetHitRef = useRef<HTMLTableRowElement>(null)
  const isHovering = useIsHovering(widgetHitRef)

  const hitLink = useWidgetFilterLink({
    widget,
    additionalTeamFilter: [row.teamUid],
  })

  const isLinkHidden = index === 0 && widget.title === ''
  const infoText = `${teamName}: ${value} (${subText})`

  return (
    <chakra.tr ref={widgetHitRef}>
      <FlexibleCell>
        <Tooltip label={infoText} isDisabled={isTooltipDisabled}>
          <Box>
            <Text as="div" css={labelCss}>
              {hitLink && !isPublicDashboard ? (
                <>
                  <Link
                    width="100%"
                    overflow="hidden"
                    href={hitLink}
                    isExternal
                    onClick={() => {
                      analytics.event('dashboard_widget_link_list_username', {
                        widgetType: widget.type,
                        visual: widget.visual,
                      })
                    }}
                  >
                    <Tag
                      variant="outline"
                      colorScheme="secondary"
                      color="gray.900"
                      fontSize="0.9em"
                      py="0.1em"
                      px="0.5em"
                    >
                      <TagLabel>{teamName}</TagLabel>
                    </Tag>
                  </Link>
                  <Text as="div" css={subLabelCss}>
                    <Text color="textMuted" noOfLines={1} wordBreak="break-all">
                      {subText}
                    </Text>
                  </Text>
                </>
              ) : (
                <>
                  <Tag
                    variant="outline"
                    colorScheme="secondary"
                    color="gray.900"
                    fontSize="0.75em"
                    py="0.1em"
                    px="0.5em"
                  >
                    <TagLabel>{teamName}</TagLabel>
                  </Tag>
                  <Text as="div" css={subLabelCss}>
                    <Text color="textMuted" noOfLines={1} wordBreak="break-all">
                      {subText}
                    </Text>
                  </Text>
                </>
              )}
            </Text>
          </Box>
        </Tooltip>
      </FlexibleCell>
      <Cell isNumeric textAlign="end" verticalAlign="baseline" pos="relative">
        {isHovering && hitLink && !isPublicDashboard ? (
          <Link
            href={hitLink}
            isExternal
            onClick={() => {
              analytics.event('dashboard_widget_link_list', {
                widgetType: widget.type,
                visual: widget.visual,
              })
            }}
          >
            <CellIcon mr={isLinkHidden ? '8' : undefined}>
              <MdLaunch />
            </CellIcon>
          </Link>
        ) : (
          <Text as="div" css={valueCss}>
            <Text>{value}</Text>
          </Text>
        )}
      </Cell>
    </chakra.tr>
  )
}

function mapWidgetData(
  data: TextTeamModel | ConversationTeamModel | undefined,
): HitRateTeamBreakdownData | undefined {
  if (data === undefined) return undefined

  return {
    teams: data.teams.map((d) => {
      if ('conversations' in d) {
        return {
          teamUid: d.teamUid,
          teamName: d.teamName,
          entities: d.conversations,
          share: d.conversationsShare,
          sharePercent: d.conversationsSharePercent,
        }
      }
      return {
        teamUid: d.teamUid,
        teamName: d.teamName,
        entities: d.cases,
        share: d.casesShare,
        sharePercent: d.casesSharePercent,
      }
    }),
  }
}

const TeamBreakdown: React.FC<TeamBreakdownProps> = ({
  data,
  valueType = 'Percent',
  widget,
}) => {
  const isPublicDashboard = useDashboardPublicContext()
  const { getTeamByUid } = useTeams({
    /**
     * When viewing dashboard with a public link `teamName` will be populated by the API.
     * Block this request in that case (request will fail otherwise because it is not exposed as a public endpoint)
     */
    isPaused: () => isPublicDashboard,
  })

  const { ref: scrollRef, width, isScrolling } = useListContainer()

  const rows = useMemo(() => {
    const rows = data.teams.map((x) => {
      /**
       * Only when viewing dashboard with a public link will
       * `teamName` be populated
       */
      if (x.teamName === undefined) {
        const team = getTeamByUid(x.teamUid)
        return {
          ...x,
          teamName: team?.name,
        }
      }
      return x
    })
    return rows.sort((a, b) => {
      const diff = compareRows(a, b, valueType)
      if (diff !== 0) return diff
      return (a.teamName ?? '').localeCompare(b.teamName ?? '')
    })
  }, [data, getTeamByUid, valueType])

  const fontSize = getFontSize(width)
  return (
    <ListContainer ref={scrollRef}>
      <Table fontSize={`${fontSize}px`}>
        <chakra.tbody>
          {rows.map((x, i) => {
            const value = renderValue(x, valueType)
            const subText = renderSubText(x, valueType)
            return (
              <TeamBreakDownRow
                row={x}
                key={x.teamUid}
                index={i}
                value={value}
                subText={subText}
                teamName={x.teamName ?? ''}
                widget={widget}
                isPublicDashboard={isPublicDashboard}
                isTooltipDisabled={isScrolling}
              />
            )
          })}
        </chakra.tbody>
      </Table>
    </ListContainer>
  )
}

export const HitRateTeamBreakdown: React.FC<HitRateTeamBreakdownProps> = ({
  widget,
}) => {
  const { title, description, value: widgetValueType } = widget
  const { data: rawData } = useWidgetData<
    TextTeamModel | ConversationTeamModel
  >(widget, () => ({
    /**
     * `visual` is not used by the API but works here as a part of the SWR cache key.
     * If not present, then after changing the visual type of a widget that widget could
     * initially be served data conforming to the previous visual type from the cache.
     **/
    visual: widget.visual,
  }))

  const data = mapWidgetData(rawData)

  return (
    <Widget.Container>
      <Widget.Title>{title}</Widget.Title>
      <Widget.Description>{description}</Widget.Description>
      <Widget.Content>
        {data && (
          <ResponsiveContainer height="100%" width="100%">
            <TeamBreakdown
              data={data}
              valueType={widgetValueType}
              widget={widget}
            />
          </ResponsiveContainer>
        )}
      </Widget.Content>
    </Widget.Container>
  )
}
