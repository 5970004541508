import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useFormContext } from 'react-hook-form'

import { FormField } from './types'

export const TitleField: FormField = ({ name = 'title', isRequired }) => {
  const { register } = useFormContext()
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel htmlFor="widget-title">
        <Trans>Title</Trans>
      </FormLabel>
      <Input
        id="widget-title"
        {...register(name, {
          required: isRequired ? t`Please add a title` : false,
        })}
        size="sm"
      />
    </FormControl>
  )
}
