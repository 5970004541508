import { useSegmentStatesContext } from '@capturi/filters'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { WidgetModel } from '../../types'

export const useSegmentStatesFormSync = (): void => {
  const { setValue } = useFormContext<Partial<WidgetModel>>()
  const { states } = useSegmentStatesContext()

  useEffect(() => {
    if (states.length === 0) return

    if (states[0].channel === 'email') {
      setValue('filters', null)
      setValue('shareFilter', null)
      setValue(
        'textFilters',
        states[0].savedTextFilter?.uid
          ? { savedCaseFilterUid: states[0].savedTextFilter.uid }
          : { ...states[0].values },
      )
      setValue(
        'textShareFilter',
        states[0].subFilterState
          ? states[0].subFilterState.savedTextFilter?.uid
            ? {
                savedCaseFilterUid:
                  states[0].subFilterState.savedTextFilter.uid,
              }
            : { ...states[0].subFilterState.values }
          : null,
      )
    } else {
      setValue(
        'filters',
        states[0].savedFilter?.uid
          ? { savedFilterGroupUid: states[0].savedFilter.uid }
          : {
              ...states[0].values,
            },
      )
      setValue(
        'shareFilter',
        states[0].subFilterState?.savedFilter?.uid
          ? { savedFilterGroupUid: states[0].subFilterState.savedFilter.uid }
          : {
              ...states[0].subFilterState?.values,
            },
      )
      setValue('textFilters', null)
      setValue('textShareFilter', null)
    }
  }, [setValue, states])
}
