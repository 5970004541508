import {
  CartesianGrid,
  DashedLineVariants,
  DateBucket,
  GoalReferenceArea,
  LineDescriptor,
  PrimaryLine,
  TimeSeriesChart,
  Tooltip,
  XAxis,
  YAxis,
  adjustDateForDSTOverlap,
  timeSeriesTickFormatters,
} from '@capturi/charts'
import { useTeams } from '@capturi/core'
import { useFilterPeriodContext } from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import { useTheme } from '@capturi/ui-theme'
import { getColor } from '@chakra-ui/theme-tools'
import { isWeekend } from 'date-fns'
import uniq from 'lodash/uniq'
import React from 'react'
import { Customized, Legend, Line, ResponsiveContainer } from 'recharts'

import { useDashboardPublicContext } from '../../../contexts/DashboardPublicContext'
import useOnlyWeekdaysToggle from '../../../hooks/useOnlyWeekdaysToggle'
import useWidgetData from '../../../hooks/useWidgetData'
import CustomTooltip from '../../components/CustomTooltip'
import * as Widget from '../../components/Widget'
import { getEffectiveTimeResolution } from '../../utils/getEffectiveResolution'
import { getResolutionFromTimespan } from '../../utils/timeResolution'
import { ScoreTimeSeriesData, ScoreWidgetModel } from '../types'

function useUserNameLookup(
  data: ScoreTimeSeriesData | undefined,
  isPublicDashboard: boolean,
): { [key: string]: string } {
  const { getUserByUid } = useUsers(
    /**
     * Block this request when in public dashboard mode (request will fail otherwise because it is not exposed as a public endpoint)
     */
    isPublicDashboard,
  )

  return React.useMemo(() => {
    if (!data) {
      return {}
    }
    let userDict: { [key: string]: string } = {}
    if (data && 'users' in data) {
      // Public dashboard
      // `data` will contain a `users` property in public dashboard mode
      userDict = (data.users ?? []).reduce<typeof userDict>((memo, x) => {
        if (x.userIsDeleted) return memo
        memo[x.userUid] = x.userName
        return memo
      }, {})
    } else {
      // Retrieve all user uids from the response data
      const allUserUids = uniq(
        (data?.series ?? []).flatMap((x) => {
          if ('users' in x) {
            return x.users.map((user) => user.userUid)
          }
          return []
        }),
      )
      userDict = allUserUids.reduce<typeof userDict>((memo, uid) => {
        const user = getUserByUid(uid)
        if (user.isDeleted) return memo
        memo[uid] = user.name
        return memo
      }, {})
    }
    return userDict
  }, [data, getUserByUid])
}

function useTeamNameLookup(
  data: ScoreTimeSeriesData | undefined,
  isPublicDashboard: boolean,
): { [key: string]: string } {
  const { getTeamByUid } = useTeams({
    suspense: !isPublicDashboard,
    /**
     * Block this request when in public dashboard mode (request will fail otherwise because it is not exposed as a public endpoint)
     */
    isPaused: () => isPublicDashboard,
  })

  return React.useMemo(() => {
    if (!data) {
      return {}
    }
    let teamDict: { [key: string]: string } = {}
    if (data && 'teams' in data) {
      // Public dashboard
      // `data` will contain a `teams` property in public dashboard mode
      teamDict = (data.teams ?? []).reduce<typeof teamDict>((memo, x) => {
        memo[x.teamUid] = x.teamName
        return memo
      }, {})
    } else {
      // Retrieve all team uids from the response data
      const allTeamUids = uniq(
        (data?.series ?? []).flatMap((x) => {
          if ('teams' in x) {
            return x.teams.map((team) => team.teamUid)
          }
          return []
        }),
      )
      teamDict = allTeamUids.reduce<typeof teamDict>((memo, uid) => {
        memo[uid] = getTeamByUid(uid)?.name ?? '?'
        return memo
      }, {})
    }
    return teamDict
  }, [data, getTeamByUid])
}

export type ScoreTimeSeriesProps = {
  widget: ScoreWidgetModel
}

export const ScoreTimeSeries: React.FC<ScoreTimeSeriesProps> = ({ widget }) => {
  const isPublicDashboard = useDashboardPublicContext()

  const { data: unsortedData } = useWidgetData<ScoreTimeSeriesData>(
    widget,
    ({ period }) => {
      return {
        resolution: getResolutionFromTimespan(period.from, period.to),
        /**
         * `visual` is not used by the API but works here as a part of the SWR cache key.
         * If not present, then after changing the visual type of a widget that widget could
         * initially be served data conforming to the previous visual type from the cache.
         **/
        visual: widget.visual,
      }
    },
  )

  // Ensures that lines get the same color on each data refresh, as users/teams order may change
  const data = React.useMemo(() => {
    if (!unsortedData) {
      return {} as ScoreTimeSeriesData
    }

    const sortedSeries = unsortedData.series.map((series) => {
      if ('users' in series) {
        const sortedUsers = series.users.sort((a, b) => {
          return a.userUid.localeCompare(b.userUid)
        })
        return { ...series, users: sortedUsers }
      }
      if ('teams' in series) {
        const sortedTeams = series.teams.sort((a, b) => {
          return a.teamUid.localeCompare(b.teamUid)
        })
        return { ...series, teams: sortedTeams }
      }
      return series
    })

    return { ...unsortedData, series: sortedSeries } as ScoreTimeSeriesData
  }, [unsortedData])

  const [plotOnlyWeekdays] = useOnlyWeekdaysToggle()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1
  const resolution = getEffectiveTimeResolution(widget.resolution, period)

  const userDict = useUserNameLookup(data, isPublicDashboard)
  const teamsDict = useTeamNameLookup(data, isPublicDashboard)

  const getLineColor = useLineColors()

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}
    const lines: {
      primary: LineDescriptor[]
      secondary: LineDescriptor[]
    } = {
      primary: [],
      secondary: [],
    }

    // Create data buckets
    data?.series.forEach((x) => {
      const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)
      // Omit weekend data?
      if (
        plotOnlyWeekdays &&
        isWeekend(date) &&
        (resolution === 'Hours' || resolution === 'Days')
      ) {
        return
      }
      const bucketKey = date.toISOString()
      buckets[bucketKey] = { date, ...x }
    })

    Object.entries(userDict).forEach(([uid, name], i) => {
      const variant = DashedLineVariants[i % DashedLineVariants.length]
      lines.secondary.push({
        id: `secondary:${widget.scoreUid}:${uid}`,
        color: getLineColor(lines.secondary.length),
        label: name,
        getValue: (d) => {
          return d.users?.[i]?.averageScore ?? null
        },
        variant,
      })
    })

    Object.entries(teamsDict).forEach(([uid, name], i) => {
      const variant = DashedLineVariants[i % DashedLineVariants.length]
      lines.secondary.push({
        id: `secondary:${widget.scoreUid}:${uid}`,
        color: getLineColor(lines.secondary.length),
        label: name,
        getValue: (d) => {
          return d.teams?.[i]?.averageScore ?? null
        },
        variant,
      })
    })

    // Create lines
    const firstDatum = data?.series?.[0]
    if (firstDatum && 'averageScore' in firstDatum) {
      lines.primary.push({
        id: widget.scoreUid,
        color: 'segments.primary',
        label: widget.scoreName,
        getValue: (d) => d.averageScore,
      })
    }

    return {
      data: Object.values(buckets).sort(
        (a, b) => a.date.getTime() - b.date.getTime(),
      ),
      lines,
    }
  }, [
    data,
    tzOffset,
    plotOnlyWeekdays,
    resolution,
    userDict,
    teamsDict,
    widget.scoreName,
    widget.scoreUid,
    getLineColor,
  ])

  return (
    <Widget.Container>
      <Widget.Title>{widget.title}</Widget.Title>
      <Widget.Description>{widget.description}</Widget.Description>
      <Widget.Content fontSize="0.75em">
        <ResponsiveContainer height="100%" minWidth={100}>
          <TimeSeriesChart data={results.data} resolution={resolution}>
            <Legend />
            <CartesianGrid />
            <XAxis dataKey="date" />
            <YAxis
              tickFormatter={timeSeriesTickFormatters.yAxis.value}
              domain={[0, widget.scoreMaxPossibleScore]}
            />
            <Tooltip
              content={
                <CustomTooltip
                  resolution={resolution}
                  customValueFormatter={(value) =>
                    timeSeriesTickFormatters.yAxis.value(value)
                  }
                />
              }
            />
            <Customized
              component={
                <GoalReferenceArea goal={widget.scoreGoal} style="number" />
              }
            />
            {results.lines.primary.map((line) => (
              <PrimaryLine key={line.id} line={line} />
            ))}
            {results.lines.secondary.map((line) => (
              <Line
                key={line.id}
                id={line.id}
                type="monotone"
                dataKey={line.getValue}
                name={line.label}
                stroke={line.color}
                strokeWidth={2}
                dot={false}
                activeDot={{ r: 4 }}
                isAnimationActive={false}
              />
            ))}
          </TimeSeriesChart>
        </ResponsiveContainer>
      </Widget.Content>
      <Widget.Footer>
        <Widget.Goal {...widget.scoreGoal} />
      </Widget.Footer>
    </Widget.Container>
  )
}

function useLineColors(): (index: number) => string {
  const theme = useTheme()
  return React.useCallback(
    (index: number) => {
      const colorNames = [
        getColor(theme, 'segmentPrimary'),
        getColor(theme, 'segmentSecondary'),
        getColor(theme, 'segmentTertiary'),
        getColor(theme, 'segmentQuaternary'),
        getColor(theme, 'segmentQuinary'),
        '#1A3A3A',
        '#657B81',
        '#676B37',
        '#89A083',
        '#997E66',
      ]
      return colorNames[index % colorNames.length]
    },
    [theme],
  )
}
