import { WidgetComponent } from '../registry'
import { ConversationDurationWidgetModel } from './types'
import { ConversationDurationSingleValue } from './variants/ConversationDurationSingleValue'
import { ConversationDurationTimeSeries } from './variants/ConversationDurationTimeSeries'
import { ConversationDurationTeamBreakdown } from './variants/DurationListVisual/ConversationDurationTeamBreakdown'
import { ConversationDurationUserBreakdown } from './variants/DurationListVisual/ConversationDurationUserBreakdown'

const ConversationDuration: WidgetComponent<ConversationDurationWidgetModel> = (
  widget,
) => {
  switch (widget.visual) {
    case 'Graph': {
      return <ConversationDurationTimeSeries widget={widget} />
    }
    case 'UserList': {
      return <ConversationDurationUserBreakdown widget={widget} />
    }
    case 'TeamList': {
      return <ConversationDurationTeamBreakdown widget={widget} />
    }
    default: {
      return <ConversationDurationSingleValue widget={widget} />
    }
  }
}

export default ConversationDuration
