import analytics from '@capturi/analytics'
import { useTeams } from '@capturi/core'
import { useIsHovering } from '@capturi/react-utils'
import {
  Box,
  Link,
  Tag,
  TagLabel,
  Text,
  Tooltip,
  chakra,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React, { useRef } from 'react'
import { MdLaunch } from 'react-icons/md'
import { ResponsiveContainer } from 'recharts'

import { useDashboardPublicContext } from '../../../../contexts/DashboardPublicContext'
import useWidgetData from '../../../../hooks/useWidgetData'
import useWidgetFilterLink from '../../../../hooks/useWidgetFilterLink'
import { WidgetModel } from '../../../../types'
import * as Widget from '../../../components/Widget'
import {
  Cell,
  CellIcon,
  FlexibleCell,
  ListContainer,
  Table,
  getFontSize,
  labelCss,
  subLabelCss,
  useListContainer,
  valueCss,
} from '../../../components/visuals/ListDisplay'
import {
  ScoreTeamBreakdownData,
  ScoreWidgetModel,
  TeamScoreStatistics,
} from '../../types'

export type ScoreTeamBreakdownProps = {
  widget: ScoreWidgetModel
}

export const ScoreTeamBreakdown: React.FC<ScoreTeamBreakdownProps> = ({
  widget,
}) => {
  const { title, description } = widget
  const { data } = useWidgetData<ScoreTeamBreakdownData>(widget, () => ({
    /**
     * `visual` is not used by the API but works here as a part of the SWR cache key.
     * If not present, then after changing the visual type of a widget that widget could
     * initially be served data conforming to the previous visual type from the cache.
     **/
    visual: widget.visual,
  }))
  return (
    <Widget.Container>
      <Widget.Title>{title}</Widget.Title>
      <Widget.Description>{description}</Widget.Description>
      <Widget.Content>
        {data && (
          <ResponsiveContainer height="100%" width="100%">
            <TeamBreakdown data={data} widget={widget} />
          </ResponsiveContainer>
        )}
      </Widget.Content>
    </Widget.Container>
  )
}

type TeamBreakdownProps = {
  width?: number
  height?: number
  data: ScoreTeamBreakdownData
  widget: WidgetModel
}

type TeamBreakdownRowProps = {
  value: string
  subText: string
  teamName: string
  row: TeamScoreStatistics
  widget: WidgetModel
  isPublicDashboard: boolean
  index: number
  isTooltipDisabled: boolean
}

const TeamBreakdown: React.FC<TeamBreakdownProps> = ({ widget, data }) => {
  const isPublicDashboard = useDashboardPublicContext()
  const { getTeamByUid } = useTeams({
    /**
     * When viewing dashboard with a public link `teamName` will be populated by the API.
     * Block this request in that case (request will fail otherwise because it is not exposed as a public endpoint)
     */
    isPaused: () => isPublicDashboard,
  })

  const { ref: scrollRef, width, isScrolling } = useListContainer()

  const rows = React.useMemo(() => {
    const rows = data.teams.map((x) => {
      /**
       * Only when viewing dashboard with a public link will
       * `teamName` be populated
       */
      if (x.teamName === undefined) {
        const team = getTeamByUid(x.teamUid)
        return {
          ...x,
          teamName: team?.name,
        }
      }
      return x
    })
    return rows.sort((a, b) => {
      const diff = b.averageScore - a.averageScore
      if (diff !== 0) return diff
      return (a.teamName ?? '').localeCompare(b.teamName ?? '')
    })
  }, [data, getTeamByUid])

  const fontSize = getFontSize(width)
  return (
    <ListContainer ref={scrollRef}>
      <Table fontSize={`${fontSize}px`}>
        <chakra.tbody>
          {rows.map((x, i) => {
            const { teamName, teamUid } = x
            const value = i18n.number(x.averageScore, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 1,
            })
            const subText = t`${x.conversations} conversations`

            return (
              <TeamBreakDownRow
                row={x}
                key={teamUid}
                index={i}
                value={value}
                subText={subText}
                teamName={teamName ?? ''}
                widget={widget}
                isPublicDashboard={isPublicDashboard}
                isTooltipDisabled={isScrolling}
              />
            )
          })}
        </chakra.tbody>
      </Table>
    </ListContainer>
  )
}

const TeamBreakDownRow: React.FC<TeamBreakdownRowProps> = ({
  value,
  subText,
  teamName,
  row,
  widget,
  isPublicDashboard,
  index,
  isTooltipDisabled,
}) => {
  const widgetHitRef = useRef<HTMLTableRowElement>(null)
  const isHovering = useIsHovering(widgetHitRef)

  const hitLink = useWidgetFilterLink({
    widget,
    additionalTeamFilter: [row.teamUid],
  })

  const isLinkHidden = index === 0 && widget.title === ''

  const infoText = `${teamName}: ${value} (${subText})`

  return (
    <chakra.tr ref={widgetHitRef}>
      <FlexibleCell>
        <Tooltip label={infoText} isDisabled={isTooltipDisabled}>
          <Box>
            <Text as="div" css={labelCss} overflow="hidden">
              <Tag
                variant="outline"
                colorScheme="secondary"
                color="gray.900"
                fontSize="0.9em"
                py="0.1em"
                px="0.5em"
              >
                <TagLabel>{teamName}</TagLabel>
              </Tag>
            </Text>
            <Text as="div" css={subLabelCss} overflow="hidden">
              <Text color="textMuted" noOfLines={1} wordBreak="break-all">
                {subText}
              </Text>
            </Text>
          </Box>
        </Tooltip>
      </FlexibleCell>
      <Cell isNumeric textAlign="end" verticalAlign="baseline" pos="relative">
        {isHovering && hitLink && !isPublicDashboard ? (
          <Link
            href={hitLink}
            isExternal
            onClick={() => {
              analytics.event('dashboard_widget_link_list', {
                widgetType: widget.type,
                visual: widget.visual,
              })
            }}
          >
            <CellIcon mr={isLinkHidden ? '8' : undefined}>
              <MdLaunch />
            </CellIcon>
          </Link>
        ) : (
          <Text as="div" css={valueCss}>
            <Text>{value}</Text>
          </Text>
        )}
      </Cell>
    </chakra.tr>
  )
}
