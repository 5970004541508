import { Button } from '@capturi/ui-components'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import { ErrorMessage } from '@hookform/error-message'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Controller } from 'react-hook-form'

export const MinimumConversationsField: React.FC = () => {
  const name = 'minimumConversationsForListView'
  const id = `widget-${name}`

  const format = (val: number | string | undefined | null): string => {
    if (!Number.isInteger(val)) {
      return ''
    }
    return `${val}`
  }

  return (
    <FormControl>
      <FormLabel htmlFor={id}>
        <Text>
          <Trans>Minimum number of conversations</Trans>
        </Text>
        <Text color="textMuted" fontSize="sm" fontWeight="normal">
          <Trans>
            Specify the minimum number of conversations a person or team must
            have to be displayed in the list.
          </Trans>
        </Text>
      </FormLabel>
      <Controller
        name={name}
        render={({ field }) => (
          <Box>
            <Text fontSize="sm">
              <Trans>Min. number of conversations</Trans>
            </Text>
            <NumberInput
              {...field}
              step={1}
              min={0}
              inputMode="numeric"
              onChange={(_valueString, valueAsNumber) => {
                field.onChange?.(
                  Number.isInteger(valueAsNumber) ? valueAsNumber : undefined,
                )
              }}
              value={format(field.value)}
              allowMouseWheel
              keepWithinRange={false}
              w={28}
              size="sm"
              id={id}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Button
              variant="link"
              size="sm"
              onClick={() => field.onChange(null)}
              tabIndex={-1}
            >
              <Trans>Reset</Trans>
            </Button>
          </Box>
        )}
      />
      <ErrorMessage
        name={name}
        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
      />
    </FormControl>
  )
}
